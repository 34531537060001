import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  TextField,
  Grid,
  CardContent,
  Divider,
  Alert,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Bugs() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'email', label: t('Users.table.1'), alignRight: false },
    { id: 'message', label: 'Message', alignRight: false },
    { id: 'status', label: t('Users.table.4'), alignRight: false },
    { id: 'action', label: t('Users.table.5'), alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'reply', 'change status'];

  function checkRender() {
    let check = 'bug reports';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [statusList, setStatuslist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [openUser, setOpenUser] = useState(false);
  const [openMesg, setOpenMesg] = useState(false);
  const [user, setuser] = useState(null);
  const [firstName, setfirstName] = useState();
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });
  const [statusss, setStatus] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (user) {
      const selected = statusList?.filter(
        (e) => e.name.toLowerCase() === user?.status.toLowerCase()
      );
      setStatus(selected[0]);
    }
  }, [user]);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getBugs(value);
  };

  function getBugs(page) {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    // Construct URL parameters
    const params = new URLSearchParams();
    if (search.length > 0) params.append('search', search);
    if (page) params.append('page', page);
    params.append('type', 'bug');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_feedback}?${params.toString()}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      })
      .then((result) => {
        const decryptedData = result.data;
        setlist(decryptedData);
        setpagesCount(result.count ? Math.ceil(result.count / 20) : 1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const [showSnackbar, setshowSnackbar] = useState(false);
  const [alertMessage, setalertmessage] = useState('');
  const [mode, setmode] = useState('error');

  function getStatus() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.get_bug_status, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      })
      .then((result) => {
        setStatuslist(result.data);
      })
      .catch((error) => {
        console.log('error', error);
        setStatuslist([]);
      })
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    setloading(true);
    getBugs();
    getStatus();
  }, []);

  function openUserModal(user) {
    setuser(user);
    setOpenUser(true);
  }

  const handleCloseUserModal = () => {
    setOpenUser(false);
    setMessage('');
    setSubject('');
  };

  const handleCloseMesgModal = () => {
    setOpenMesg(false);
    setMessage('');
    setSubject('');
  };

  const descriptionElementRef = React.useRef(null);

  function openMesgModal(user) {
    setuser(user);
    setOpenMesg(true);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isUserNotFound = list.length === 0;
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link'
  ];

  const refreshAdmin = () => {
    setOpenUser(false);
    getBugs(page);
  };

  const handleReply = () => {
    if (subject && message) {
      replyBug();
    } else if (!subject) {
      setalert({ visible: true, message: 'Subject feild is required!' });
    } else if (!message) {
      setalert({ visible: true, message: 'Message feild is required!' });
    }
  };

  function replyBug() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('email', user?.user?.email);
    formdata.append('message', message);
    formdata.append('subject', subject);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.reply_feedback, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json().then((response) => {
            refreshAdmin();
            setMessage('');
            setSubject('');
            setmode('success');
            setalertmessage(response.message);
            setshowSnackbar(true);
          });
        } else {
          return response.json().then((result) => {
            setalertmessage(result.error);
            setmode('error');
            setshowSnackbar(true);
            throw new Error(`Error: ${response.status}`);
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // setalert({ visible: true, message: 'Something went wrong' });
      })
      .finally(() => setloading(false));
  }

  function openModal(user) {
    setuser(user);
    handleOpen();
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
    changeBugStatus(event.target.value);
  };

  function changeBugStatus(status) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('id', user.id);
    formdata.append('status', status.name);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);
    handleClose();

    fetch(api.bug_status_change, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          return response.json().then((result) => {
            setalertmessage(result.error);
            setmode('error');
            setshowSnackbar(true);
            throw new Error(`Error: ${response.status}`);
          });
        }
      })
      .then((response) => {
        setalertmessage(response.message);
        setmode('success');
        setshowSnackbar(true);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      setloading(true);
      getBugs();
    }
  }, [search]);

  return (
    <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* SNACKBAR FOR ALERTS */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setshowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setshowSnackbar(false);
            }}
            severity={mode}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {/* REPLY MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openUser}
          onClose={handleCloseUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <Box p={1} mt={2}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Reply
              </Typography>

              <CardContent>
                <Grid container spacing={2} className="top_column">
                  <Grid item lg={6} sm={12} md={12}>
                    <TextField
                      disabled
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Name'}
                      type="text"
                      value={firstName ? firstName : user?.user?.name}
                      onChange={(val) => {
                        setfirstName(val.target.value);
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>

                  <Grid item lg={6} sm={12} md={12}>
                    <TextField
                      disabled
                      id="outlined-error-helper-text"
                      fullWidth
                      label={t('EditProfile.fields.2')}
                      value={user?.user?.email}
                      type="text"
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>

                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Subject'}
                      type="text"
                      value={subject}
                      onChange={(val) => {
                        setSubject(val.target.value);
                        if (!val.target.value) {
                          setalert({ visible: true, message: 'Subject feild is required!' });
                        } else {
                          setalert({ visible: false, message: 'Subject feild is required!' });
                        }
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    <ReactQuill
                      theme="snow"
                      value={message}
                      onChange={(e) => {
                        setMessage(e);
                        if (!e) {
                          setalert({ visible: true, message: 'Message feild is required!' });
                        } else {
                          setalert({ visible: false, message: 'Message feild is required!' });
                        }
                      }}
                      modules={modules}
                      formats={formats}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <LoadingButton
                  disabled={false}
                  loading={loading}
                  sx={{
                    marginRight: 1
                  }}
                  onClick={() => {
                    handleReply();
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t('EditProfile.buttons.1')}
                </LoadingButton>
                <LoadingButton
                  sx={{
                    marginRight: 1
                  }}
                  onClick={handleCloseUserModal}
                  variant="outlined"
                >
                  {t('EditProfile.buttons.2')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* CHANGE STATUS MODAL */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Change Status
              </Typography>
              <CardContent>
                <TextField
                  style={{
                    width: '100%'
                  }}
                  select
                  value={statusss}
                  label={'Status'}
                  onChange={handleChange}
                  className="width_full_status"
                >
                  {statusList.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  style={{ marginTop: 20 }}
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  fullWidth
                  size={'large'}
                >
                  Close
                </Button>
              </CardContent>
            </Box>
          </Box>
        </Modal>

        <Dialog
          open={openMesg}
          onClose={handleCloseMesgModal}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box sx={{ minWidth: 600, maxHeight: 500 }}>
            <DialogTitle id="scroll-dialog-title">Message</DialogTitle>
            <DialogContent dividers={'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {user?.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMesgModal}>Cancel</Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Bug Reports
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'100%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                setPage(1);
                getBugs(1);
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getBugs(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row) => {
                    const { id, message, email, seen, status, user } = row;

                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell
                          scope="row"
                          padding="normal"
                          style={{ minWidth: 150, maxWidth: 150 }}
                        >
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={user.name} src={user.avatar + '?v=' + Date.now()} />
                            <Typography variant="subtitle2">{user.name}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 150 }}>
                          <Typography noWrap>{user.email}</Typography>
                        </TableCell>
                        <TableCell
                          onClick={() => openMesgModal(row)}
                          align="left"
                          style={{ maxWidth: 280, cursor: 'pointer' }}
                        >
                          <Typography noWrap>{message ? message : 'N/A'}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Label
                            style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 100 }}
                            variant="ghost"
                            color={
                              (status.toLowerCase() === 'resolved' && 'success') ||
                              (status.toLowerCase() === 'pending' && 'warning') ||
                              (status.toLowerCase() === 'process' && 'info') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 130 }}>
                          {permission.includes('reply') && (
                            <Button
                              size="small"
                              onClick={() => openUserModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 140,
                                alignSelf: 'center',
                                marginRight: 10
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="material-symbols-light:reply"
                                />
                              }
                            >
                              Reply
                            </Button>
                          )}

                          {permission.includes('change status') && (
                            <Button
                              size="small"
                              onClick={() => openModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 140,
                                alignSelf: 'center',
                                marginRight: 10
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={<Iconify color={pallete.primary.main} icon="bx:edit" />}
                            >
                              change status
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>

        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
