import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid, Box } from '@mui/material';
import { fShortenNumber } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
  height: 250
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

export default function TotalBusinesses({ total }) {
  return (
    <Grid container spacing={2}>
      {/* USERS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="carbon:user-avatar-filled" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.users)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Users
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* PRODUCTS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="eva:shopping-bag-fill" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.products)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Products
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* ORDERS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="ion:receipt-sharp" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.orders)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Orders{' '}
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* EARNINGS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="solar:dollar-bold" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.earnings)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Earnings
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* REFUNDS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="heroicons:receipt-refund-16-solid" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.refunds)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Refunds
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* WITHDRAW REQUEST */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="uil:money-withdraw" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.withdraw_request)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Withdraw Requests
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
    </Grid>
  );
}
