import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { api } from 'src/Api';

export default function DropDown(
    {
        error,
        fullWidth,
        value,
        label,
        handleChange,
        data,
        setRole,
        dontAppend,
        alreadyRole,
    }
) {
    //   const [roles, setRoles] = useState(
    //     [
    //       { id: 1, role_name: 'Learners' },
    //       { id: 2, role_name: 'Assessors' },
    //       { id: 3, role_name: 'IQAs' },
    //       { id: 4, role_name: 'Admin' },
    //       { id: 5, role_name: 'EQAs' }
    //     ]
    //   );
    //   const [reasondrop, setReasondrop] = useState();

    //   useEffect(() => { 
    //     const getRoles = async () => {
    //       const myHeaders = new Headers();
    //       const token = localStorage.getItem('token');
    //       myHeaders.append('Authorization', `Bearer ${token}`);

    //       const requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         redirect: 'follow'
    //       };

    //       try {
    //         const response = await fetch(api.getRoleNames, requestOptions);
    //         const result = await response.json();
    //         if (result.success) {
    //           const rolesData = dontAppend
    //             ? result.data
    //             : [{ id: 0, role_name: 'All' }, ...result.data];
    //           setRoles(rolesData);
    //           if (alreadyRole) {
    //             const defaultRole = rolesData.find((role) => role.id === alreadyRole.id) || null;
    //             setReasondrop(defaultRole);
    //           }
    //         }
    //       } catch (error) {
    //         console.error('Error fetching roles:', error);
    //       }
    //     };

    //     getRoles();
    //   }, [alreadyRole, dontAppend]);

    //   const handleChange = (event) => {
    //     const selectedRole = event.target.value;
    //     setRole(selectedRole?.id);
    //     setReasondrop(selectedRole);
    //   };

    return (
        <TextField
            error={error}
            color={error ? 'error' : 'primary'}
            fullWidth={fullWidth}
            style={{ width: !fullWidth && '20%', marginLeft: !fullWidth && 15 }}
            select
            value={value}
            label={label ? label : ""}
            onChange={handleChange}
        >
            {data.map((option) => (
                <MenuItem key={option.id} value={option}>
                    {option.role_name}
                </MenuItem>
            ))}
        </TextField>
    );
}
