import { Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from '../Iconify';

// import Iframe from 'react-iframe'

function Iframe({ url, setDialogOpen, dialogOpen }) {
    // State to store the URL and file type
    const [iframe, setIframe] = useState(null);
    const [fileType, setFileType] = useState(null);

    // Function to determine the file type from the URL and update states
    function getFileTypeFromURL(url) {
        const extension = url.split('.')?.pop()?.split('?')[0]?.toLowerCase(); // Extract file extension
        console.log('Error IN IQA', extension);
        // Map of file extensions to file types
        const fileTypes = {
            'png': 'Image',
            'jpg': 'Image',
            'jpeg': 'Image',
            'gif': 'Image',
            'bmp': 'Image',
            'webp': 'Image',
            'svg': 'Image',
            'mp4': 'Video',
            'avi': 'Video',
            'mov': 'Video',
            'wmv': 'Video',
            'flv': 'Video',
            'mkv': 'Video',
            'mp3': 'Audio',
            'wav': 'Audio',
            'ogg': 'Audio',
            'pdf': 'DocumentPDF',
            'doc': 'Document',
            'docx': 'Document',
            'xls': 'Document',
            'xlsx': 'Document',
            'ppt': 'Document',
            'pptx': 'Document',
            'zip': 'Compressed File',
            'rar': 'Compressed File',
        };

        // Set the iframe URL state
        setIframe(url);

        // Set the file type state based on the extension or 'Unknown'
        setFileType(fileTypes[extension] || 'Unknown');
    }

    // On component mount, get the file type and set the URL
    useEffect(() => {
        getFileTypeFromURL(url);
    }, [url]);

    return (
        <Dialog
            open={dialogOpen}
            onClose={setDialogOpen}
            maxWidth={fileType === 'Image' || fileType === 'Video' || fileType === 'Audio' ? "sm" : "md"}
            fullWidth
        >
            <DialogTitle id="dialog-title">
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={setDialogOpen}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 10, top: 3 }}
                >
                    <Iconify icon="iconamoon:close-fill" sx={{ fontSize: 25 }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: "100%", marginTop: 2, overflowX: "hidden" }}>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // overflowX: "hidden"
                    }}
                >
                    {fileType === 'Image' ? (
                        <img
                            src={iframe}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '70vh', // Limits the image height to fit within the viewport
                                objectFit: 'contain', // Ensures the image retains its aspect ratio
                                overflowX: "hidden"
                            }}
                        />
                    ) : fileType === 'Video' ? (
                        <video
                            src={iframe}
                            width="100%"
                            height="100%"
                            controls
                        >
                            Your browser does not support the video tag.
                        </video>
                    ) : fileType === 'Audio' ? (
                        <audio
                            src={iframe}
                            controls
                            style={{ width: '100%' }}
                        >
                            Your browser does not support the audio element.
                        </audio>
                    ) : fileType === 'Document' ? (
                        <iframe
                            // src={iframe}
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${iframe}`}
                            width="100%"
                            height={"420px"}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    ) : fileType === 'DocumentPDF' ? (
                        <iframe
                            src={iframe}
                            width="100%"
                            height={"420px"}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    ) : (
                        <h1>Unsupported file type</h1>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Iframe;
