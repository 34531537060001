import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Grid,
  Container,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { Add, Remove, ExpandMore, Close, ArrowBack } from '@mui/icons-material';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { api } from 'src/Api';
import { LoadingButton } from '@mui/lab';

export default function Form() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [subTitle, setsubTitle] = useState('');
  const [subNumber, setsubNumber] = useState('');
  const [units, setUnits] = useState([]);
  const [docs, setDocs] = useState([]);
  const [types, setTypes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleted_at, setIsDeleted_at] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const [openAlertModalD, setOpenAlertModalD] = useState(false);


  const handleOpenAlertD = () => {
    setOpenAlertModalD(true);
  };

  const handleCloseAlertD = () => {
    setOpenAlertModalD(false);
  };


  useEffect(() => {
    getTypes();
    if (id) {
      getQualifications();
    }
  }, [id]);

  useEffect(() => {
    if (id && !isEdit) {
      setDisabled(true);
    } else if (id && isEdit) {
      setDisabled(false);
    }
  }, [isEdit]);

  function getTypes() {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_types}?type=qualification_unit_type`;
    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setTypes(result.data);
        } else {
          setTypes([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  function getQualifications() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    const params = new URLSearchParams();
    id && params.append('qualification_id', id);
    params.append('company_admin_id', user?.company_admin_id);
    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualifications_by_id}?${params.toString()}`;
    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setsubTitle(result?.data?.sub_title);
          setsubNumber(result?.data?.sub_number);
          setUnits(result?.data?.units);
          setDocs(result?.data?.unit_document_titles);
          setIsDeleted_at(result?.data?.deleted_at);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const handleAddUnit = () => {
    setUnits([
      ...units,
      {
        unit_number: '',
        unit_title: '',
        unit_type_id: '',
        LO: []
      }
    ]);
  };

  const handleAddLo = (unitIndex) => {
    const newUnits = [...units];
    newUnits[unitIndex].LO.push({
      lo_number: '',
      lo_detail: '',
      AC: []
    });
    setUnits(newUnits);
  };

  const handleAddAc = (unitIndex, loIndex) => {
    const newUnits = [...units];
    newUnits[unitIndex].LO[loIndex].AC.push({
      ac_number: '',
      ac_detail: ''
    });
    setUnits(newUnits);
  };

  const handleRemoveUnit = (unitIndex) => {
    const newUnits = [...units];
    newUnits.splice(unitIndex, 1);
    setUnits(newUnits);
  };

  const handleRemoveLo = (unitIndex, loIndex) => {
    const newUnits = [...units];
    newUnits[unitIndex].LO.splice(loIndex, 1);
    setUnits(newUnits);
  };

  const handleRemoveAc = (unitIndex, loIndex, acIndex) => {
    const newUnits = [...units];
    newUnits[unitIndex].LO[loIndex].AC.splice(acIndex, 1);
    setUnits(newUnits);
  };

  const handleChange = (event, unitIndex, loIndex, acIndex, field, type) => {
    const newUnits = [...units];
    const value = event.target.value;
    if (type === 'unit') {
      newUnits[unitIndex][field] = value;
    } else if (type === 'lo') {
      newUnits[unitIndex].LO[loIndex][field] = value;
    } else if (type === 'ac') {
      newUnits[unitIndex].LO[loIndex].AC[acIndex][field] = value;
    }
    setUnits(newUnits);
  };

  const handleAddDoc = () => {
    setDocs([...docs, { title: '' }]);
  };

  const handleRemoveDoc = (docIndex) => {
    const newDocs = [...docs];
    newDocs.splice(docIndex, 1);
    setDocs(newDocs);
  };

  const handleDocChange = (event, docIndex) => {
    const newDocs = [...docs];
    newDocs[docIndex].title = event.target.value;
    setDocs(newDocs);
  };

  const handleSave = () => {
    // Check if subtitle is provided
    if (!subTitle) {
      setSnackbarMessage('Qualification/Subject Title is required.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Check if subtitle number is provided
    if (!subNumber) {
      setSnackbarMessage('Qualification/Subject Number is required.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Check if units are added
    if (units?.length === 0) {
      setSnackbarMessage('Please add at least one unit.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Validate units
    for (let i = 0; i < units?.length; i++) {
      const unit = units[i];
      if (!unit.unit_number || !unit.unit_title || !unit.unit_type_id) {
        setSnackbarMessage('Please fill all fields in each unit.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // Validate LO (Learning Outcomes) in each unit
      if (unit?.LO?.length === 0) {
        setSnackbarMessage('Each unit must have at least one LO.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      for (let j = 0; j < unit?.LO?.length; j++) {
        const lo = unit?.LO[j];
        if (!lo.lo_number || !lo.lo_detail) {
          setSnackbarMessage('Please fill all fields in each LO.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        // Validate AC (Assessment Criteria) in each LO
        if (lo?.AC?.length === 0) {
          setSnackbarMessage('Each LO must have at least one AC.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        for (let k = 0; k < lo?.AC?.length; k++) {
          const ac = lo.AC[k];
          if (!ac.ac_number || !ac.ac_detail) {
            setSnackbarMessage('Please fill all fields in each AC.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
          }
        }
      }
    }

    // Validate docs
    if (docs?.length > 0) {
      for (let i = 0; i < docs?.length; i++) {
        const doc = docs[i];
        if (!doc.title) {
          setSnackbarMessage('Each document must have a title.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }
      }
    } else {
      setSnackbarMessage('Please add at least one document.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (id) {
      handleUpdateQualification();
    } else {
      handleSaveQualification();
    }
  };

  const handleUpdateQualification = () => {
    setSaveLoading(true);
    // Implement save functionality here
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('sub_title', subTitle);
    formdata.append('sub_number', subNumber);
    // Prepare units data before appending
    const preparedUnits = units.map((unit) => ({
      ...unit,
      unit_type_id: unit.unit_type_id.id || unit.unit_type_id
    }));

    formdata.append('units', JSON.stringify(preparedUnits));
    formdata.append('unit_document_titles', JSON.stringify(docs));
    formdata.append('id', id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.update_qualification, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setSaveLoading(false);
          setSnackbarMessage(result.message || 'Update successful!');
          setSnackbarSeverity('success');
          setTimeout(() => {
            navigate('/dashboard/qualifications');
          }, 1000);
          getQualifications();
        } else {
          setSaveLoading(false);
          setSnackbarMessage(result.error || 'Update failed.');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('error', error);
        setSnackbarMessage('An error occurred while updating.');
        setSaveLoading(false);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleSaveQualification = () => {
    setSaveLoading(true);
    // Implement save functionality here
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('sub_title', subTitle);
    formdata.append('sub_number', subNumber);
    formdata.append('units', JSON.stringify(units));
    formdata.append('unit_document_titles', JSON.stringify(docs));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.save_qualification, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201) {
          setsubTitle('');
          setsubNumber('');
          setDocs([]);
          setUnits([]);
          setSnackbarMessage(result.message || 'Save successful!');
          setSnackbarSeverity('success');
          setTimeout(() => {
            navigate('/dashboard/qualifications');
          }, 1000);
          setSaveLoading(false);
        } else {
          setSaveLoading(false);
          setSnackbarMessage(result.error || 'Save failed.');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('error', error);
        setSnackbarMessage('An error occurred while saving.');
        setSaveLoading(false);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleDeleteQualification = () => {
    setDeleteLoading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    const queryParams = new URLSearchParams();
    id && queryParams.append('id', id);
    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.delete_qualification}?${queryParams.toString()}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setSnackbarMessage(result.message || 'Delete successfull!');
          setSnackbarSeverity('success');
          setTimeout(() => {
            navigate('/dashboard/qualifications');
          }, 1000);
          setDeleteLoading(false);
          handleCloseAlertD()
        } else {
          setDeleteLoading(false);
          setSnackbarMessage(result.error || 'Delete failed.');
          setSnackbarSeverity('error');
          handleCloseAlertD()
        }
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('error', error);
        setSnackbarMessage('An error occurred while deleting.');
        setDeleteLoading(false);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        handleCloseAlertD()
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Page title={`Qualifications | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
            {id ? 'Edit Qualification Details' : ' Add Qualification Form'}
          </Typography>
          {id && !isDeleted_at && (
            <Grid>
              <Button
                sx={{ backgroundColor: 'red' }}
                onClick={() => handleOpenAlertD()}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="ic:baseline-delete" />}
              >
                Delete
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => setIsEdit(true)}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="lucide:edit" />}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Stack>
        <Card>
          {loading ? (
            <Box
              py={5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <Box padding={2}>
                <TextField
                  fullWidth
                  label="Qualification/Subject Title"
                  margin="normal"
                  required
                  size="small"
                  onChange={(e) => setsubTitle(e.target.value)}
                  value={subTitle}
                  disabled={disabled}
                />
                <TextField
                  fullWidth
                  label="Qualification/Subject Number"
                  margin="normal"
                  size="small"
                  onChange={(e) => setsubNumber(e.target.value)}
                  value={subNumber}
                  disabled={disabled}
                />

                <Box mt={2}>
                  {units?.length > 0 && (
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                      Units
                    </Typography>
                  )}
                  {units?.map((unit, unitIndex) => (
                    <Accordion key={unitIndex}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                          overflow: 'hidden', // Ensure overflow handling
                          minHeight: '40px !important', // Set a fixed height if necessary
                          maxHeight: '40px !important',
                          backgroundColor: '#eeeeee',
                          marginBottom: 1
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            width:"80%",
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginTop: 0.5 
                          }}
                        >
                          {unit?.unit_title ? unit?.unit_title : 'UNIT'}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} sx={{ padding: '0px 0px 0px !important' }}>
                          <Grid item xs={3.3}>
                            <TextField
                              fullWidth
                              label="Unit Number"
                              value={unit.unit_number}
                              onChange={(e) =>
                                handleChange(e, unitIndex, null, null, 'unit_number', 'unit')
                              }
                              margin="normal"
                              size="small"
                              disabled={disabled}
                            />
                          </Grid>
                          <Grid item xs={3.3}>
                            <TextField
                              fullWidth
                              label="Unit Title"
                              value={unit.unit_title}
                              onChange={(e) =>
                                handleChange(e, unitIndex, null, null, 'unit_title', 'unit')
                              }
                              margin="normal"
                              size="small"
                              disabled={disabled}
                            />
                          </Grid>
                          <Grid item xs={3.3}>
                            <TextField
                              fullWidth
                              select
                              label="Type"
                              value={
                                unit.unit_type_id?.id ? unit.unit_type_id?.id : unit.unit_type_id
                              }
                              onChange={(e) =>
                                handleChange(e, unitIndex, null, null, 'unit_type_id', 'unit')
                              }
                              margin="normal"
                              size="small"
                              disabled={disabled}
                            >
                              {types.map((item) => {
                                return <MenuItem value={item.id}>{item?.name}</MenuItem>;
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={2} display="flex" alignItems="center">
                            <Button
                              variant="contained"
                              onClick={() => handleAddLo(unitIndex)}
                              size="small"
                              sx={{ mt: 1 }}
                              disabled={disabled}
                            >
                              Add Lo
                            </Button>
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemoveUnit(unitIndex)}
                              size="small"
                              sx={{ mt: 1, ml: 1, color: 'red' }}
                              disabled={disabled || unit.is_submission}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>

                        {unit.LO.map((lo, loIndex) => (
                          <Accordion key={loIndex} sx={{ mt: 1 }}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              sx={{
                                minHeight: '40px !important',
                                maxHeight: '40px !important',
                                backgroundColor: '#eeeeee',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <Typography sx={{ marginTop: 0.5 }}>
                                {lo?.lo_number ? lo?.lo_number : 'LO'}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                spacing={2}
                                sx={{ padding: '0px 0px 0px !important' }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    label="LO Number"
                                    value={lo.lo_number}
                                    onChange={(e) =>
                                      handleChange(e, unitIndex, loIndex, null, 'lo_number', 'lo')
                                    }
                                    margin="normal"
                                    size="small"
                                    disabled={disabled}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    label="LO Detail"
                                    value={lo.lo_detail}
                                    onChange={(e) =>
                                      handleChange(e, unitIndex, loIndex, null, 'lo_detail', 'lo')
                                    }
                                    margin="normal"
                                    size="small"
                                    disabled={disabled}
                                  />
                                </Grid>
                                <Grid item xs={2} display="flex" alignItems="center">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddAc(unitIndex, loIndex)}
                                    size="small"
                                    sx={{ mt: 1 }}
                                    disabled={disabled}
                                  >
                                    Add Ac
                                  </Button>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleRemoveLo(unitIndex, loIndex)}
                                    size="small"
                                    sx={{ mt: 1, ml: 1, color: 'red' }}
                                    disabled={disabled || lo.is_submission}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              {/* Single Accordion for AC */}
                              {lo?.AC?.length > 0 && (
                                <Accordion sx={{ mt: 1 }}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    sx={{
                                      minHeight: '40px !important',
                                      maxHeight: '40px !important',
                                      backgroundColor: '#eeeeee',
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '0px 16px' // Adjust padding as needed
                                    }}
                                  >
                                    <Typography sx={{ marginTop: 0.5 }}>AC</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {lo.AC.map((ac, acIndex) => (
                                      <Box key={acIndex} sx={{ mt: 0 }}>
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{ padding: '0px 0px 0px !important' }}
                                        >
                                          <Grid item xs={5}>
                                            <TextField
                                              fullWidth
                                              label="AC Number"
                                              value={ac.ac_number}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  unitIndex,
                                                  loIndex,
                                                  acIndex,
                                                  'ac_number',
                                                  'ac'
                                                )
                                              }
                                              margin="normal"
                                              size="small"
                                              disabled={disabled}
                                            />
                                          </Grid>
                                          <Grid item xs={5}>
                                            <TextField
                                              fullWidth
                                              label="AC Detail"
                                              value={ac.ac_detail}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  unitIndex,
                                                  loIndex,
                                                  acIndex,
                                                  'ac_detail',
                                                  'ac'
                                                )
                                              }
                                              margin="normal"
                                              size="small"
                                              disabled={disabled}
                                            />
                                          </Grid>
                                          <Grid item xs={2} display="flex" alignItems="center">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveAc(unitIndex, loIndex, acIndex)
                                              }
                                              size="small"
                                              sx={{ mt: 1, color: 'red' }}
                                              disabled={disabled || ac.is_submission}
                                            >
                                              <Close fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    ))}
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  {!disabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddUnit}
                      size="small"
                      sx={{ mt: units?.length > 0 ? 2 : 0 }}
                    >
                      Add Unit
                    </Button>
                  )}
                </Box>

                <Box mt={2}>
                  {docs?.length > 0 && (
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                      Document
                    </Typography>
                  )}
                  {docs?.length > 0 && (
                    <Accordion sx={{ mt: 1 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                          minHeight: '40px !important',
                          maxHeight: '40px !important',
                          backgroundColor: '#eeeeee',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography sx={{ marginTop: 0.5 }}>Documents</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {docs.map((doc, docIndex) => (
                          <Box key={docIndex} sx={{ mt: 0 }}>
                            <Grid container spacing={2} sx={{ padding: '0px 0px 0px !important' }}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Document Title"
                                  value={doc.title}
                                  onChange={(e) => handleDocChange(e, docIndex)}
                                  margin="normal"
                                  size="small"
                                  disabled={disabled}
                                />
                              </Grid>

                              <Grid item xs={2} display="flex" alignItems="center">
                                <IconButton
                                  onClick={() => handleRemoveDoc(docIndex)}
                                  size="small"
                                  sx={{ mt: 1, color: 'red' }}
                                  disabled={disabled || doc.is_submission}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {!disabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddDoc}
                      size="small"
                      sx={{ mt: docs?.length > 0 ? 2 : 0 }}
                    >
                      Add Learners Document
                    </Button>
                  )}
                </Box>

                {!disabled && (
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    // disabled={!loading}
                    >
                      {isSaveLoading ? (
                        <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </Scrollbar>
          )}
        </Card>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog
          open={openAlertModalD}
          onClose={handleCloseAlertD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlertD} color="primary">
              No
            </Button>
            <LoadingButton loading={isDeleteLoading} onClick={() => {
              handleDeleteQualification()
            }}
              color="primary" autoFocus>
              Yes
            </LoadingButton >
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
