import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Modal,
  LinearProgress,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
  Divider,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  DialogActions
} from '@mui/material';
import moment from 'moment';
import { api } from 'src/Api';
import SearchNotFound from 'src/components/SearchNotFound';
import { useNavigate } from 'react-router-dom';
import { Add, Close, CloudUpload, Close as CloseIcon, ExpandMore } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import pallete from '../../../theme/palette';
import Scrollbar from 'src/components/Scrollbar';
import Iframe from '../Iframe';


const SubmissionTab = ({ id, qualification_id }) => {
  const navigate = useNavigate();
  const [list, setList] = useState(null);
  const [listDropDown, setListDropDown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');
  const userData = JSON.parse(localStorage.getItem('user'));
  const [openChange, setOpenChange] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);

  const [openUpload, setOpenUpload] = useState(false);
  const [file, setFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedSubmission, setSelectedSubmission] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [alreadyUrl, setAlreadyUrl] = useState([]);
  const [statusss, setStatus] = useState(null);

  /////////////////////////////////////// EVIDENCE ////////////////////////////////////////
  const [openEvidence, setOpenEvidence] = useState(false);
  const [selectValues, setSelectValues] = useState(null);
  const [listEvidence, setListEvidence] = useState(null);
  const [loadingEvidence, setLoadingEvidence] = useState(false);
  const [listFeedback, setListFeeback] = useState([]);
  const [loadingFeeback, setLoadingFeeback] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [loadingComment, setLoadingComment] = useState(false);
  const [openChangeEvidence, setOpenChangeEvidence] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openFeedback2, setOpenFeedback2] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState([]);
  const [commentModal, setCommentModal] = useState('');
  const [evidenceComment, setEvidenceComment] = useState('');
  const [IQAComment, setIQAComment] = useState('');
  const [uploadSaveDisabled, setUploadSaveDisabled] = useState(true);

  const [openFeedbackText, setOpenFeedbackText] = useState(false);
  const [openCommentText, setOpenCommentText] = useState(false);
  const [check, setCheck] = useState('');

  const [iframeURL, setIframeURL] = useState('');
  const [iframeModal, setIframeModal] = useState(false);

  useEffect(() => {
    GetTypesDropdownStatus()
    getSubmissions("loading");
  }, []);

  useEffect(() => {
    if (selectValues) {
      getEvidence()
    }
  }, [selectValues]);

  useEffect(() => {
    // uploading || file?.length == 0 ? true : false || alreadyUrl ? false : true
    if (uploading) {
      setUploadSaveDisabled(true)
    } else if (file?.length == 0 && !alreadyUrl) {
      setUploadSaveDisabled(true)
    } else if (alreadyUrl) {
      setUploadSaveDisabled(false)
    }
  }, [uploading, file, alreadyUrl])


  const getEvidence = () => {
    setLoadingEvidence(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    selectValues && queryParams.append('user_id', selectValues?.user_id);
    selectValues && queryParams.append('qualification_id', selectValues?.qualification_id);
    selectValues && queryParams.append('ac_id', selectValues?.ac_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_evidence}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setListEvidence(result?.data);
        } else {
          setListEvidence(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoadingEvidence(false);
      });
  };

  const getSubbmissionFeedback = (data) => {
    setLoadingFeeback(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    data && queryParams.append('learner_id', data?.learner_id);
    data && queryParams.append('qualification_id', data?.qualification_id);
    data && queryParams.append('lo_id', data?.lo_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_assessor_feedback}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setListFeeback(result?.data);
        } else {
          setListFeeback(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoadingFeeback(false);
      });
  };

  const getCommentsData = (data) => {
    setLoadingComment(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    data && queryParams.append('learner_id', data?.learner_id);
    data && queryParams.append('qualification_id', data?.qualification_id);
    data && queryParams.append('ac_id', data?.ac_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_iqa_comment}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setCommentList(result?.data);
        } else {
          setCommentList(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoadingComment(false);
      });
  };

  const handleFeedkback = () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('qualification_id', selectedFeedback.qualification_id);
    formData.append('learner_id', selectedFeedback?.learner_id);
    formData.append('lo_id', selectedFeedback?.id);
    feedbackText && formData.append('feedback', feedbackText);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    fetch(api.save_assessor_feedback, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201 || status === 200) {
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
          setTimeout(() => {
            handleFeedbackModalClose()
            getEvidence()
          }, 2000);
        } else {
          console.error('Error uploading file:', result);
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setTimeout(() => {
            handleFeedbackModalClose()
          }, 2000);
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      })
      .finally(() => { });
  };

  const getSubmissions = (loading) => {
    setLoading(loading == 'loading' ? true : false);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    qualification_id && queryParams.append('qualification_id', qualification_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    // v1/qualifications/get_qualification_submissions_V2?user_id=240&qualification_id=133

    fetch(`${api.get_qualification_submissions_V3}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setList(result?.data);
        } else {
          setList(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoading(false);
      });
  };

  const GetTypesDropdownStatus = () => {
    setLoading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_types}?type=${userData.role.id == 5 ? "iqa_submision_status" : "assessor_submision_status"}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setListDropDown(result?.data);
        } else {
          setListDropDown(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  };

  const handleClickOpen = (comment, data) => {
    getCommentsData(data)
    setSelectedComment(comment);
    setOpen(true);
  };

  const handleEvidenceModal = (values) => {
    setSelectValues(values);
    setOpenEvidence(true);
  };

  const handleEvidenceModalClose = () => {
    setSelectValues(null);
    setOpenEvidence(false);
  };

  const handleChangeModal = (submission, type) => {
    // setStatus(submission.assessor_status)
    setSelectedSubmission(submission)
    setOpenChange(true);
    setCheck(type)
  };

  const handleChangeModalClose = (id) => {
    setSelectedSubmission({})
    setOpenChange(false);
    setIQAComment('')
    setStatus(null)
  };

  const handleUploadModal = (submission) => {
    setSelectedSubmission(submission)
    setAlreadyUrl(submission.attachement)
    setCommentModal(submission.comments)
    setOpenUpload(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedComment('');
    setCommentList([])
  };

  const handleCloseModal = () => {
    setOpenUpload(false);
    setFile([])
    setCommentModal('')
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFile((prevFiles) => {
      if (!Array.isArray(prevFiles)) {
        return [...newFiles]; // Initialize state with new files if it's not an array
      }

      return [...prevFiles, ...newFiles]; // Append new files to existing state
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <CircularProgress color="inherit" size={50} />
      </Box>
    );
  }

  if (!list) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5, backgroundColor: 'white' }}>
        <SearchNotFound message="No submissions found." />
      </Box>
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "90%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    maxHeight: 600,
    overflow: "scroll",
    overflowX: "hidden"
  };

  const submissions = list?.Unit_list;
  const assessment_criterion = listEvidence?.assessment_criterion;
  const submissionsEvidence = listEvidence?.submission_list;

  const handleSubmit = () => {
    if (!file) return;
    setUploading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('qualification_id', qualification_id);
    formData.append('unit_id', selectedSubmission?.unit_id);
    formData.append('lo_id', selectedSubmission?.lo_id);
    formData.append('ac_id', selectedSubmission?.id);
    commentModal && formData.append('comment', commentModal);
    file.map((value, index) => {
      formData.append(`file[${index}]`, value);
    })
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
    };
    fetch(api.save_submission_upload, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201 || status === 200) {
          setProgress(100);
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
          setTimeout(() => {
            handleCloseModal()
            getSubmissions()
            setFile([]);
            setProgress(0);
            setUploading(false);
          }, 2000);
        } else {
          console.error('Error uploading file:', result);
          setSnackbarMessage(result.error);
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setProgress(0);
          setUploading(false);
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      })
      .finally(() => {

      });
  };

  const handleChangeStatus = () => {
    setChangeLoading(true)
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('user_id', id);
    formData.append('qualification_id', qualification_id);
    formData.append('ac_id', selectedSubmission?.id);
    statusss && formData.append('status', statusss.name);
    userData.role.id == 5 && IQAComment && formData.append('comments', IQAComment);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    fetch(userData.role.id == 5 ? api.update_submission_iqa_status : api.update_submission_status, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201 || status === 200) {
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
          setChangeLoading(false)
          setTimeout(() => {
            handleChangeModalClose()
            getSubmissions()
          }, 2000);
        } else {
          setSnackbarMessage(result.error);
          setSnackbarOpen(true);
          setChangeLoading(false)
          setSnackbarSeverity('error');
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      })
      .finally(() => {
        setChangeLoading(false)
        setTimeout(() => {
          setSnackbarOpen(false);
          handleChangeModalClose()
        }, 2000);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRemoveFile = (fileName) => {
    setFile((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const boxStyle2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    // maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeModalEvidence = (submission) => {
    setEvidenceComment(submission.comments)
    setSelectedAttachment(submission.attachement)
    setOpenChangeEvidence(true);
  };

  const handleChangeModalCloseEvidence = (id) => {
    setOpenChangeEvidence(false);
  };

  const handleFeedbackModal = (submission) => {
    setOpenFeedback(true);
  };

  const handleFeedbackModalClose = (id) => {
    setSelectValues(null)
    setOpenFeedback(false);
    setSelectedFeedback(null)
    setFeedbackText('')
  };

  const handleModalOpenFeekBack = (submission) => {
    setFeedbackText(submission.feedback)
    setSelectedFeedback(submission)
    handleFeedbackModal();
  };

  const handleFeedbackModal2 = () => {
    setOpenFeedback2(true);
  };

  const handleFeedbackModalClose2 = (id) => {
    setListFeeback([])
    setOpenFeedback2(false);
    setSelectedFeedback(null)
    setFeedbackText('')
  };

  const handleModalOpenFeekBack2 = (submission) => {
    setSelectedFeedback(submission)
    handleFeedbackModal2();
  };

  const handleShowFeeback = (submission) => {
    handleFeedbackModal2(true)
    getSubbmissionFeedback(submission)
  }

  const handleClickOpenFeedback = (value) => {
    setFeedbackText(value.comments)
    setOpenFeedbackText(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedbackText(false);
    setFeedbackText('')
  };

  const handleClickOpenComment = (value) => {
    setCommentText(value.comments)
    setOpenCommentText(true);
  };

  const handleCloseComment = () => {
    setOpenCommentText(false);
    setCommentText('')
  };

  const handleCloseIframe = () => {
    // setIframeURL('')
    setIframeModal(false)
  }


  return (
    <Box>
      <Scrollbar sx={{ padding: "0px 0px 0px 0px" }}>
        {submissions.map((val) => {
          return (
            <Accordion sx={{ mt: 1 }} defaultExpanded>
              <AccordionSummary
                sx={{
                  minHeight: '40px !important',
                  maxHeight: '40px !important',
                  backgroundColor: "#000000",
                  color: "yellow",
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 16px',  // Adjust padding as needed
                }}
                expandIcon={<ExpandMore sx={{ color: 'yellow' }} />}
              >
                <Iconify
                  color={"yellow"}
                  icon="material-symbols:folder"
                  sx={{ marginTop: 0.5, fontSize: 20 }}
                />
                <Typography sx={{ marginTop: 0.5, flex: 1, marginLeft: 1 }}>{`${val.unit_title} - # ${val.unit_number}`}</Typography>
              </AccordionSummary>

              {val?.lo_list?.length > 0 &&
                <AccordionDetails defaultExpanded>
                  <Box sx={{ mt: 0, mb: 0 }}>
                    {val?.lo_list?.map((lo, loIndex) => (
                      <Accordion key={loIndex} sx={{ mt: 0.5 }} defaultExpanded>
                        <AccordionSummary
                          sx={{
                            minHeight: '40px !important',
                            maxHeight: '40px !important',
                            backgroundColor: '#717171',
                            color: "white",
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                        >
                          <Iconify
                            color={"white"}
                            icon="ic:baseline-assessment"
                            sx={{ marginTop: 0.7, fontSize: 20 }}
                          />
                          <Typography sx={{ marginTop: 0.5, flex: 1, marginLeft: 1 }}>
                            {`${lo.lo_detail} - # ${lo.lo_number}` || "N/A"}
                          </Typography>

                          {userData.role.id !== 4 ? (
                            <>
                              {lo?.feedback &&
                                <Button
                                  size="small"
                                  style={{
                                    justifyContent: 'center',
                                    width: 125, // Less than 30
                                    height: 28, // Ensuring it's a square button
                                    minWidth: 30, // Ensuring minimum width is set to 30
                                    padding: 0, // Removing padding to fit the icon properly
                                    lineHeight: 1, // Ensuring the icon is centered
                                    alignSelf: 'center',
                                    backgroundColor: "white",
                                    color: "black"
                                  }}
                                  variant="contained"
                                  // onClick={() => handleModalOpenFeekBack2(lo)}
                                  onClick={() => handleShowFeeback(
                                    {
                                      learner_id: id,
                                      qualification_id: qualification_id,
                                      lo_id: lo?.id,
                                    }
                                  )}
                                >
                                  <Iconify style={{ fontSize: 15, color: "black" }} icon="fluent-mdl2:feedback" />
                                  <Typography sx={{ fontSize: 11, marginLeft: 1, fontWeight: 500 }}>
                                    View Feedback
                                  </Typography>

                                </Button>
                              }
                            </>
                          ) : (
                            <Tooltip title="Add Feedback">
                              <Button
                                size="small"
                                style={{
                                  justifyContent: 'center',
                                  width: 125, // Less than 30
                                  height: 28, // Ensuring it's a square button
                                  minWidth: 30, // Ensuring minimum width is set to 30
                                  padding: 0, // Removing padding to fit the icon properly
                                  lineHeight: 1, // Ensuring the icon is centered
                                  alignSelf: 'center',
                                  backgroundColor: "white",
                                  color: "black"
                                }}
                                variant="contained"
                                onClick={() => handleModalOpenFeekBack(lo)}
                              >
                                <Iconify style={{ fontSize: 15, color: "black" }} icon="ic:round-add" />
                                <Typography sx={{ fontSize: 11, marginLeft: 1, fontWeight: 500 }}>
                                  Add Feedback
                                </Typography>

                              </Button>
                            </Tooltip>
                          )}
                        </AccordionSummary>

                        <AccordionDetails>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontSize: 13 }}>AC#</TableCell>
                                  <TableCell sx={{ fontSize: 13 }}>Assessment Criterion</TableCell>
                                  {userData.role.id == 3 && <TableCell sx={{ fontSize: 13 }}>Upload</TableCell>}
                                  <TableCell sx={{ fontSize: 13 }}>View Submission</TableCell>
                                  {<TableCell sx={{ fontSize: 13 }}>Status</TableCell>}
                                  {userData.role.id !== 3 && <TableCell sx={{ fontSize: 13 }}>IQA Status</TableCell>}
                                  {userData.role.id !== 3 && <TableCell sx={{ fontSize: 13 }}>IQA Comment</TableCell>}
                                  {userData.role.id == 4 && <TableCell sx={{ fontSize: 13 }}>Action</TableCell>}
                                  {userData.role.id == 5 && <TableCell sx={{ fontSize: 13 }}>Action</TableCell>}
                                </TableRow>
                              </TableHead>

                              {lo?.ac_list.length > 0 ? (
                                <TableBody>
                                  {lo?.ac_list?.map((submission, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{submission?.ac_number || 'N/A'}</TableCell>
                                      <TableCell>{submission?.ac_detail || 'N/A'}</TableCell>

                                      {userData.role.id == 3 && <TableCell>
                                        <IconButton

                                          disabled={
                                            submission?.status?.toLowerCase() == 'accept' ||
                                              submission?.status?.toLowerCase() == 'added to portfolio' ||
                                              submission?.status?.toLowerCase() == 'accepted' ? true : false}
                                          onClick={() => {
                                            handleUploadModal(submission)
                                          }}
                                          edge="end"
                                        >
                                          <Add />
                                        </IconButton>
                                      </TableCell>
                                      }

                                      {/* EVIDENCE */}
                                      <TableCell>
                                        {submission.is_submission ? <Button
                                          sx={{ fontSize: 12 }}
                                          variant='outlined'
                                          onClick={() => {
                                            handleEvidenceModal({
                                              user_id: id,
                                              qualification_id: qualification_id,
                                              ac_id: submission?.id,
                                              name: 'Evidence'
                                            })
                                          }}
                                        >
                                          View
                                        </Button>
                                          :
                                          "N/A"
                                        }
                                      </TableCell>

                                      {/* STATUS */}
                                      {< TableCell style={{ width: 120 }}>
                                        <Typography
                                          style={{
                                            textTransform: 'capitalize',
                                            fontWeight: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                                            fontSize: 12,
                                            backgroundColor:
                                              submission?.status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                              submission?.status?.toLowerCase() === 'added to portfolio' && "rgba(84, 214, 44, 0.16)" ||
                                              submission?.status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                              submission?.status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                                            paddingLeft: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && 5,
                                            paddingRight: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && 5,
                                            paddingTop: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && 6,
                                            paddingBottom: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && 6,
                                            textAlign: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && "center",
                                            borderRadius: (submission?.status?.toLowerCase() === 'accept' || submission?.status?.toLowerCase() === 'added to portfolio' || submission?.status?.toLowerCase() === 'in-progress' || submission?.status?.toLowerCase() === 're-submit') && 7,
                                            color:
                                              submission?.status?.toLowerCase() === 'accept' && "#54D62C" ||
                                              submission?.status?.toLowerCase() === 'added to portfolio' && "#54D62C" ||
                                              submission?.status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                              submission?.status?.toLowerCase() === 're-submit' && "#969695" || "black"
                                          }}
                                        >
                                          {submission.status || 'N/A'}
                                        </Typography>
                                      </TableCell>
                                      }

                                      {/* IQA STATUS */}
                                      {userData.role.id !== 3 &&
                                        < TableCell sx={{ width: 130 }}>
                                          <Typography
                                            style={{
                                              textTransform: 'capitalize',
                                              fontWeight:
                                                (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') ? 'bold' : " 500"
                                              ,
                                              fontSize: 12,
                                              backgroundColor: submission?.iqa_outcome?.toLowerCase() === 'agreed' ? "rgba(84, 214, 44, 0.16)" : submission?.iqa_outcome?.toLowerCase() === 'disagreed' ? "rgba(255, 72, 66,0.16)" : 'transparent',
                                              paddingLeft: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && 5,
                                              paddingRight: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && 5,
                                              paddingTop: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && 6,
                                              paddingBottom: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && 6,
                                              textAlign: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && "center",
                                              borderRadius: (submission?.iqa_outcome?.toLowerCase() === 'agreed' || submission?.iqa_outcome?.toLowerCase() === 'disagreed') && 7,
                                              color: submission?.iqa_outcome?.toLowerCase() === 'agreed' ? "#54D62C" : submission?.iqa_outcome?.toLowerCase() === 'disagreed' ? "#FF4842" : "black"
                                            }}
                                          >
                                            {submission?.iqa_outcome || 'N/A'}
                                          </Typography>
                                        </TableCell>
                                      }

                                      {/* IQA COMMENTS */}
                                      {userData.role.id !== 3 && <TableCell>
                                        {submission?.iqa_comment ? (
                                          <Button
                                            size="small"
                                            onClick={() => {
                                              handleClickOpen(submission?.iqa_comment,
                                                {
                                                  learner_id: id,
                                                  qualification_id: qualification_id,
                                                  ac_id: submission?.id,
                                                }
                                              )
                                            }}
                                            style={{
                                              justifyContent: 'center',
                                              marginTop: 5,
                                              width: 30, // Less than 30
                                              height: 30, // Ensuring it's a square button
                                              minWidth: 30, // Ensuring minimum width is set to 30
                                              padding: 0, // Removing padding to fit the icon properly
                                              lineHeight: 1, // Ensuring the icon is centered
                                              alignSelf: 'center',
                                              marginRight: 5,
                                            }}
                                            variant="outlined"
                                            component={RouterLink}
                                            to="#"
                                          >
                                            <Iconify style={{ fontSize: 17 }} icon="carbon:view-filled" />
                                          </Button>
                                        ) : (
                                          'N/A'
                                        )}
                                      </TableCell>}

                                      {userData.role.id == 4 &&
                                        <TableCell style={{ width: 150 }}>
                                          <Button disabled={!submission.is_submission} sx={{ fontSize: 12 }} variant='outlined' onClick={() => handleChangeModal(submission)}>
                                            Change Status
                                          </Button>
                                        </TableCell>
                                      }
                                      {userData.role.id == 5 &&
                                        <TableCell style={{ width: 150 }}>
                                          <Button disabled={(submission.is_submission == false || submission.status?.toLowerCase() === 'in-progress') ? true : false}
                                            sx={{ fontSize: 12, marginBottom: 0.5 }}
                                            variant='outlined'
                                            onClick={() => handleChangeModal(submission, 'iqaStatus')}
                                          >
                                            Change Status
                                          </Button>
                                          <Button disabled={(submission.is_submission == false || submission.status?.toLowerCase() === 'in-progress') ? true : false}
                                            sx={{ fontSize: 12, marginBottom: 0.5 }}
                                            variant='outlined'
                                            onClick={() => handleChangeModal(submission, 'iqaComments')}
                                          >
                                            Add Comment
                                          </Button>
                                        </TableCell>
                                      }
                                    </TableRow>
                                  ))}
                                </TableBody>
                              ) : (
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={9}>
                                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                        <SearchNotFound message="No submissions found." />
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer >
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </AccordionDetails>
              }
            </Accordion>
          )
        })}
      </Scrollbar>

      {/* VIEW COMMENTS MODAL */}
      <Modal style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }} open={open} onClose={handleClose}>
        <Box sx={[boxStyle2, { width: "60%", }]}>
          <Box p={"20px 20px 10px 20px"} flexDirection={'column'} display={'flex'} flex={1}>

            <Typography variant="h6" component="h2" textAlign="center">
              IQA Comment
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              {/* <Typography>{selectedComment}</Typography> */}

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 13 }}>IQA</TableCell>
                      <TableCell sx={{ fontSize: 13 }}>Date</TableCell>
                      <TableCell sx={{ fontSize: 13 }}>Comment</TableCell>
                    </TableRow>
                  </TableHead>

                  {commentList.length > 0 && (
                    <TableBody>
                      {commentList?.map((value, index) => (
                        <TableRow key={index}>
                          <TableCell>{value?.iqa.full_name || 'N/A'}</TableCell>
                          <TableCell>{moment(value?.created_at).format('DD/MM/YYYY') || 'N/A'}</TableCell>
                          <TableCell sx={{ maxWidth: 250 }}>
                            <RouterLink
                              className="routerLink"
                              onClick={() => handleClickOpenFeedback(value)}
                            >
                              <Typography
                                sx={{
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,  // limits text to 2 lines
                                  textOverflow: 'ellipsis',  // ensures ellipsis for long text
                                  whiteSpace: 'normal',  // allows wrapping of text
                                  cursor: 'pointer',
                                }}
                              >
                                {value?.comments || 'N/A'}
                              </Typography>
                            </RouterLink>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {commentList?.length < 0 &&
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <SearchNotFound message="No Feedback found." />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>}

                </Table>
                {loadingComment && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer >

            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ marginTop: 4 }}>
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Dialog to show full text */}
      <Dialog open={openCommentText} onClose={handleCloseComment} fullWidth maxWidth="sm">
        <Box position="relative">
          <DialogTitle>Comment</DialogTitle>
          <IconButton
            onClick={handleCloseComment}
            edge="end"
            style={{
              position: 'absolute',
              top: 8,
              right: 18
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>
            {commentText || 'N/A'}
          </Typography>
        </DialogContent>
      </Dialog>

      {/* UPLOAD FILES MODAL */}
      <Modal
        open={openUpload}
        onClose={handleCloseModal}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

            <Typography textAlign={"center"} variant="h6" component="h2">
              Upload Files
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleCloseModal}
                color="primary"
                variant="contained" disabled={uploading}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                startIcon={uploading && <CircularProgress size={20} />}
                disabled={uploadSaveDisabled}
                style={{ marginLeft: 8 }}
              >
                {uploading ? `Uploading ${progress}%` : 'Save'}
              </Button>
            </Box>
          </Box>

          <TextField
            multiline
            fullWidth
            label="Comment"
            variant="outlined"
            name="Comment"
            sx={{ mt: 2 }}
            rows={4}
            value={commentModal}
            onChange={(e) => setCommentModal(e.target.value)}
          />

          <Box display="flex" alignItems="center" mt={2}>
            {file?.length !== 0 &&
              <div style={{ marginTop: 10, flex: 1 }}>
                {file?.map((file, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Typography flex={1} textAlign={"center"} variant="body2" ml={2}>
                      {file.name}
                    </Typography>
                    <IconButton sx={{ color: "red" }} aria-label="remove file" onClick={() => handleRemoveFile(file.name)}>
                      <Close />
                    </IconButton>
                  </div>
                ))}
              </div>
            }
          </Box>

          <Box mt={2}>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-input-bottom"
              multiple
            />
            <label htmlFor="file-input-bottom">
              <Button
                variant="outlined"
                component="span"
                fullWidth
                startIcon={<CloudUpload />}
              >
                Upload File
              </Button>
            </label>
          </Box>

          {alreadyUrl?.length !== 0 &&
            <Box mt={2}>
              {alreadyUrl?.map((val, index) => (
                <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
                  <Typography mr={1}>{index + 1} :</Typography>
                  <RouterLink
                    // target='blank'
                    className="routerLink"
                    // to={val.attachement}
                    onClick={() => {
                      setIframeModal(true)
                      setIframeURL(val.attachement)
                    }}

                  >
                    <Typography noWrap>{val.attachement || 'N/A'}</Typography>
                  </RouterLink>
                </Box>
              ))}
            </Box>
          }

          {uploading && <LinearProgress variant="buffer" value={progress} valueBuffer={progress} />}
        </Box>
      </Modal>

      {/* CHANGE STATUS MODAL */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
        open={openChange}
        onClose={handleChangeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[{ ...boxStyle }, { maxWidth: '70%' }]} className="admin_modal_pa">

          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              {userData.role.id == 5 ? "IQA Outcome" : "Change Status"}
            </Typography>

            {userData.role.id == 4 &&
              <TextField
                style={{
                  width: '100%',
                  marginTop: 25,
                  textTransform: "capitalize"
                }}
                select
                value={statusss}
                label={"Status"}
                onChange={handleChange}
                className="width_full_status"
              >
                {listDropDown.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            }

            {check === 'iqaStatus' && userData.role.id == 5 &&
              <TextField
                style={{
                  width: '100%',
                  marginTop: 25,
                  textTransform: "capitalize"
                }}
                select
                value={statusss}
                label={"Status"}
                onChange={handleChange}
                className="width_full_status"
              >
                {listDropDown.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            }


            {check == 'iqaComments' && userData.role.id == 5 &&
              <TextField
                multiline
                fullWidth
                label="Comment"
                variant="outlined"
                name="Comment"
                sx={{ mt: 2 }}
                rows={6}
                value={IQAComment}
                onChange={(e) => setIQAComment(e.target.value)}
              />
            }

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button onClick={handleChangeModalClose}
                color="primary"
                variant="contained"
                disabled={changeLoading}
              >
                Close
              </Button>

              <Button
                endIcon={changeLoading && <CircularProgress size={20} />}
                disabled={changeLoading}
                onClick={handleChangeStatus}
                color="primary"
                variant="contained"
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* VIEW EVIDENCE */}
      <Dialog
        open={openEvidence}
        onClose={handleEvidenceModalClose}
        fullWidth={true}
        maxWidth="md" // Adjust the modal width as per your requirement (e.g., 'sm', 'md', 'lg')
        scroll="paper" // Makes the content scrollable
      >
        <DialogTitle>
          Submissions
        </DialogTitle>

        <DialogContent dividers={true} style={{ padding: '20px' }}>
          {loadingEvidence ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <CircularProgress color="inherit" size={50} />
            </Box>
          ) : (
            <>
              <Container sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Card sx={{ mb: 2, p: 0, width: "100%" }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid lg={20} xs={12} sm={6} sx={{ flex: 1 }}>
                        <Typography variant="h6" gutterBottom>
                          Assessment Criterion
                        </Typography>
                        <Typography
                          style={{
                            textTransform: 'capitalize',
                            width: '100%',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {assessment_criterion?.ac_detail || 'N/A'} - {assessment_criterion?.ac_number || 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Assessor Name</TableCell>
                        <TableCell>Submission Date</TableCell>
                        <TableCell>Outcome</TableCell>
                        <TableCell>Assessment Date</TableCell>
                        <TableCell>Evidence</TableCell>
                      </TableRow>
                    </TableHead>

                    {submissionsEvidence?.length > 0 ? (
                      <TableBody>
                        {submissionsEvidence?.map((submission, index) => (
                          <TableRow key={index}>
                            <TableCell>{submission?.assessor?.full_name || 'N/A'}</TableCell>
                            <TableCell>
                              {submission?.submission_date ? moment(submission?.submission_date).format('DD/MM/YYYY') : 'N/A'}
                            </TableCell>

                            <TableCell>
                              {/* Outcome styling and display */}
                              {/* {submission?.outcome || 'N/A'} */}

                              <Typography
                                style={{
                                  textTransform: 'capitalize',
                                  fontWeight: (
                                    submission?.outcome?.toLowerCase() === 'resubmission required' ||
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress') ? 'bold' : " 500",
                                  fontSize: 12,

                                  backgroundColor:
                                    submission?.outcome?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required' && "rgba(255, 72, 66,0.16)",

                                  paddingLeft: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && 5,

                                  paddingRight: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && 5,

                                  paddingTop: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && 6,

                                  paddingBottom: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && 6,

                                  textAlign: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && "center",

                                  borderRadius: (
                                    submission?.outcome?.toLowerCase() === 'accepted' ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required') && 7,

                                  color:
                                    submission?.outcome?.toLowerCase() === 'accepted' && "#54D62C" ||
                                    submission?.outcome?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                    submission?.outcome?.toLowerCase() === 'resubmission required' && "#FF4842" || "black"

                                }}
                              >
                                {submission?.outcome || 'N/A'}
                              </Typography>

                            </TableCell>

                            <TableCell>{submission?.assessment_date ? moment(submission?.assessment_date).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                            <TableCell>
                              <Button variant="contained" onClick={() => handleChangeModalEvidence(submission)}>
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                              <SearchNotFound message={`No ${selectValues?.name} Found.`} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Container>
              <Divider />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleEvidenceModalClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/*VIEW INNER EVIDENCE MODAL */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
        open={openChangeEvidence}
        onClose={handleChangeModalCloseEvidence}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, maxWidth: "70%", border: '1px solid', }} className="admin_modal_pa">

          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Evidence
            </Typography>
            <CardContent>


              <TextField
                multiline
                fullWidth
                label="Comment"
                variant="outlined"
                name="Comment"
                sx={{ mb: 2 }}
                rows={4}
                value={evidenceComment}
                disabled={true}
              />

              {selectedAttachment.map((val, index) => {
                return (
                  <Box sx={{ display: "flex" }}>
                    <Typography mr={1}>{index + 1} :</Typography>
                    <RouterLink
                      // target='blank'
                      className="routerLink"
                      // to={val.attachement}
                      onClick={() => {
                        setIframeModal(true)
                        setIframeURL(val.attachement)
                      }}
                    >
                      <Typography noWrap>{val.attachement || 'N/A'}</Typography>
                    </RouterLink>
                  </Box>
                )
              })}
            </CardContent>
            <Button style={{ marginTop: 10, display: "flex", alignSelf: "self-end" }} color="primary" variant="outlined" onClick={handleChangeModalCloseEvidence}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* FEEDBACK MODAL */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        open={openFeedback}
        onClose={handleFeedbackModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, maxWidth: "60%" }} className="admin_modal_pa">
          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Feedback
            </Typography>

            <TextField
              sx={{ marginTop: 2, marginBottom: 2 }}
              fullWidth
              variant="outlined"
              label="Feedback"
              name="feedback"
              multiline
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              rows={4}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Button variant="contained" style={{ marginTop: 10, marginLeft: 10 }} color="primary" onClick={handleFeedbackModalClose}>
                Close
              </Button>
              <Button variant="contained" style={{ marginTop: 10, marginLeft: 10 }} color="primary" onClick={handleFeedkback}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={openFeedback2}
        onClose={handleFeedbackModalClose2}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            overflow: 'hidden' // Prevent horizontal scroll
          }
        }}
      >
        <Box position="relative">
          <DialogTitle>Feedback</DialogTitle>
          <IconButton
            onClick={handleFeedbackModalClose2}
            edge="end"
            style={{
              position: 'absolute',
              top: 8,
              right: 18
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 13 }}>Assessor</TableCell>
                  <TableCell sx={{ fontSize: 13 }}>Date</TableCell>
                  <TableCell sx={{ fontSize: 13 }}>Feedback</TableCell>
                </TableRow>
              </TableHead>

              {listFeedback.length > 0 && (
                <TableBody>
                  {listFeedback?.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell>{value?.assessor.full_name || 'N/A'}</TableCell>
                      <TableCell>{moment(value?.created_at).format('DD/MM/YYYY') || 'N/A'}</TableCell>
                      <TableCell sx={{ maxWidth: 250 }}>
                        <RouterLink
                          className="routerLink"
                          onClick={() => handleClickOpenFeedback(value)}
                        >
                          <Typography
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,  // limits text to 2 lines
                              textOverflow: 'ellipsis',  // ensures ellipsis for long text
                              whiteSpace: 'normal',  // allows wrapping of text
                              cursor: 'pointer',
                            }}
                          >
                            {value?.comments || 'N/A'}
                          </Typography>
                        </RouterLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {listFeedback.length < 0 &&
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <SearchNotFound message="No Feedback found." />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>}

            </Table>
            {loadingFeeback && (
              <Box
                py={5}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </TableContainer >
        </DialogContent>
      </Dialog>

      {/* Dialog to show full text */}
      <Dialog open={openFeedbackText} onClose={handleCloseFeedback} fullWidth maxWidth="sm">
        <Box position="relative">
          <DialogTitle>Feedback</DialogTitle>
          <IconButton
            onClick={handleCloseFeedback}
            edge="end"
            style={{
              position: 'absolute',
              top: 8,
              right: 18
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>
            {feedbackText || 'N/A'}
          </Typography>
        </DialogContent>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

    </Box >
  );
};

export default SubmissionTab;
