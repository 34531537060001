import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const IQAsForm = ({ edit, selectedIQAs, handleClose, refreshPage }) => {
    const theme = useTheme();
    const [formDataIQAs, setFormDataIQAs] = useState({
        id: null,
        firstName: '',
        surname: '',
        qualification: [],
        email: '',
        contact: ''
    });

    const [error, setError] = useState({ visible: false, message: '' });
    const handleChange = (e) => {
        setError({ visible: false, message: '' });
        const { name, value } = e.target;
        setFormDataIQAs((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setFormDataIQAs((prevData) => ({
            ...prevData,
            qualification: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const [qualifications, setqualifications] = useState([]);
    const [loading, setLoading] = useState(false);

    function getQualifications() {
        const myHeaders = new Headers();

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_qualificationsV2}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${true}`;

        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setqualifications(result.data);
                } else {
                    setqualifications([]);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    useEffect(() => {
        getQualifications();
    }, []);

    useEffect(() => {
        if (edit) {
            setFormDataIQAs({
                firstName: selectedIQAs?.user_qualifications?.first_name || '',
                middleName: selectedIQAs?.user_qualifications?.middle_name || '',
                surname: selectedIQAs?.user_qualifications?.sur_name || '',
                // qualification: selectedIQAs?.user_qualifications?.map((e) => e?.qualifications_with_trash[0]?.sub_title) || [],
                qualification: selectedIQAs?.user_qualifications?.list_of_qualifications.map((e) => e.sub_title) || [],
                email: selectedIQAs?.email || '',
                contact: selectedIQAs?.user_qualifications?.contact || '',
            });
        }
    }, []);

    const appendIfNotEmpty = (formDataIQAs, key, value) => {
        if (value !== null && value !== undefined && value !== '') {
            formDataIQAs.append(key, value);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const checkQualifications = qualifications
            .filter((a) => formDataIQAs.qualification.includes(a.sub_title))
        const formdata = new FormData();

        if (edit) appendIfNotEmpty(formdata, 'id', selectedIQAs?.id);
        appendIfNotEmpty(formdata, 'first_name', formDataIQAs.firstName);
        appendIfNotEmpty(formdata, 'role_id', '5');
        appendIfNotEmpty(formdata, 'middle_name', formDataIQAs.middleName);
        appendIfNotEmpty(formdata, 'sur_name', formDataIQAs.surname);
        appendIfNotEmpty(formdata, 'qualifications', JSON.stringify(checkQualifications));
        appendIfNotEmpty(formdata, 'email', formDataIQAs.email);
        appendIfNotEmpty(formdata, 'contact', formDataIQAs.contact);
        appendIfNotEmpty(formdata, 'sampling_ratio', formDataIQAs.samplingRatio);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.create_user, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201 || status === 200) {
                    refreshPage();
                } else {
                    setError({ visible: true, message: result.error });
                }
            })
            .finally(() => setLoading(false));
    };



    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                p: 0,
                paddingTop: 2,
                paddingBottom: 0
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled={edit}
                        fullWidth
                        variant="outlined"
                        label="Code"
                        name="Code"
                        value={selectedIQAs.code}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={formDataIQAs.firstName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label=" Middle Name"
                        variant="outlined"
                        name="middleName"
                        value={formDataIQAs.middleName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Surname"
                        variant="outlined"
                        required
                        name="surname"
                        value={formDataIQAs.surname}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <MultiSelectDropDown
                        disabled={!edit}
                        required
                        id="qualification"
                        lableId="qualification-label"
                        label={'Select Qualification/s'}
                        value={formData.qualification}
                        onChange={handleSelectChange}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value?.id} label={value?.sub_title} />
                                ))}
                            </Box>
                        )}
                        data={qualifications}
                    /> */}
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="qualification">Select Qualification/s</InputLabel>
                        <Select
                            labelId="qualification-label"
                            id="qualification"
                            multiple
                            value={formDataIQAs.qualification}
                            onChange={handleSelectChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {qualifications.map((name) => (
                                <MenuItem
                                    key={name.id}
                                    value={name.sub_title}
                                    style={getStyles(name, formDataIQAs.qualification, theme)}
                                >
                                    <Typography>
                                        {name.sub_title}
                                    </Typography>
                                    {name.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                                        DELETED
                                    </Typography>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        type="email"
                        label="Email ID"
                        variant="outlined"
                        name="email"
                        value={formDataIQAs.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Contact #"
                        variant="outlined"
                        name="contact"
                        type='number'
                        value={formDataIQAs.contact}
                        onChange={handleChange}
                    />
                </Grid>

                {error?.visible && (
                    <Grid item xs={12}>
                        <Alert style={{ marginBottom: 20 }} severity="error">
                            {error?.message}
                        </Alert>
                    </Grid>
                )}
                {edit && (
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                handleSubmit(formDataIQAs);
                            }}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default IQAsForm;
