import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { TreeSelect } from 'primereact/treeselect';
import { api } from '../../../Api';
import { isBuffer } from 'lodash';

export default function CategoriesModal({ handleClose, categories, refreshAdmin }) {
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [name, setname] = useState('');

  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  function SaveCategory() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    categories && formdata.append('id', categories?.key);
    formdata.append('name', name);
    selectedNodeKey && formdata.append('parent_id', selectedNodeKey);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(categories ? api.edit_categories : api.add_categories, requestOptions)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return response.text();
        } else {
          return response.json().then((result) => {
            setalert({ visible: true, message: result.error });
          });
        }
      })
      .then((result) => {
        const response = JSON.parse(result);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  const [categoriess, setcategories] = useState([]);

  function getCategories() {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    let url = api.get_parent_categories;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        setcategories(result?.data);
      })
      .catch((error) => {
        setcategories([]);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (categories) {
      setname(categories?.data?.label);
      categories?.data?.parent_id != '0' && setSelectedNodeKey(categories?.data?.parent_id);
    }
    getCategories();
  }, []);

  return (
    <Box
      p={1}
      mt={2}
      style={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {categories ? 'Edit Category' : 'Category'}
      </Typography>

      <Box display={'flex'} sx={{ width: '90%', my: 4, flexDirection: 'column' }} appendTo={'self'}>
        {categoriess.length > 0 && (
          <TreeSelect
            appendTo={'self'}
            value={selectedNodeKey}
            onChange={(e) => {
              if (e.value == undefined) {
                setSelectedNodeKey(null);
              } else {
                setSelectedNodeKey(e.value);
              }
            }}
            options={categoriess}
            className="w-full mb-2"
            placeholder="Select Parent Category"
            showClear
            style={{
              height: 60,
              alignItems: 'center',
              paddingLeft: 10,
              borderRadius: 10
            }}
          />
        )}

        <TextField
          id="outlined-error-helper-text"
          fullWidth
          label="Category Name"
          value={name}
          onChange={(val) => {
            setname(val.target.value);
          }}
          type="text"
          variant="outlined"
          display="inline"
          style={{ marginTop: 5 }}
        />

        <Box mt={2}></Box>
        {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '90%'
        }}
      >
        <LoadingButton
          disabled={false}
          loading={loading}
          sx={{
            marginRight: 1
          }}
          onClick={SaveCategory}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton onClick={handleClose} variant="outlined">
          Close
        </LoadingButton>
      </Box>
    </Box>
  );
}
