import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    CircularProgress,
    Pagination,
    CardContent,
    TextField,
    Grid,
    Link,
    TableHead,
    TableSortLabel,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    useTheme,
    Chip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { api } from '../Api';
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



export default function Analytics() {
    const theme = useTheme();

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [analytics, setAnalytics] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [errorUserDropDown, setErrorUserDropDown] = useState(false);
    const [loadingUserDropDown, setLoadingUserDropDown] = useState(false);
    const [loading, setloading] = useState(false);
    const [show, setShow] = useState(false);

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    useEffect(() => {
        checkRender();
    }, []);

    function checkRender() {
        let check = 'analytics';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    function handleGetAnalytics() {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const selectedCustomersData = customers
            .filter((a) => selectedCustomers.includes(a.name))
            .map((a) => a.id);

        const queryParams = new URLSearchParams();
        queryParams.append('company_ids', selectedCustomersData);
        queryParams.append('date_from', dateFrom);
        queryParams.append('date_to', dateTo);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_analytics}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAnalytics(result.data);
                    setShow(true)
                } else {
                    setAnalytics([]);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
            });
    }

    function handleGetCustomers() {
        setLoadingUserDropDown(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const queryParams = new URLSearchParams();

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_customer}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setCustomers(result.data);
                    setLoadingUserDropDown(false)
                    
                } else {
                    setCustomers([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                console.log('error', error)
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
            })
            .finally(() => {
            });
    }

    const handleChangeCustomers = (event) => {
        const value = event.target.value;
        setSelectedCustomers(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Page title={`analytics | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        Analytics
                    </Typography>
                </Stack>

                <Card padding="10px 10px 10px 10px">
                    <Box
                        p={2}
                        style={{
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ width: 210 }} variant="subtitle2" gutterBottom>
                                New Learners Registrations:
                            </Typography>
                            <Grid container spacing={2} style={{ marginBottom: 16 }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        label="Select Date From"
                                        variant="outlined"
                                        fullWidth
                                        value={dateFrom}
                                        onChange={(e) => setDateFrom(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        label="Select Date To"
                                        variant="outlined"
                                        fullWidth
                                        value={dateTo}
                                        onChange={(e) => setDateTo(e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ width: 210 }} variant="subtitle2" gutterBottom>
                                Select Customer/s:
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="customers-label">Select Customer/s</InputLabel>
                                <Select
                                    onOpen={handleGetCustomers}
                                    labelId="customers-label"
                                    id="customers"
                                    multiple
                                    value={selectedCustomers}
                                    onChange={handleChangeCustomers}
                                    input={<OutlinedInput id="select-multiple-chip" label="Select Customers/s" />}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {loadingUserDropDown ? (
                                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                            <CircularProgress size={24} color="inherit" />
                                        </MenuItem>
                                    ) : errorUserDropDown ? (
                                        <MenuItem disabled>No data found</MenuItem>
                                    ) : (
                                        customers.map((name) => (
                                            <MenuItem
                                                key={name.id}
                                                value={name.name}
                                                style={getStyles(name, selectedCustomers, theme)}
                                            >
                                                {name.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" padding={2}>
                        <Button variant='contained' onClick={handleGetAnalytics}>
                            Get Analytics
                        </Button>
                    </Stack>

                    {loading ?
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 4 }}>
                            <CircularProgress />
                        </Box>
                        :
                        <>
                            {show && <Box
                                sx={{
                                    height: 200,
                                    backgroundColor: '#121315',
                                    margin: 2,
                                    borderRadius: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{ color: '#ffffff' }}
                                >
                                    {analytics}
                                </Typography>
                            </Box>
                                // :
                                // <Box
                                //     sx={{
                                //         height: 200,
                                //         backgroundColor: '#121315',
                                //         margin: 2,
                                //         borderRadius: 2,
                                //         display: 'flex',
                                //         justifyContent: 'center',
                                //         alignItems: 'center',
                                //     }}
                                // >
                                //     <Typography
                                //         variant="h1"
                                //         sx={{ color: '#ffffff' }}
                                //     >
                                //         {analytics}
                                //     </Typography>
                                // </Box>
                            }
                        </>
                    }
                </Card>
            </Container>
        </Page >
    )
}
