import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';

const IQAsForm = ({ formData, setFormData, handleSubmit, editable, error, setAlert, loading }) => {
  const handleChange = (e) => {
    setAlert({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      qualification: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const [qualifications, setqualifications] = useState([]);

  function getQualifications() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV2}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${false}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setqualifications(result.data);
          // if (formData.qualification) {
          //   setFormData((prevData) => ({
          //     ...prevData,
          //     ['qualification']: result.data.filter(
          //       (item) => item.id === formData.qualification[0].id
          //     )[0]
          //   }));
          // }
        } else {
          setqualifications([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  useEffect(() => {
    getQualifications();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label=" Middle Name"
            variant="outlined"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Surname"
            variant="outlined"
            required
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelectDropDown
            disabled={!editable}
            required
            id="qualification"
            lableId="qualification-label"
            label={'Select Qualification/s'}
            value={formData.qualification}
            onChange={handleSelectChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value?.id} label={value?.sub_title} />
                ))}
              </Box>
            )}
            data={qualifications}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="email"
            label="Email ID"
            variant="outlined"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type='number'
            label="Contact #"
            variant="outlined"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
          />
        </Grid>

        {error?.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error?.message}
            </Alert>
          </Grid>
        )}
        {editable && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleSubmit(formData);
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default IQAsForm;
