import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  IconButton,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { api } from 'src/Api';
import {
  LockReset as LockResetIcon,
  Done as DoneIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import AdminForm from 'src/components/Users/AdminForm';

const AdminDetail = () => {
  const navigate = useNavigate(); // Use navigate hook
  const { id } = useParams(); // Access the dynamic ID
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingReset, setloadingReset] = useState(false);
  const [deactiveLoader, setdeactiveLoader] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openAlertModalD, setOpenAlertModalD] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlertModal(true);
  };

  const handleCloseAlert = () => {
    setOpenAlertModal(false);
  };
  const handleOpenAlertD = () => {
    setOpenAlertModalD(true);
  };

  const handleCloseAlertD = () => {
    setOpenAlertModalD(false);
  };

  function get_user_by_id() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    // Construct the query string
    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    queryParams.append('company_admin_id', user?.company_admin_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_user_by_id_V1}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setuser(result?.data);
        } else {
          setuser(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    get_user_by_id();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshPage = () => {
    setOpen(false);
    get_user_by_id();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function action_user_status(userId, status) {
    if (status === 'inactive' || status === 'active') setdeactiveLoader(true);
    if (status === 'deleted') setdeleteLoader(true);

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('status', status);
    formdata.append('id', userId);
    formdata.append('company_admin_id', user?.company_admin_id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.action_on_user, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      })
      .then((result) => {
        setSnackbarMessage(
          `Admin successfully ${status == 'inactive' ? 'de-activated' : status == 'deleted' ? 'deleted' : 're-activated'}`
        );
        handleCloseAlert()
        handleCloseAlertD()
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate(-1)
        }, 1000);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => {
        if (status === 'inactive') setdeactiveLoader(false);
        if (status === 'deleted') setdeleteLoader(false);
      });
  }

  const handleResetPasswordLink = () => {
    setloadingReset(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.send_reset_password_link}?email=${user.email}&company_admin_id=${user?.company_admin_id}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
        } else {
          console.log('result', result)
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloadingReset(false);
      });
  }

  return (
    <Page title={`Admins | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="inherit" size={50} />
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton style={{ marginRight: 12, marginBottom: 5 }} onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h4" gutterBottom>
                Admin Details
              </Typography>
            </Stack>

            <Card sx={{ mb: 5 }}>
              <CardContent>
                <Typography variant="h6" align="left" gutterBottom>
                  Admin Name: {user?.full_name || 'DFRR'}
                </Typography>
                <Typography variant="h6" align="left" gutterBottom>
                  Email ID: {user?.email || 'asdr@hxv.com'}
                </Typography>
                {/* <Typography variant="h6" align="left" gutterBottom>
                  Sampling Ratio: {user?.user_qualifications[0]?.sampling_ratio ?
                    `${user?.user_qualifications[0]?.sampling_ratio}%`
                    : '0%'}
                </Typography> */}
              </CardContent>
            </Card>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <LoadingButton variant="outlined" color="primary"
                onClick={() => handleResetPasswordLink()}
                loading={loadingReset}
              >
                Reset Password
              </LoadingButton>

              <LoadingButton variant="outlined" color="primary" onClick={handleOpen}>
                Edit Admin Details
              </LoadingButton>

              <LoadingButton variant="outlined" color="primary"
                // disabled={user?.status?.toLowerCase() == 'inactive' ? true : false}
                onClick={() => {
                  handleOpenAlert()
                }}
                startIcon={<BlockIcon />}
                fullWidth
              >
                {user?.status?.toLowerCase() == 'inactive' ? 'Re-activate Admin' : 'De-activate Admin'}
              </LoadingButton>

              <LoadingButton
                onClick={() => {
                  handleOpenAlertD()
                }}
                startIcon={<DeleteIcon />}
                fullWidth
                variant="outlined"
              >
                Delete Admin
              </LoadingButton>

            </Box>
          </Box>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              overflow: 'hidden' // Prevent horizontal scroll
            }
          }}
        >
          <Box position="relative">
            <DialogTitle>Edit Admin Details</DialogTitle>
            <IconButton
              onClick={handleClose}
              edge="end"
              style={{
                position: 'absolute',
                top: 8,
                right: 18
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <AdminForm
              edit={true}
              selectedAdmins={user}
              handleClose={handleClose}
              refreshPage={refreshPage}
            />
          </DialogContent>
        </Dialog>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>

      <Dialog
        open={openAlertModal}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            No
          </Button>
          <LoadingButton loading={deactiveLoader} onClick={() => {
            action_user_status(
              user?.id,
              user?.status?.toLowerCase() == 'inactive' ? 'active' : 'inactive'
            );
          }}
            color="primary" autoFocus>
            Yes
          </LoadingButton >
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAlertModalD}
        onClose={handleCloseAlertD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertD} color="primary">
            No
          </Button>
          <LoadingButton loading={deleteLoader} onClick={() => {
            action_user_status(user?.id, 'deleted');
          }}
            color="primary" autoFocus>
            Yes
          </LoadingButton >
        </DialogActions>
      </Dialog>

    </Page>
  );
};

export default AdminDetail;
