import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { Alert, Button, Card, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Pagination, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, TextField, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { api } from 'src/Api';
import { UserListHead } from 'src/sections/@dashboard/user';
import moment from 'moment';
import pallete from '../theme/palette';
import { Close, PausePresentationTwoTone } from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import Iframe from 'src/components/Users/Iframe';

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    outline: 0,
};

function StandardizationMeetings() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const TABLE_HEAD = [
        { id: 'meeting_date', label: "Meeting Date", alignRight: false },
        { id: 'wiew_minutes_of_the_meeting', label: "View Minutes of the Meeting", alignRight: false },
        { id: 'title', label: "Title", alignRight: false },
        { id: 'type_of_meeting', label: "Type of Meeting", alignRight: false },
        { id: 'uploaded_by', label: "Uploaded By", alignRight: false },
    ];
    const TABLE_HEAD1 = [
        { id: 'meeting_date', label: "Meeting Date", alignRight: false },
        { id: 'wiew_minutes_of_the_meeting', label: "View Minutes of the Meeting", alignRight: false },
        { id: 'title', label: "Title", alignRight: false },
        { id: 'type_of_meeting', label: "Type of Meeting", alignRight: false },
    ];

    const TABLE_HEAD_COMPANY = [
        { id: 'meeting_date', label: "Meeting Date", alignRight: false },
        { id: 'wiew_minutes_of_the_meeting', label: "View Minutes of the Meeting", alignRight: false },
        { id: 'title', label: "Title", alignRight: false },
        { id: 'type_of_meeting', label: "Type of Meeting", alignRight: false },
        { id: 'uploaded_by', label: "Uploaded By", alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [meetingsData, setMeetingsData] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [addModal, setAddModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [attchmentData, setAttchmentData] = useState([]);

    const [selectedMeetingsTypes, setSelectedMeetingsTypes] = useState({});

    const [meetingsTypes, setMeetingsTypes] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dateSampling, setDateSampling] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [title, setTitle] = useState('');

    const [iframeURL, setIframeURL] = useState('');
    console.log("🚀 ~ StandardizationMeetings ~ iframeURL:", iframeURL)
    const [iframeModal, setIframeModal] = useState(false);

    const isIQANotFound = meetingsData.length === 0;

    useEffect(() => {
        getMinuteOfMeeting(page);
        getMeetingTypes()
    }, [page]);

    useEffect(() => {
        checkRender();
    }, []);

    useEffect(() => {
        if (search.length == 0) {
            meetingsData.length = 0;
            getMinuteOfMeeting(page);
        }
    }, [search]);

    const refreshAdmin = () => {
        setAddModal(false);
        meetingsData.length = 0;
        getMinuteOfMeeting(page);
    };

    function checkRender() {
        let check = 'standardization meetings';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const getMeetingTypes = () => {
        const token = localStorage.getItem('token');

        fetch(`${api.get_types}?type=minutes_of_meetings_types`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setMeetingsTypes(result.data);
                } else {
                    setMeetingsTypes([]);
                }
            })
            .catch((error) => {
                console.log('error', error)
            });
    };

    const handlePageChange = (event, value) => {
        meetingsData.length = 0;
        setPage(value);
        getMeetingTypes(value);
    };

    function getMinuteOfMeeting(page) {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('company_admin_id', user?.company_admin_id);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_minutes_of_meetings}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setMeetingsData(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                } else {
                    setMeetingsData([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    const handleCloseAddModal = () => {
        setAddModal(false);
        setDateSampling('')
        setSelectedFile(null);
        setSelectedMeetingsTypes([]);
    }

    const handleOpenAddModal = () => {
        setAddModal(true)
    }

    const handleCloseDetailModal = () => {
        setDetailModal(false);
        setAttchmentData([])
    }

    const handleOpenDetailModal = (attachement) => {
        setDetailModal(true)
        setAttchmentData(attachement)
    }

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setSelectedFile((prevFiles) => {
            if (!Array.isArray(prevFiles)) {
                return [...newFiles]; // Initialize state with new files if it's not an array
            }

            return [...prevFiles, ...newFiles]; // Append new files to existing state
        });
    };

    const handleSubmit = () => {

        if (!dateSampling || !selectedMeetingsTypes || !selectedFile) {
            setSnackbarMessage("Please enter all field");
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }

        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();
        dateSampling && formdata.append('date', dateSampling); // Added dateSampling value
        selectedMeetingsTypes && formdata.append('type', selectedMeetingsTypes.name);
        title && formdata.append('title', title);
        // selectedFile && formdata.append('file', selectedFile);
        selectedFile && selectedFile.map((value, index) => {
            formdata.append(`file[${index}]`, value);
        })

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.save_minutes_of_meetings, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201) {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    refreshAdmin();
                    handleCloseAddModal()
                } else {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.error);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setSubmitLoading(false)
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleCloseAddModal()
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleRemoveFile = (fileName) => {
        setSelectedFile((prevFiles) => prevFiles.filter(file => file.name !== fileName));
    };

    const handleCloseIframe = () => {
        // setIframeURL('')
        setIframeModal(false)
    }

    return (
        <Page title={`standardization_meetings | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        Standardization Meetings
                    </Typography>
                    {permission.includes('add') && (
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={handleOpenAddModal}
                        >
                            Add
                        </Button>
                    )}
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'90%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={'Search Standardization Meetings'}
                            onEnter={() => {
                                setsearchLoading(true);
                                meetingsData.length = 0;
                                getMinuteOfMeeting();
                            }}
                        />
                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getMinuteOfMeeting(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead headLabel={userData.role.id == 2 && TABLE_HEAD_COMPANY ||
                                    userData.role.id == 7 && TABLE_HEAD || TABLE_HEAD1} rowCount={meetingsData.length} />

                                <TableBody>
                                    {meetingsData.map((row) => {
                                        const { id, date, attachement, type, uploaded_by, title } = row;
                                        return (
                                            <TableRow key={id}>
                                                <TableCell noWrap align="left">
                                                    {date ? moment(date).format('DD/MM/YYYY') : 'N/A'}
                                                </TableCell>

                                                <TableCell noWrap align="left" >
                                                    {attachement ? (
                                                        <Button
                                                            // target='blank'
                                                            size="small"
                                                            style={{
                                                                justifyContent: 'flex-start',
                                                                alignSelf: 'center',
                                                                marginRight: 5
                                                            }}
                                                            variant="outlined"
                                                            component={RouterLink}
                                                            // to={attachement}
                                                            onClick={() => {
                                                                // setIframeModal(true)
                                                                // setIframeURL(attachement)
                                                                handleOpenDetailModal(attachement)
                                                            }}
                                                            startIcon={
                                                                <Iconify color={pallete.primary.main} icon="carbon:view-filled" />
                                                            }
                                                        >
                                                            Click to see
                                                        </Button>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {title || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {type || 'N/A'}
                                                </TableCell>
                                                {userData.role.id !== 5 && <TableCell noWrap align="left">
                                                    {uploaded_by || 'N/A'}
                                                </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isIQANotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No Standardization Meetings Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Dialog open={detailModal} onClose={handleCloseDetailModal} aria-labelledby="dialog-title" aria-describedby="dialog-description" fullWidth maxWidth="md">
                <DialogTitle id="dialog-title">Details</DialogTitle>

                <DialogContent dividers>
                    {attchmentData?.map((val, index) => {
                        return (
                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                {/* <Typography mr={1}>{index + 1} :</Typography> */}
                                <RouterLink
                                    // target='blank'
                                    className="routerLink"
                                    // to={val.attachement}
                                    onClick={() => {
                                        setIframeModal(true)
                                        setIframeURL(val.attachement)
                                    }}
                                >
                                    <Typography noWrap sx={{ fontSize: 13, marginRight: 2 }}>{val.attachement || 'N/A'}</Typography>
                                </RouterLink>
                            </Box>
                        )
                    })}
                </DialogContent>

                <DialogActions>
                    <Button disabled={submitLoading} variant="contained" color="primary" onClick={handleCloseDetailModal}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={addModal} onClose={handleCloseAddModal} aria-labelledby="dialog-title" aria-describedby="dialog-description" fullWidth maxWidth="sm">
                <DialogTitle id="dialog-title">Add Standardization Meeting</DialogTitle>

                <DialogContent dividers>
                    <Paper>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Date of Sampling"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                name="DateofSampling"
                                value={dateSampling}
                                onChange={(e) => setDateSampling(e.target.value)}
                                sx={{ mb: 2 }}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ mb: 2 }}
                                select
                                fullWidth
                                required
                                label="Select Type"
                                variant="outlined"
                                name="type"
                                value={selectedMeetingsTypes}
                                onChange={(e) => setSelectedMeetingsTypes(e.target.value)}
                            >
                                {meetingsTypes.map((item) => (
                                    <MenuItem key={item.name} value={item}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{ mb: 2 }}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <input
                                accept="*"
                                id="upload-file"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                multiple
                                required
                            />
                            <label htmlFor="upload-file">
                                <LoadingButton variant="outlined" component="span">
                                    Upload File
                                </LoadingButton>
                            </label>
                            <Box display="flex" alignItems="center" mt={2}>
                                {selectedFile?.length !== 0 && (
                                    <div style={{ marginTop: 10, flex: 1 }}>
                                        {selectedFile?.map((file, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                <Typography flex={1} textAlign="center" variant="body2" ml={2}>
                                                    {file.name}
                                                </Typography>
                                                <IconButton sx={{ color: "red" }} aria-label="remove file" onClick={() => handleRemoveFile(file.name)}>
                                                    <Close />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Box>
                        </Grid>
                    </Paper>
                </DialogContent>

                <DialogActions>
                    <Button disabled={submitLoading} variant="contained" color="primary" onClick={handleCloseAddModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={submitLoading} variant="contained" color="primary" sx={{ ml: 2 }} onClick={handleSubmit}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Page >
    )
}

export default StandardizationMeetings
