import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import OtpInput from 'react-otp-input';
import { useTheme } from '@emotion/react';
import { api } from 'src/Api';

// ----------------------------------------------------------------------//

export default function OtpForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [alert, setalert] = useState({ visible: false, message: '', color: 'success' });
  const [loading, setloading] = useState(false);

  const otpInputStyle = {
    width: '100%',
    height: '56px',
    textAlign: 'center',
    fontSize: '18px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px',
    border: `1px solid ${theme.palette.primary.main} !important`
  };

  const otpContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  };

  // const codeCheck = localStorage.getItem('code');

  function VerifyCode() {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: localStorage.getItem('email'),
        otp: otp
      }),
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.otp_verify, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          navigate('/changePassword', { replace: true });
          setalert({ visible: true, message: response.message, color: 'success' });
          return response.json();
        } else {
          return response.json().then((error) => {
            setalert({ visible: true, message: error.error, color: 'error' });
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        setalert({ visible: true, message: error.message, color: 'error' });
      })
      .finally(() => {
        setloading(false);
      });
  }

  return (
    <Stack spacing={2}>
      {alert.visible && (
        <Alert
          onClose={() => {
            setalert({ visible: false, message: '', color: 'success' });
          }}
          severity={alert?.color}
        >
          {alert.message}
        </Alert>
      )}
      <div style={otpContainerStyle}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>&nbsp;</span>}
          renderInput={(props) => <input {...props} style={otpInputStyle} />}
        />
      </div>
      <LoadingButton
        onClick={() => {
          VerifyCode();
          // if (otp == codeCheck) {
          //   navigate('/changePassword', { replace: true });
          // } else {
          //   setalert({
          //     visible: true,
          //     message: 'Invalid Code. Please check again',
          //     color: 'error'
          //   });
          // }
        }}
        fullWidth
        size="large"
        variant="contained"
        loading={loading}
      >
        Continue
      </LoadingButton>
    </Stack>
  );
}
