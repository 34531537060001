import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
  IconButton,
  Modal,
  LinearProgress,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  TextField,
} from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { UserListHead } from 'src/sections/@dashboard/user';
import { api } from 'src/Api';
import { Add, Close, CloudUpload } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import Iframe from '../Iframe';

export default function DocsTab({ id, qualification_id }) {
  const navigate = useNavigate();

  const COMPANYADMINHEAD = [
    { id: 'id', label: 'Title', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'assessor_comments', label: 'Assessor Comments', alignRight: false },
    { id: 'iqa_status', label: 'IQA Status', alignRight: false },
    { id: 'iqa_comments', label: 'IQA Comments', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];

  const LEARNERADMINHEAD = [
    { id: 'id', label: 'Title', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'assessor_comments', label: 'Assessor Comments', alignRight: false },
    { id: 'upload', label: 'Upload Doc', alignRight: false },
  ];

  const ASSESSORTABLEHEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'comment', label: 'Comments', alignRight: false },
    { id: 'iqa_status', label: 'IQA Status', alignRight: false },
    { id: 'iqa_comments', label: 'IQA Comments', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false }
  ];

  const IQATABLEHEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'comment', label: 'Comments', alignRight: false },
    { id: 'assessor_status', label: 'Assessor Status', alignRight: false },
    { id: 'assessor_comments', label: 'Assessor Comments', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
  ];

  const EQATABLEHEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'iqa status', label: 'IQA Status', alignRight: false },
    // { id: 'comment', label: 'Comments', alignRight: false },
    { id: 'assessor_status', label: 'Assessor Status', alignRight: false },
    // { id: 'assessor_comments', label: 'Assessor Comments', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
  ];

  const userData = JSON.parse(localStorage.getItem('user'));
  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);

  const [openUpload, setOpenUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [SelectedDocUploadId, setSelectedDocUploadId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [alreadyUrl, setAlreadyUrl] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [statusss, setStatus] = useState(null);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [comments, setComments] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [commentView, setCommentView] = useState(false);

  const [iframeURL, setIframeURL] = useState('');
  console.log("🚀 ~ DocsTab ~ iframeURL:", iframeURL)
  const [iframeModal, setIframeModal] = useState(false);

  const [listDropDown, setListDropDown] = useState([]);

  function getDocuments() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    queryParams.append('company_admin_id', user?.company_admin_id);
    qualification_id && queryParams.append('qualification_id', qualification_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    };

    fetch(`${api.get_documents}?${queryParams.toString()}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        setlist(result.data);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
      })
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    GetTypesDropdownStatus()
    getDocuments();
  }, []);

  const isDataNotFound = list.length === 0;

  const handleUploadModal = (submission) => {
    setSelectedDocUploadId(userData.role.id == 3 ? submission.document_title.id : submission.id)
    setAlreadyUrl(userData.role.id == 3 ? submission.attachment : submission.assessor_attachment)
    setOpenUpload(true);
  };

  const handleCloseModal = () => {
    setOpenUpload(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const GetTypesDropdownStatus = () => {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_types}?type=${userData.role.id == 5 ? "iqa_submision_status" : "assessor_submision_status"}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setListDropDown(result?.data);
        } else {
          setListDropDown(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  };

  const handleChangeModalClose = (id) => {
    setOpenStatusModal(false);
    setStatus(null)
  };

  const handleCommentModalClose = (id) => {
    setOpenCommentModal(false);
    setComments(null)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 1
  };

  const handleSubmit = () => {
    setUploading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('user_id', id);
    qualification_id && formData.append('qualification_id', qualification_id);
    userData.role.id !== 3 && formData.append('document_id', SelectedDocUploadId);
    userData.role.id == 3 && formData.append('req_document_id', SelectedDocUploadId);
    userData?.role.id !== 5 && statusss && formData.append('status', statusss.name);
    userData?.role.id == 5 && statusss && formData.append('iqa_status', statusss.name);
    userData?.role.id !== 5 && comments && formData.append('comments', comments);
    userData?.role.id == 5 && comments && formData.append('iqa_comment', comments);
    file && formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
    };
    fetch(userData?.role.id == 3 ? api.save_document_upload : api.update_document_detail, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201 || status === 200) {
          setProgress(100);
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity("success")
          setlist([])
          setTimeout(() => {
            handleCloseModal()
            handleChangeModalClose()
            handleCommentModalClose()
          }, 2000);
          getDocuments()

        } else {
          console.error('Error uploading file:', result);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("error")
          setSnackbarOpen(true);
          setTimeout(() => {
            handleCloseModal()
            handleChangeModalClose()
            handleCommentModalClose()
          }, 2000);
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      })
      .finally(() => {
        setTimeout(() => {
          setFile(null);
          setStatus(null)
          setComments(null)
          setProgress(0);
          setUploading(false);
        }, 2000);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleOpenComment = (value) => {
    setSelectedComment(value);
    setCommentView(true)
  };

  const handleCloseComment = (event) => {
    setSelectedComment(null);
    setComments(null)
    setCommentView(false)
  };

  const handleChangeStatusModal = (submission) => {
    // setSelectedDocUploadId(submission.id)
    setSelectedDocUploadId(userData.role.id == 3 ? submission.document_title.id : submission.id)
    setOpenStatusModal(true);
  };

  const handleOpenCommentModal = (submission, commentData) => {
    setComments(commentData)
    setSelectedDocUploadId(userData.role.id == 3 ? submission.document_title.id : submission.id)
    setOpenCommentModal(true);
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const boxStyle2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    // maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const handleCloseIframe = () => {
    setIframeModal(false)
    // setIframeURL('')
  }

  return (
    <Box>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>

            <Table>
              <UserListHead headLabel={
                userData.role.id == 2 && COMPANYADMINHEAD ||
                userData.role.id == 3 && LEARNERADMINHEAD ||
                userData.role.id == 4 && ASSESSORTABLEHEAD ||
                userData.role.id == 5 && IQATABLEHEAD ||
                userData.role.id == 7 && EQATABLEHEAD ||
                COMPANYADMINHEAD
              } rowCount={list.length} />
              <TableBody>
                {list.map((row) => {
                  const { id, document_title, status, attachment, iqa_status, comments, iqa_comment, assessor_attachment } = row;
                  if (userData?.role?.id == 2) {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DOCUMENTS TITLE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {document_title?.title || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                              paddingLeft: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingRight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingTop: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              paddingBottom: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              textAlign: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && "center",
                              borderRadius: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 7,
                              color: status?.toLowerCase() === 'accept' && "#54D62C" ||
                                status?.toLowerCase() === 'accepted' && "#54D62C" ||
                                status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                status?.toLowerCase() === 're-submit' && "#969695" || "black"
                            }}
                          >
                            {status || 'N/A'}
                          </Typography>

                        </TableCell>

                        {/* ASSESSOR COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {comments ?
                              <Button variant='outlined' onClick={() => handleOpenComment({ name: "Assessor Comment", comment: comments })}>
                                View
                              </Button>
                              :
                              'N/A'
                            }
                          </Typography>
                        </TableCell>

                        {/* IQA STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                iqa_status?.toLowerCase() === 'agreed' && "rgba(84, 214, 44, 0.16)" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "rgba(252, 3, 3, 0.16)",
                              paddingLeft: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingRight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingTop: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              paddingBottom: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              textAlign: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && "center",
                              borderRadius: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 7,
                              color: iqa_status?.toLowerCase() === 'agreed' && "#54D62C" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "#fc0303" || "black"
                            }}
                          >
                            {iqa_status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* IQA COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {iqa_comment ?
                              <Button variant='outlined' onClick={() => handleOpenComment({ name: "IQA Comment", comment: iqa_comment })}>
                                View
                              </Button>
                              :
                              'N/A'
                            }
                          </Typography>
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell scope="row" padding="normal" sx={{ maxWidth: 170 }}>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              width: 150,
                              alignSelf: 'center',
                              marginRight: 3,
                              fontSize: 11
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(attachment)
                            }}
                            disabled={!attachment}
                            startIcon={
                              <Iconify
                                color={!attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Doc
                          </Button>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              marginRight: 3,
                              width: 150,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={assessor_attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(assessor_attachment)
                            }}
                            disabled={!assessor_attachment}
                            startIcon={
                              <Iconify
                                color={!assessor_attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Assessor Doc
                          </Button>
                        </TableCell>

                      </TableRow>
                    );
                  } else if (userData.role.id == 3) {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DOCUMENTS TITLE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {document_title?.title || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                              paddingLeft: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingRight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingTop: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              paddingBottom: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              textAlign: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && "center",
                              borderRadius: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 7,
                              color: status?.toLowerCase() === 'accept' && "#54D62C" ||
                                status?.toLowerCase() === 'accepted' && "#54D62C" ||
                                status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                status?.toLowerCase() === 're-submit' && "#969695" || "black"
                            }}
                          >
                            {status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* ASSESSOR COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {comments ?
                              <Button variant='outlined' onClick={() => handleOpenComment({ name: "Assessor Comment", comment: comments })}>
                                View
                              </Button>
                              :
                              'N/A'
                            }
                          </Typography>
                        </TableCell>

                        {/* UPLOAD DOC */}
                        <TableCell scope="row" padding="normal">
                          <IconButton
                            // disabled={status == "Accepted" ? true : false}
                            disabled={status?.toLowerCase() == 'accept' || status?.toLowerCase() == 'accepted' ? true : false}
                            onClick={() => {
                              handleUploadModal(row)
                            }}
                            edge="end"
                          >
                            <Add />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  } else if (userData.role.id == 4) {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DOCUMENTS TITLE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {document_title?.title || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                              paddingLeft: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingRight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingTop: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              paddingBottom: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              textAlign: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && "center",
                              borderRadius: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 7,
                              color: status?.toLowerCase() === 'accept' && "#54D62C" ||
                                status?.toLowerCase() === 'accepted' && "#54D62C" ||
                                status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                status?.toLowerCase() === 're-submit' && "#969695" || "black"
                            }}
                          >
                            {status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/*COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          {comments ?
                            <Button variant='outlined' onClick={() => handleOpenComment({ name: "Comment", comment: comments })}>
                              View
                            </Button>
                            :
                            'N/A'
                          }
                        </TableCell>

                        {/* IQA STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                iqa_status?.toLowerCase() === 'agreed' && "rgba(84, 214, 44, 0.16)" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "rgba(252, 3, 3, 0.16)",
                              paddingLeft: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingRight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingTop: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              paddingBottom: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              textAlign: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && "center",
                              borderRadius: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 7,
                              color: iqa_status?.toLowerCase() === 'agreed' && "#54D62C" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "#fc0303" || "black"
                            }}
                          >
                            {iqa_status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* IQA COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          {iqa_comment ? <Button variant='outlined' onClick={() => handleOpenComment({ name: "IQA Comment", comment: iqa_comment })}>
                            View
                          </Button>
                            :
                            'N/A'
                          }
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell scope="row" padding="normal" sx={{ maxWidth: 170 }}>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              width: 150,
                              alignSelf: 'center',
                              marginRight: 3,
                              fontSize: 11
                            }}
                            disabled={!attachment}
                            variant="outlined"
                            onClick={() => handleChangeStatusModal(row)}
                            startIcon={
                              <Iconify
                                color={!attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            Change Status
                          </Button>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              marginRight: 3,
                              width: 150,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            disabled={!attachment}
                            variant="outlined"
                            onClick={() => handleOpenCommentModal(row, row.comments)}
                            startIcon={
                              <Iconify
                                color={!attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            Add Comments
                          </Button>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              marginRight: 3,
                              width: 150,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            disabled={!attachment}
                            variant="outlined"
                            onClick={() => handleUploadModal(row)}
                            startIcon={
                              <Iconify
                                color={!attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            Add File
                          </Button>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              marginRight: 3,
                              width: 150,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(attachment)
                            }}
                            disabled={!attachment}
                            // onClick={() => handleUploadModal(row)}
                            startIcon={
                              <Iconify
                                color={!attachment ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Learner Doc
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  } else if (userData.role.id == 5) {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DOCUMENTS TITLE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {document_title?.title || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                iqa_status?.toLowerCase() === 'agreed' && "rgba(84, 214, 44, 0.16)" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "rgba(252, 3, 3, 0.16)",
                              paddingLeft: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingRight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingTop: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              paddingBottom: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              textAlign: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && "center",
                              borderRadius: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 7,
                              color: iqa_status?.toLowerCase() === 'agreed' && "#54D62C" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "#fc0303" || "black"
                            }}
                          >
                            {iqa_status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* ASSESSOR COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          {iqa_comment ?
                            <Button variant='outlined' onClick={() => handleOpenComment({ name: "iqa_comment", comment: iqa_comment })}>
                              View
                            </Button>
                            :
                            'N/A'
                          }
                        </TableCell>

                        {/* ASSESSOR STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                              paddingLeft: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingRight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingTop: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              paddingBottom: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              textAlign: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && "center",
                              borderRadius: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 7,
                              color: status?.toLowerCase() === 'accept' && "#54D62C" ||
                                status?.toLowerCase() === 'accepted' && "#54D62C" ||
                                status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                status?.toLowerCase() === 're-submit' && "#969695" || "black"
                            }}
                          >
                            {status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* COMMENTS */}
                        <TableCell scope="row" padding="normal">
                          {comments ?
                            <Button variant='outlined' onClick={() => handleOpenComment({ name: "Assessor Comment", comment: comments })}>
                              View
                            </Button>
                            :
                            'N/A'
                          }
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell scope="row" padding="normal" sx={{ maxWidth: 170 }}>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              width: 150,
                              alignSelf: 'center',
                              marginRight: 3,
                              fontSize: 11
                            }}
                            variant="outlined"
                            onClick={() => handleChangeStatusModal(row)}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress') ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress') ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            Change Status
                          </Button>

                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              width: 150,
                              marginRight: 3,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            onClick={() => handleOpenCommentModal(row, row.iqa_comment)}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress') ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress') ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            Add Comments
                          </Button>

                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              width: 150,
                              marginRight: 3,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(attachment)
                            }}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress') ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress') ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Learner Doc
                          </Button>

                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              width: 150,
                              marginRight: 3,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={assessor_attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(assessor_attachment)
                            }}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress' || !assessor_attachment) ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress' || !assessor_attachment) ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Assessor Doc
                          </Button>
                        </TableCell>

                      </TableRow>
                    )
                  } else if (userData.role.id == 7) {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DOCUMENTS TITLE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle4" flexWrap={'wrap'}>
                            {document_title?.title || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ width: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                iqa_status?.toLowerCase() === 'agreed' && "rgba(84, 214, 44, 0.16)" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "rgba(252, 3, 3, 0.16)",
                              paddingLeft: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingRight: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 5,
                              paddingTop: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              paddingBottom: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 6,
                              textAlign: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && "center",
                              borderRadius: (iqa_status?.toLowerCase() === 'agreed' || iqa_status?.toLowerCase() === 'disagreed') && 7,
                              color: iqa_status?.toLowerCase() === 'agreed' && "#54D62C" ||
                                iqa_status?.toLowerCase() === 'disagreed' && "#fc0303" || "black"
                            }}
                          >
                            {iqa_status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* ASSESSOR COMMENTS */}
                        {/*       <TableCell scope="row" padding="normal">
                          {iqa_comment ?
                            <Button variant='outlined' onClick={() => handleOpenComment({ name: "iqa_comment", comment: iqa_comment })}>
                              View
                            </Button>
                            :
                            'N/A'
                          }
                        </TableCell> */}

                        {/* ASSESSOR STATUS */}
                        <TableCell scope="row" padding="normal" sx={{ maxWidth: 130 }}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') ? 'bold' : " 500",
                              fontSize: 12,
                              backgroundColor:
                                status?.toLowerCase() === 'accept' && "rgba(84, 214, 44, 0.16)" ||
                                status?.toLowerCase() === 'in-progress' && "rgba(24, 144, 255, 0.16)" ||
                                status?.toLowerCase() === 're-submit' && "rgba(168, 168, 165, 0.3)",
                              paddingLeft: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingRight: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 5,
                              paddingTop: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              paddingBottom: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 6,
                              textAlign: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && "center",
                              borderRadius: (status?.toLowerCase() === 'accept' || status?.toLowerCase() === 'in-progress' || status?.toLowerCase() === 're-submit') && 7,
                              color: status?.toLowerCase() === 'accept' && "#54D62C" ||
                                status?.toLowerCase() === 'in-progress' && "#1890FF" ||
                                status?.toLowerCase() === 're-submit' && "#969695" || "black"
                            }}
                          >
                            {status || 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* COMMENTS */}
                        {/* <TableCell scope="row" padding="normal">
                          {comments ?
                            <Button variant='outlined' onClick={() => handleOpenComment({ name: "Assessor Comment", comment: comments })}>
                              View
                            </Button>
                            :
                            'N/A'
                          }
                        </TableCell> */}

                        {/* ACTIONS */}
                        <TableCell scope="row" padding="normal" sx={{ maxWidth: 150 }}>
                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              width: 150,
                              marginRight: 3,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(attachment)
                            }}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress') ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress') ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Learner Doc
                          </Button>

                          <Button
                            size="small"
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              width: 150,
                              marginRight: 3,
                              fontSize: 11,
                              marginTop: 3
                            }}
                            variant="outlined"
                            // target='blank'
                            component={RouterLink}
                            // to={assessor_attachment}
                            onClick={() => {
                              setIframeModal(true)
                              setIframeURL(assessor_attachment)
                            }}
                            disabled={(status == null || status?.toLowerCase() === 'in-progress' || !assessor_attachment) ? true : false}
                            startIcon={
                              <Iconify
                                color={(status == null || status?.toLowerCase() === 'in-progress' || !assessor_attachment) ? "#A7B1BC" : "#000000"}
                                icon="carbon:view-filled"
                              />
                            }
                          >
                            View Assessor Doc
                          </Button>
                        </TableCell>

                      </TableRow>
                    )
                  }
                })}
              </TableBody>

              {
                isDataNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                          <SearchNotFound message={`No Document Found`} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }
            </Table>

            {loading && (
              <Box
                py={5}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1
                }}
              >
                <CircularProgress />
              </Box>
            )}

          </TableContainer>
        </Scrollbar>
      </Card>

      <Modal
        open={openUpload}
        onClose={handleCloseModal}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-modal-description"
      >
        <Box sx={style}>
          <Typography textAlign={"center"} variant="h6" component="h2">
            Upload File
          </Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-input"
            />

            {file ? (
              <>
                <Typography flex={1} textAlign={"center"} variant="body2" ml={2}>
                  {file.name}
                </Typography>
                <IconButton sx={{ color: "red" }} aria-label="remove file" onClick={() => setFile(null)}>
                  <Close />
                </IconButton>
              </>
            )
              :
              <label style={{
                display: "flex",
                backgroundColor: 'rgba(0, 0, 0,0.1)',
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                cursor: "pointer",
                border: "1px dashed black",
                padding: "10px 0px 10px 0px"
              }} htmlFor="file-input">
                <CloudUpload sx={{ height: 50, width: 50 }} />
              </label>
            }
          </Box>
          {alreadyUrl &&
            <RouterLink
              // target='blank'
              className="routerLink"
              // to={alreadyUrl}
              onClick={() => {
                setIframeModal(true)
                setIframeURL(alreadyUrl)
              }}
            >
              <Typography sx={{
                marginTop: 2,
                marginBottom: 2,
                wordBreak: 'break-word',  // Break long words
                whiteSpace: 'pre-wrap',   // Preserve whitespace and allow wrapping
              }}>{alreadyUrl}</Typography>
            </RouterLink>
          }
          {uploading && <LinearProgress variant="buffer" value={progress} />}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={handleCloseModal} color="primary"
              variant="contained" disabled={uploading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              startIcon={uploading && <CircularProgress size={20} />}
              disabled={uploading}
              style={{ marginLeft: 8 }}
            >
              {uploading ? `Uploading ${progress}%` : alreadyUrl ? 'Replace' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
        open={openStatusModal}
        onClose={handleChangeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[{ ...boxStyle }, { maxWidth: '70%' }]} className="admin_modal_pa">

          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Change Status
            </Typography>

            <TextField
              style={{
                width: '100%',
                marginTop: 25,
                textTransform: "capitalize"
              }}
              select
              value={statusss}
              label={"Status"}
              onChange={handleChange}
              className="width_full_status"
            >
              {listDropDown.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button onClick={handleChangeModalClose}
                color="primary"
                variant="contained"
                disabled={uploading}
              >
                Close
              </Button>

              <Button
                endIcon={uploading && <CircularProgress size={20} />}
                disabled={uploading}
                onClick={() => handleSubmit()}
                color="primary"
                variant="contained"
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
        open={openCommentModal}
        onClose={handleCommentModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[{ ...boxStyle }, { maxWidth: '70%' }]} className="admin_modal_pa">

          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Add Comment
            </Typography>

            <TextField
              multiline
              fullWidth
              label="Comment"
              variant="outlined"
              name="Comment"
              sx={{ mt: 2 }}
              rows={6}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button onClick={handleCommentModalClose}
                color="primary"
                variant="contained"
                disabled={uploading}
              >
                Close
              </Button>

              <Button
                endIcon={uploading && <CircularProgress size={20} />}
                disabled={uploading}
                onClick={() => handleSubmit()}
                color="primary"
                variant="contained"
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
        open={commentView}
        onClose={handleCloseComment}
      >
        <Box sx={[boxStyle2, { width: "60%", }]}>
          <Box p={"20px 20px 10px 20px"} flexDirection={'column'} display={'flex'} flex={1}>

            <Typography variant="h6" component="h2" textAlign="center">
              {selectedComment?.name}
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <Typography>{selectedComment?.comment}</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ marginTop: 4 }}>
              <Button variant="contained" onClick={handleCloseComment}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box >
  );
}
