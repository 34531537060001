import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import Alert from '@mui/material/Alert';

export default function ChangePasswordForm() {
  const navigate = useNavigate();

  const [email, setemail] = useState('');
  const [alert, setalert] = useState({ visible: false, message: '' });

  useEffect(() => {
    setemail(localStorage.getItem('email'));
  }, []);

  const RegisterSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setPassword();
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps } = formik;
  const [loading, setloading] = useState(false);
  const [disabled, setdisabled] = useState(false);

  function setPassword() {
    var formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', values.password);
    formdata.append('password_confirmation', values.confirmPassword);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.updateforgotpassword, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((error) => {
            setalert({ visible: true, message: error.error });
          });
        }
      })
      .then((result) => {
        setalert({ visible: true, message: result.message });
        localStorage.clear();
        navigate('/login', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        // setalert({ visible: true, message: error.message });
      })
      .finally(() => {
        setloading(false);
      });
  }

  const [showPassword, setshowPassword] = useState(false);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setshowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setshowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity={alert.message.includes('updated') ? 'success' : 'error'}
            >
              {alert.message}
            </Alert>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            disabled={disabled}
          >
            Save
          </LoadingButton>
          <LoadingButton
            style={{ marginTop: 10 }}
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => {
              navigate('/login', { replace: true });
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
