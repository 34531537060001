import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="#">
      <Box
        component="img"
        src={process.env.REACT_APP_LOGO}
        sx={{
          padding: 1,
          // marginLeft: 1,
          width: 300,
          height: 100,
          alignSelf: "center",
          ...sx
        }}
      />
    </RouterLink>
  );
}
