import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Rolepermission from './pages/Rolepermission';
import User from './pages/User/User';
import NotFound from './pages/Page404';
import Forgot from './pages/Forgot';
import ChangePassword from './pages/ChangePassword';
import OtpVerification from './pages/OtpVerification';
import Bugs from './pages/Bugs';
import Faqs from './pages/Faqs';
import FaqCategories from './pages/FaqCategories';
import Colors from './pages/Colors';
import Categories from './pages/Categories';
import Withdraw_request from './pages/Withdraw_Request';
import DashboardApp from './pages/DashboardApp';
import ExistingQualificationsList from './pages/Qualification/ExistingQualificationsList.js';
import AddQualifications from './pages/Qualification/AddQualifications';
import Form from './pages/Qualification/Form';
import LearnerDetails from './pages/User/LearnerDetail/LearnerDetails';
import Qualifications_Subjects from './pages/User/Qualifications_Subjects';
import Assigned_Learner from './pages/User/Assigned_Learner';
import AssessorDetail from './pages/User/AssessorDetail';
import IqaDetail from './pages/User/IqaDetail';
import Registered_Learner from './pages/User/Registered_Learner';
import ViewEvidence from './pages/User/LearnerDetail/ViewEvidence';
import Resource_Materials_list from './pages/ResourceMaterials/Resource_Materials_list';
import ResourceForm from './pages/ResourceMaterials/Resource_Form';
import Communications from './pages/Communications';
import ResourceEdit from './pages/ResourceMaterials/Resource_Edit';
import IQAReports from './pages/IQAReports';
import StandardizationMeetings from './pages/StandardizationMeetings';
import AssessorList from './pages/AssessorList';
import QASampling from './pages/QASampling';
import Invoices from './pages/Invoices';
import Analytics from './pages/Analytics';
import CustomerList from './pages/Customer/CustomerList';
import InvoiceRegisteredLearner from './pages/InvoiceRegisteredLearner';
import EqaDetail from './pages/User/EqaDetail';
import AdminDetail from './pages/User/AdminDetail';

export default function Router() {
  const localPerm = JSON.parse(localStorage.getItem('permissions'));
  const token = localStorage.getItem('token');

  return useRoutes([
    {
      path: '/',
      element: token ? <Navigate to="/dashboard/qualifications" /> : <LogoOnlyLayout />,
      children: [
        { path: 'app', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'changePassword', element: <ChangePassword /> },
        { path: 'otpVerification', element: <OtpVerification /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
        {
          path: 'app',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'dashboard') ? (
            <DashboardApp />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'roles',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'role/permissions'
          ) ? (
            <Rolepermission />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'colors',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'colors') ? (
            <Colors />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'categories',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'categories'
          ) ? (
            <Categories />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'withdraw_request',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'withdraw request'
          ) ? (
            <Withdraw_request />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <User />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/learnerDetails/:id/:qualificationId',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <LearnerDetails />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/eqaDetails/:id/:qualificationId',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <EqaDetail />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/adminDetails/:id/:qualificationId',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <AdminDetail />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/learnerDetails/viewEvidence',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <ViewEvidence />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/learnerDetails/viewFeedback',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <ViewEvidence />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/AssessorDetails/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <AssessorDetail />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/IqaDetails/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <IqaDetail />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/EqaDetails/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <EqaDetail />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/qualifications_subjects/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <Qualifications_Subjects />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/assigned_learners/:id/:role_id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <Assigned_Learner />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'users/registered_learners/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'users') ? (
            <Registered_Learner />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'communications',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'communication'
          ) ? (
            <Communications />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'faqs',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'faqs') ? (
            <Faqs />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'faq_categories',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'faq categories'
          ) ? (
            <FaqCategories />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'bug_reports',
          element: localPerm?.some(
            (item) => item.permission_name.toLowerCase() === 'bug reports'
          ) ? (
            <Bugs />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications',
          element: <ExistingQualificationsList />
        },
        {
          path: 'qualifications/qualificationDetails/:id/:qualificationId',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <LearnerDetails />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications/registered_learners/:id',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <Registered_Learner />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications/qualificationDetails/viewEvidence',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <ViewEvidence />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications/qualificationDetails/viewFeedback',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <ViewEvidence />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications/qualificationDetails/viewAssessmentDates',
          element: localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <ViewEvidence />
          ) : (
            <Navigate to="/404" />
          )
        },
        {
          path: 'qualifications/addQualifications',
          element: <AddQualifications />
        },
        {
          path: 'qualifications/addQualifications/form/:id',
          element: <Form />
        },
        {
          path: 'qualifications/addQualifications/form',
          element: <Form />
        },
        {
          path: 'qualifications/resource_materials/:id/:qualification_id',
          element: <Resource_Materials_list />
        },
        {
          path: 'qualifications/iq_reports/:id/:qualification_id',
          element: <IQAReports />
        },

        {
          path: 'resource_materials',
          element: <Resource_Materials_list />
        },
        {
          path: 'resource_materials/resource_form',
          element: <ResourceForm />
        },
        {
          path: 'resource_materials/folder/:id',
          element: <ResourceForm />
        },
        {
          path: 'resource_materials/file/:folderId/:id/:folder_name',
          element: <ResourceEdit />
        },
        {
          path: 'resource_materials/file/:folder_name/:folder_id',
          element: <ResourceEdit />
        },

        //IQA SCREENS
        {
          path: 'iq_reports',
          element: <IQAReports />,
        },
        {
          path: 'standardization_meetings',
          element: <StandardizationMeetings />
        },
        {
          path: 'assessors',
          element: <AssessorList />
        },
        {
          path: 'qa_samlings',
          element: <QASampling />
        },
        {
          path: 'qa_samlings/QADetails/:id/:qualificationId',
          element:
            // localPerm?.some((item) => item.permission_name.toLowerCase() === 'qualifications') ? (
            <LearnerDetails />
          // ) : (
          //   <Navigate to="/404" />
          // )
        },

        // Invoices
        {
          path: '/dashboard/invoices',
          element: <Invoices />
        },
        {
          path: 'customers/invoices/:id',
          element: <Invoices />
        },
        {
          path: 'analytics',
          element: <Analytics />
        },
        {
          path: 'customers/invoices/registered_learner_invoice/:invoice_no',
          element: <InvoiceRegisteredLearner />
        },

        // CUSTOMER
        {
          path: 'customers',
          element: <CustomerList />
        },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
