import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const AdminForm = ({ edit, selectedAdmins, handleClose, refreshPage }) => {
    console.log("🚀 ~ AdminForm ~ selectedAdmins:", selectedAdmins)
    const theme = useTheme();
    const [formDataEQAs, setFormDataEQAs] = useState({
        id: null,
        firstName: '',
        middleName: '',
        surname: '',
        email: '',
        contact: ''
    });

    const [error, setError] = useState({ visible: false, message: '' });
    const handleChange = (e) => {
        setError({ visible: false, message: '' });
        const { name, value } = e.target;
        setFormDataEQAs((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setFormDataEQAs((prevData) => ({
            ...prevData,
            qualification: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const [qualifications, setqualifications] = useState([]);
    const [loading, setLoading] = useState(false);

    function getQualifications() {
        const myHeaders = new Headers();

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_qualifications}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}`;

        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setqualifications(result.data);
                } else {
                    setqualifications([]);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    useEffect(() => {
        getQualifications();
    }, []);

    useEffect(() => {
        if (edit) {
            setFormDataEQAs({
                firstName: selectedAdmins?.first_name || '',
                middleName: selectedAdmins?.middle_name || '',
                surname: selectedAdmins?.sur_name || '',
                email: selectedAdmins?.email || '',
                contact: selectedAdmins?.contact || '',
            });
        }
    }, []);

    const appendIfNotEmpty = (formDataEQAs, key, value) => {
        if (value !== null && value !== undefined && value !== '') {
            formDataEQAs.append(key, value);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();

        if (edit) appendIfNotEmpty(formdata, 'id', selectedAdmins?.id);
        appendIfNotEmpty(formdata, 'first_name', formDataEQAs.firstName);
        appendIfNotEmpty(formdata, 'role_id', '6');
        appendIfNotEmpty(formdata, 'middle_name', formDataEQAs.middleName);
        appendIfNotEmpty(formdata, 'sur_name', formDataEQAs.surname);
        appendIfNotEmpty(formdata, 'email', formDataEQAs.email);
        appendIfNotEmpty(formdata, 'contact', formDataEQAs.contact);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.create_user, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201 || status === 200) {
                    refreshPage();
                } else {
                    setError({ visible: true, message: result.error });
                }
            })
            .finally(() => setLoading(false));
    };



    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                p: 0,
                paddingTop: 2,
                paddingBottom: 0
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled={edit}
                        fullWidth
                        variant="outlined"
                        label="Code"
                        name="Code"
                        value={selectedAdmins?.code}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={formDataEQAs.firstName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label=" Middle Name"
                        variant="outlined"
                        name="middleName"
                        value={formDataEQAs.middleName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Surname"
                        variant="outlined"
                        required
                        name="surname"
                        value={formDataEQAs.surname}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        type="email"
                        label="Email ID"
                        variant="outlined"
                        name="email"
                        value={formDataEQAs.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Contact #"
                        variant="outlined"
                        name="contact"
                        type='number'
                        value={formDataEQAs.contact}
                        onChange={handleChange}
                    />
                </Grid>

                {error?.visible && (
                    <Grid item xs={12}>
                        <Alert style={{ marginBottom: 20 }} severity="error">
                            {error?.message}
                        </Alert>
                    </Grid>
                )}
                {edit && (
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                handleSubmit(formDataEQAs);
                            }}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AdminForm;
