import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Alert,
  Typography,
  IconButton,
  Autocomplete
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import CloseIcon from '@mui/icons-material/Close';

const CommunicationModal = ({ refreshAdmin, handleClose }) => {
  const [formData, setFormData] = useState({
    to: [],
    title: '',
    message: ''
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ visible: false, message: '' });

  useEffect(() => {
    getUsers();
  }, []);

  const handleChange = (e) => {
    setError({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // const handleUserChange = (event, value) => {
  //   const selectedUser = users.find((user) => user.full_name === value);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     to: selectedUser ? selectedUser.id : null
  //   }));
  // };

  const handleUserChange = (event, value) => {
    const selectedUserIds = value.map((selectedValue) => {
      const selectedUser = users.find((user) => user.full_name === selectedValue.full_name);
      return selectedUser ? selectedUser.id : null;
    }).filter(id => id !== null); // Filter out null values

    setFormData((prevData) => ({
      ...prevData,
      to: selectedUserIds.toString()
    }));
  };

  const getUsers = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_dropdown_users}?company_admin_id=${user?.company_admin_id}`
      , requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setUsers(result.data);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => console.log('error', error));
  };

  const handleSubmit = () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formData?.to?.length !== 0 && formdata.append('to', formData.to);
    formData?.title && formdata.append('topic', formData?.title);
    formData?.message && formdata.append('message', formData?.message);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.send_message, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201) {
          refreshAdmin();
        } else {
          setError({ visible: true, message: result.error });

        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 2
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'} pb={2} px={1}>
        <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Send Message
        </Typography>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          edge="end"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {/* <Autocomplete
            disabled={users.length === 0 ? true : false}
            id="free-solo-demo"
            freeSolo
            multiple
            options={users?.map((option) => option.full_name)}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select Recipient" />}
          /> */}
          <Autocomplete
            disabled={users.length === 0}
            id="multi-select-demo"
            multiple
            options={users}
            getOptionLabel={(option) => option.full_name}
            getOptionSelected={(option, value) => option.full_name === value.full_name}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select Recipient" />}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            required
            label="Title"
            variant="outlined"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Message"
            variant="outlined"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Grid>

        {error.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error.message}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {'Send'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommunicationModal;
