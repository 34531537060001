import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { Encryption } from 'src/functions/function';
import { api } from 'src/Api';

export default function RegisterForm() {
  const navigate = useNavigate();
  const [alert, setalert] = useState({ visible: false, message: '', color: 'success' });
  const [isPhone, setisPhone] = useState(false);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required('Key is required')
      .test(
        'valid-key',
        'Key must be a valid 11-digit phone number or a valid email address',
        function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const phoneRegex = /^\d{11}$/;
          if (phoneRegex.test(value)) {
            setisPhone(true);
          } else {
            setisPhone(false);
          }
          return emailRegex.test(value) || phoneRegex.test(value);
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      sendLink();
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps } = formik;
  const [loading, setloading] = useState(false);

  function sendLink() {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: Encryption(values.email, process.env.REACT_APP_SECRET_KEY),
        verification_method: isPhone ? 1 : 0
      }),
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.send_forgot_password, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json().then((error) => {
            setalert({ visible: true, message: error.error, color: 'error' });
          });
        }
      })
      .then((result) => {
        setloading(false);
        setalert({ visible: true, message: result.message, color: 'success' });
        localStorage.setItem('email', values.email);
        setTimeout(() => {
          navigate('/otpVerification', { replace: true });
        }, 400);
      })
      .catch((error) => {
        console.log('error', error);
        // setalert({ visible: true, message: error.error, color: 'error' });
      })
      .finally(() => {
        setloading(false);
      });
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            required
            fullWidth
            type="email"
            label="Email address / Phone number"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '', color: 'success' });
              }}
              severity={alert?.color}
            >
              {alert.message}
            </Alert>
          )}

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Send
          </LoadingButton>
          <LoadingButton
            style={{ marginTop: 10 }}
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
