import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    CircularProgress,
    Pagination,
    CardContent,
    TextField,
    Grid,
    Link,
    TableHead,
    TableSortLabel,
    Snackbar,
    Alert,
    IconButton,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { api } from '../Api';
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { ArrowBack } from '@mui/icons-material';
import moment from 'moment';

export default function InvoiceRegisteredLearner() {
    const { invoice_no } = useParams();
    const navigate = useNavigate()
    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const userData = JSON.parse(localStorage.getItem('user'));

    const [invoiceList, setInvoiceList] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const isInvoiceNotFound = invoiceList.length === 0;

    useEffect(() => {
        handleGetInvoices(page);
    }, [page]);

    useEffect(() => {
        checkRender();
    }, []);

    useEffect(() => {
        if (search.length == 0) {
            invoiceList.length = 0;
            handleGetInvoices(page);
        }
    }, [search]);

    function checkRender() {
        let check = 'invoices';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const handlePageChange = (event, value) => {
        invoiceList.length = 0;
        setPage(value);
        handleGetInvoices(value);
    };

    function handleGetInvoices(page) {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('invoice_no', invoice_no);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_invoice_detail}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setInvoiceList(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
                } else {
                    setInvoiceList([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    const handleChangeStatus = (id, statyyys) => {
        setSaveLoading(true);
        // Implement save functionality here
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        var formdata = new FormData();

        formdata.append('id', id);
        formdata.append('status', statyyys);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.action_learner_status, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSaveLoading(false);
                    setInvoiceList([])
                    handleGetInvoices()
                    handleSnackbarClose()
                } else {
                    setSaveLoading(false);
                    setSnackbarMessage(result.error);
                    setSnackbarSeverity('error');
                    handleSnackbarClose()
                }
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('error', error);
                setSnackbarMessage('An error occurred while saving.');
                setSaveLoading(false);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleStatusChange = (index, newStatus) => {
        setInvoiceList((prevList) => {
            const updatedList = [...prevList];
            const customer = updatedList[index];

            // Update status in the local state
            customer.status = newStatus;

            // Call API to update status on the server
            handleChangeStatus(customer.learner.id, newStatus);

            return updatedList;
        });
    };

    return (
        <Page title={`invoices | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
                        Registered Learner Invoice
                    </Typography>
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'90%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={'Search Registered Learner'}
                            onEnter={() => {
                                setsearchLoading(true);
                                invoiceList.length = 0;
                                handleGetInvoices();
                            }}
                        />
                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                handleGetInvoices(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr#</TableCell>
                                        <TableCell>Candidate Name</TableCell>
                                        <TableCell>Unique Number</TableCell>
                                        <TableCell>Registered on</TableCell>
                                        <TableCell>Payment Due in</TableCell>
                                        <TableCell>Invoice # </TableCell>
                                        <TableCell>Billing status</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {invoiceList.map((row, index) => {
                                        const { id, learner, payment_due, billing_status, status, code } = row;
                                        console.log("🚀 ~ {invoiceList.map ~ row:", row)
                                        const user = JSON.parse(localStorage.getItem('user'));
                                        return (
                                            <TableRow hover onClick={() => { }} key={id} tabIndex={-1} role="checkbox" sx={{ backgroundColor: row?.learner?.disability == 1 ? 'rgba(255, 54, 1,0.3)' : 'transparnet' }}>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500, }}>
                                                    <Typography >
                                                        {learner.full_name || 'N/A'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    {code || 'N/A'}
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    {learner.created_at ? moment(learner.created_at).format('DD/MM/YYYY') : 'N/A'}
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    {payment_due || 'N/A'}
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    {invoice_no || 'N/A'}
                                                </TableCell>
                                                <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            fontWeight: (
                                                                billing_status?.toLowerCase() === 'unpaid' ||
                                                                billing_status?.toLowerCase() === 'paid') ? 'bold' : " 500",
                                                            fontSize: 12,

                                                            backgroundColor:
                                                                billing_status?.toLowerCase() === 'paid' && "rgba(84, 214, 44, 0.16)" ||
                                                                billing_status?.toLowerCase() === 'unpaid' && "rgba(255, 72, 66,0.16)",

                                                            paddingLeft: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && 5,

                                                            paddingRight: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && 5,

                                                            paddingTop: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && 6,

                                                            paddingBottom: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && 6,

                                                            textAlign: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && "center",

                                                            borderRadius: (
                                                                billing_status?.toLowerCase() === 'paid' ||
                                                                billing_status?.toLowerCase() === 'unpaid') && 7,

                                                            color:
                                                                billing_status?.toLowerCase() === 'paid' && "#54D62C" ||
                                                                billing_status?.toLowerCase() === 'unpaid' && "#FF4842" || "black"

                                                        }}
                                                    >
                                                        {billing_status || 'N/A'}
                                                    </Typography>
                                                    {/* {billing_status || 'N/A'} */}
                                                </TableCell>
                                                {userData.role.id !== 2 && <TableCell align="left" style={{ maxWidth: 500 }}>
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={status}
                                                        exclusive
                                                        onChange={(event, newStatus) => handleStatusChange(index, newStatus)}
                                                        aria-label="Platform"
                                                    >
                                                        <ToggleButton
                                                            value="active"
                                                            sx={{
                                                                fontSize: '12px', // Smaller font size
                                                                padding: '4px 8px', // Less padding for smaller button size
                                                                minWidth: '80px', // Ensuring the button isn't too small
                                                                '&.Mui-selected': {
                                                                    backgroundColor: 'green',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            Active
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="inactive"
                                                            sx={{
                                                                fontSize: '12px', // Smaller font size
                                                                padding: '4px 8px', // Less padding for smaller button size
                                                                minWidth: '80px', // Ensuring the button isn't too small
                                                                '&.Mui-selected': {
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            Deactivated
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </TableCell>}

                                                {userData.role.id == 2 &&
                                                    <TableCell align="left" style={{ maxWidth: 500 }}>
                                                        <Typography
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                fontWeight: (
                                                                    status?.toLowerCase() === 'inactive' ||
                                                                    status?.toLowerCase() === 'active') ? 'bold' : " 500",
                                                                fontSize: 12,

                                                                backgroundColor:
                                                                    status?.toLowerCase() === 'active' && "rgba(84, 214, 44, 0.16)" ||
                                                                    status?.toLowerCase() === 'inactive' && "rgba(255, 72, 66,0.16)",

                                                                paddingLeft: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && 6,

                                                                paddingRight: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && 6,

                                                                paddingTop: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && 6,

                                                                paddingBottom: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && 6,

                                                                textAlign: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && "center",

                                                                borderRadius: (
                                                                    status?.toLowerCase() === 'active' ||
                                                                    status?.toLowerCase() === 'inactive') && 7,

                                                                color:
                                                                    status?.toLowerCase() === 'active' && "#54D62C" ||
                                                                    status?.toLowerCase() === 'inactive' && "#FF4842" || "black"

                                                            }}
                                                        >
                                                            {status == 'inactive' ? "Deactived" : status || 'N/A'}
                                                        </Typography>
                                                        {/* {billing_status || 'N/A'} */}
                                                    </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isInvoiceNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No Registered Learner Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>
                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Page>
    )
}
