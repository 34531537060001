import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  TextField,
  CardContent,
  Alert,
  MenuItem,
  Snackbar
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';

export default function Withdraw_request() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'remarks', label: 'Remarks', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'change status'];

  function checkRender() {
    let check = 'withdraw request';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [statusss, setStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getWithdrawalRequests(value);
  };

  function getWithdrawalRequests(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    // Construct the query string
    const queryParams = new URLSearchParams();
    search.length > 0 && queryParams.append('search', search);
    page && queryParams.append('page', page);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_withdrawal_request}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setlist(result.data);
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  function getWithdrawalStatus() {
    setloading(true);
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.get_withdraw_request_status, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setStatusList(result.data);
        } else {
          setStatusList([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const handleOpenStatus = () => setOpenStatus(true);

  const handleCloseStatus = () => {
    setOpenStatus(false);
    setStatus(null);
  };

  useEffect(() => {
    getWithdrawalRequests();
    getWithdrawalStatus();
  }, []);

  const [openStatus, setOpenStatus] = React.useState(false);
  const [user, setuser] = useState(null);

  useEffect(() => {
    if (user) {
      const selected = statusList?.filter(
        (e) => e.name.toLowerCase() === user?.status.toLowerCase()
      );
      setSelectedStatus(selected[0]);
    }
  }, [user]);

  function openModalStatus(user) {
    setuser(user);
    handleOpenStatus();
  }

  const isUserNotFound = list.length === 0;

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const refreshAdmin = () => {
    getWithdrawalRequests(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getWithdrawalRequests(page);
    }
  }, [search]);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const [remarks, setremarks] = useState('');
  const [loadingButton, setloadingButton] = useState(false);

  function handleChangeStatus(status, remarks) {
    setloadingButton(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('id', user.id);
    status && formdata.append('status', status.name);
    remarks && remarks != undefined && formdata.append('remarks', remarks);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.request_status_change, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const response = JSON.parse(body);
        if (status === 200 || status === 201) {
          refreshAdmin();
          handleCloseStatus();
          setremarks('');
          setmessage(response?.message);
          setmode('success');
          setshowSnackbar(true);
        } else {
          //   setalert({ visible: true, message: 'Something went wrong' });
          setmessage(response?.message);
          setmode('error');
          setshowSnackbar(true);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setloadingButton(false);
        handleCloseStatus();
      });
  }

  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState('');
  const [mode, setmode] = useState('error');

  return (
    <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* SNACKBAR FOR DELETE */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setshowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setshowSnackbar(false);
            }}
            severity={mode}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        {/* CHANGE STATUS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openStatus}
          onClose={handleCloseStatus}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <Box p={1} py={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Change Status
              </Typography>
              <CardContent>
                <TextField
                  style={{
                    width: '100%'
                  }}
                  select
                  value={statusss || selectedStatus}
                  label={'Status'}
                  onChange={handleChange}
                  className="width_full_status"
                >
                  {statusList.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  style={{ width: '100%', marginTop: 10 }}
                  id="outlined-select-currency"
                  label={'Remarks'}
                  value={remarks}
                  onChange={(e) => setremarks(e.target.value)}
                />

                <Box style={{ marginTop: 20 }} display={'flex'} justifyContent={'flex-end'}>
                  <LoadingButton
                    onClick={() => {
                      handleChangeStatus(statusss, remarks);
                    }}
                    variant="contained"
                    style={{ marginRight: 5 }}
                    loading={loadingButton}
                  >
                    Save
                  </LoadingButton>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleCloseStatus}
                    size="large"
                  >
                    Close
                  </Button>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Withdraw Request
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getWithdrawalRequests();
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getWithdrawalRequests(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {list.map((row) => {
                    const { user, id, remarks, amount, status } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar alt={user?.name} src={user?.avatar} />
                            <Box style={{ display: 'flex', flexDirection: 'column', width: 120 }}>
                              <Typography variant="subtitle2" flexWrap={'wrap'}>
                                {user?.name || 'N/A'}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{amount ? '$ ' + amount : 'N/A'}</TableCell>
                        <TableCell align="left">{remarks || 'N/A'}</TableCell>
                        <TableCell align="left">
                          <Label
                            style={{ height: 30, width: 100 }}
                            variant="ghost"
                            color={
                              (status.toLowerCase() === 'on hold' && 'info') ||
                              (status.toLowerCase() === 'approved' && 'success') ||
                              (status.toLowerCase() === 'declined' && 'error') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {permission.includes('change status') && (
                            <Button
                              size="small"
                              onClick={() => openModalStatus(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,

                                alignSelf: 'center',
                                marginRight: 10
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={<Iconify color={pallete.primary.main} icon="bx:edit" />}
                            >
                              change status
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
