import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { api } from '../../../Api';

export default function ColorsModal({ handleClose, color, refreshAdmin }) {
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [name, setname] = useState('');
  const [code, setcode] = useState(null);

  function SaveColor() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    color && formdata.append('id', color?.id);
    formdata.append('name', name);
    code && code != undefined && formdata.append('code', code);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(color ? api.edit_color : api.add_color, requestOptions)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return response.text();
        } else {
          return response.json().then((result) => {
            setalert({ visible: true, message: result.error });
          });
        }
      })
      .then((result) => {
        const response = JSON.parse(result);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  useEffect(() => {
    if (color) {
      setname(color?.name);
      setcode(color?.code);
    }
  }, []);

  return (
    <Box
      p={1}
      mt={2}
      style={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {color ? 'Edit Color' : 'Color'}
      </Typography>

      <Box display={'flex'} sx={{ width: '90%', my: 4, flexDirection: 'column' }}>
        <TextField
          id="outlined-error-helper-text"
          fullWidth
          label="Color Name"
          value={name}
          onChange={(val) => {
            setname(val.target.value);
          }}
          type="text"
          variant="outlined"
          display="inline"
        />
        <Box mt={2}></Box>
        <TextField
          id="outlined-error-helper-text"
          fullWidth
          label="Color Code"
          value={code}
          onChange={(val) => {
            setcode(val.target.value);
          }}
          type="text"
          variant="outlined"
          display="inline"
        />
        <Box mt={2}></Box>
        {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '90%'
        }}
      >
        <LoadingButton
          disabled={false}
          loading={loading}
          sx={{
            marginRight: 1
          }}
          onClick={SaveColor}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton onClick={handleClose} variant="outlined">
          Close
        </LoadingButton>
      </Box>
    </Box>
  );
}
