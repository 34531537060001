import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Card,
    Snackbar,
    Alert,
    Typography,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Stack,
    Chip,
    CircularProgress,
    Link
} from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Scrollbar from '../../components/Scrollbar';
import Page from 'src/components/Page';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';
import Iframe from 'src/components/Users/Iframe';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function ResourceEdit() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id, folderId, folder_name, folder_id } = useParams(); // Assuming ID is passed in the URL for editing
    const [folderArry, setFolderArry] = useState([]);
    const [fileName, setFileName] = useState('');
    const [qualifications, setQualification] = useState([]);
    const [roles, setRoles] = useState([]);
    const [batchs, setBatch] = useState([]);
    const [url, setUrl] = useState('');
    const [files, setFiles] = useState(null);
    const [filesType, setFileType] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedQualifications, setSelectedQualifications] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);
    const [isGetLoading, setGetLoading] = useState(false);

    const [iframeURL, setIframeURL] = useState('');
    const [iframeModal, setIframeModal] = useState(false);

    useEffect(() => {
        getResourceData()
        if (id) {
            getResourceDataEdit(id); // Fetch existing data if editing
        }
    }, [id]);

    const getQualifications = () => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_qualificationsV3}?company_admin_id=${user?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualification(result.data);
                    getUserRoles();
                } else {
                    setQualification([]);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getUserRoles = () => {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_user_roles}?company_admin_id=${user?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setRoles(result.data);
                    getBatch();
                } else {
                    setRoles([]);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getBatch = () => {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_batch_no}?company_admin_id=${user?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setBatch(result.data);
                } else {
                    setBatch([]);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getResourceDataEdit = () => {
        setGetLoading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_resource_materail}?id=${id}&folder_id=${folderId}&company_admin_id=${user?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSelectedFolder(result.data[0].files[0].id || null);
                    setFileName(result.data[0].files[0].file_name || '');
                    setSelectedQualifications(result.data[0].files[0].qualification.map((e) => e.sub_title) || []);
                    setSelectedRoles(result.data[0].files[0].role.map((e) => e.role_name) || []);
                    setSelectedBatch(result.data[0].files[0].batch_numbers.map((e) => e.cohort_batch_no) || []);
                    setUrl(result.data[0].files[0].file || '');
                    setFileType(result.data[0].files[0].file_type || '');
                    setGetLoading(false)
                } else {
                    setGetLoading(false)
                }
            })
            .catch((error) => { console.log('error', error); setGetLoading(false) });
    };

    const getResourceData = () => {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_resource_materail}?company_admin_id=${user?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setFolderArry(result.data);
                    getQualifications();
                } else {
                    setFolderArry([]);
                }
            })
            .catch((error) => { console.log('error', error) });
    };

    const handleFileUpload = (event) => {
        setFiles(event.target.files[0]); // Only select the first file
        setUrl(''); // Clear URL if file is selected
        setFileType('file'); // Set fileType to 'file'
    };

    const handleFileRemove = () => {
        setFiles(null); // Remove the selected file
        setFileType(''); // Clear fileType
    };

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
        if (event.target.value) {
            setFiles(null); // Clear files if URL is entered
            setFileType(''); // Clear fileType
        }
    };

    const handleUrlRemove = () => {
        setUrl(''); // Clear URL
        setFileType(''); // Clear fileType to enable the URL input
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = () => {
        setIsSaveLoading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);


        const rolescheck = roles
            .filter((role) => selectedRoles.includes(role.role_name))
            .map((role) => role.id);
        const checkQualifications = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);
        const checkBatches = batchs
            .filter((a) => selectedBatch.includes(a.cohort_batch_no))
            .map((a) => a.cohort_batch_no);

        var formdata = new FormData();

        formdata.append('folder_id', folderId ? folderId : folder_id);
        formdata.append('file_name', fileName);
        formdata.append('file_type', url ? "url" : "file");
        files && formdata.append('file', files);
        formdata.append('qualification_ids', JSON.stringify(checkQualifications));
        formdata.append('role_ids', JSON.stringify(rolescheck));
        formdata.append('file_url', url ? url : '0');
        formdata.append('batch_numbers', JSON.stringify(checkBatches));

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(selectedFolder ? `${api.update_resource_materail}?id=${id}` : api.save_resource_materail, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setIsSaveLoading(false);
                    setTimeout(() => {
                        navigate('/dashboard/resource_materials');
                    }, 1000);
                } else {
                    setIsSaveLoading(false);
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setIsSaveLoading(false);
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleDelete = () => {
        setDeleteLoading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.delete_resource_materail}?id=${id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setDeleteLoading(false);
                    setTimeout(() => {
                        navigate('/dashboard/resource_materials');
                    }, 1000);
                } else {
                    setDeleteLoading(false);
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setDeleteLoading(false);
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleChangeRoles = (event) => {
        const value = event.target.value;
        setSelectedRoles(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeQualifications = (event) => {
        const value = event.target.value;
        setSelectedQualifications(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeBatches = (event) => {
        const value = event.target.value;
        setSelectedBatch(typeof value === 'string' ? value.split(',') : value);
    };

    const isCohortEnabled = selectedRoles.some((role) => role === 'Learners');

    const handleCloseIframe = () => {
        setIframeModal(false)
        // setIframeURL('')
    }

    return (
        <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
                        {id ? 'Edit Resource Material File' : 'Add Resource Material File'}
                    </Typography>
                    {id && (
                        <Button
                            sx={{ backgroundColor: 'red' }}
                            onClick={() => handleDelete()}
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            startIcon={<Iconify icon="ic:baseline-delete" />}
                        >
                            {isDeleteLoading ? (
                                <CircularProgress size={20} sx={{ color: '#ffffff' }} />
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    )}
                </Stack>
                <Card>
                    {isGetLoading ? (
                        <Box
                            py={5}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Scrollbar>
                            <Box p={3}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            label="Folder Name"
                                            value={folder_name}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="File Name"
                                            value={fileName}
                                            onChange={(e) => setFileName(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                                            <Select
                                                labelId="qualification-label"
                                                id="qualification"
                                                multiple
                                                value={selectedQualifications}
                                                onChange={handleChangeQualifications}
                                                input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {qualifications.map((name) => (
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.sub_title}
                                                        style={getStyles(name, selectedQualifications, theme)}
                                                    >
                                                        {name.sub_title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="role-label">Select Roles</InputLabel>
                                            <Select
                                                labelId="role-label"
                                                id="roles"
                                                multiple
                                                value={selectedRoles}
                                                onChange={handleChangeRoles}
                                                input={<OutlinedInput id="select-multiple-chip" label="Select Roles" />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {roles.map((name) => (
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.role_name}
                                                        style={getStyles(name, selectedRoles, theme)}
                                                    >
                                                        {name.role_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="batch-label">Select Cohort/Batch</InputLabel>
                                            <Select
                                                labelId="batch-label"
                                                id="batch"
                                                multiple
                                                disabled={!isCohortEnabled}
                                                value={selectedBatch}
                                                onChange={handleChangeBatches}
                                                input={<OutlinedInput id="select-multiple-chip" label="Select Cohort/Batch" />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((batch) => (
                                                            <Chip label={batch} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {batchs.map((name) => (
                                                    <MenuItem
                                                        key={name.cohort_batch_no}
                                                        value={name.cohort_batch_no}
                                                        style={getStyles(name, selectedBatch, theme)}
                                                    >
                                                        {name.cohort_batch_no}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} display="flex" flexDirection="column" alignItems="flex-start">
                                        <TextField
                                            fullWidth
                                            label="Paste the URL here"
                                            value={url}
                                            onChange={handleUrlChange}
                                            disabled={Boolean(files) || filesType === 'file'} // Disable if file is selected or fileType is 'file'
                                        />
                                        <Box mt={3} display="flex" alignItems="center" width="100%" flexWrap="wrap">
                                            {!url && ( // Hide if URL is entered
                                                <Button variant="contained" component="label" size="small">
                                                    Upload File
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={handleFileUpload}
                                                    // accept="image/*" // Accept only images
                                                    />
                                                </Button>
                                            )}
                                            {files && (
                                                <Box ml={2} display="flex" alignItems="center" flexGrow={1}>
                                                    <Typography variant="body2" noWrap>
                                                        {files.name}
                                                    </Typography>
                                                    <IconButton
                                                        onClick={handleFileRemove} // Handle file removal
                                                        size="small"
                                                    >
                                                        <Close sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Box>
                                            )}
                                            {url && filesType === 'file' && (
                                                <Box ml={2} display="flex" alignItems="center" flexGrow={1}>
                                                    <Typography variant="body2" noWrap>
                                                        {filesType === 'file' ?
                                                            <RouterLink className="routerLink"
                                                                // target="_blank"
                                                                // href={url}
                                                                onClick={() => {
                                                                    setIframeModal(true)
                                                                    setIframeURL(url)
                                                                }}

                                                                variant="subtitle1">
                                                                {url}
                                                            </RouterLink>
                                                            :
                                                            { url }
                                                        }
                                                    </Typography>
                                                    <IconButton
                                                        onClick={handleUrlRemove} // Handle URL removal
                                                        size="small"
                                                    >
                                                        <Close sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box textAlign="right">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                {isSaveLoading ? (
                                                    <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                                                ) : id ? 'Update' : 'Submit'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Scrollbar>
                    )}
                </Card>

                <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </Page>
    );
}
