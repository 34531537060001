import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  TextField,
  Grid,
  CardContent,
  Divider,
  Alert,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
  Snackbar
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { ChangeRoleModal, ResetUserPassword, UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import EditUserModal from 'src/sections/@dashboard/user/EditUserModal';

export default function Faqs() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'question', label: 'Topics', alignRight: false },
    { id: 'answer', label: 'Answers', alignRight: false },
    { id: 'categories', label: 'Categories', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];

  function checkRender() {
    let check = 'faqs';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [open, setOpen] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [openMesg, setOpenMesg] = useState(false);
  const [openQues, setOpenQues] = useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [user, setuser] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [alertSuccess, setalertSuccess] = useState({ visible: false, message: '' });
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState('');
  const [mode, setmode] = useState('error');

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (user) {
      const selected = categories?.filter((e) => e.id === user?.faq_category?.id);
      setSelectedCategory(selected[0]);
      setQuestion(user.topic);
      setAnswer(user.answer);
    }
  }, [user]);

  function getCategories() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    let url = api.get_faq_category;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);

        setCategories(result?.data);
      })
      .catch((error) => {
        console.log('error', error);
        setCategories([]);
      })
      .finally(() => {
        setloading(false);
      });
  }

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getFaqs(value);
  };

  useEffect(() => {
    getFaqs();
  }, []);

  function getFaqs(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    // Build the query parameters
    const params = new URLSearchParams();
    search.length > 0 && params.append('search', search);
    page && params.append('page', page);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_faqs}?${params.toString()}`, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          throw new Error('Failed to fetch FAQs');
        }
      })
      .then((result) => {
        list.length = 0;
        let decryptedData = result.data;
        setlist(decryptedData);
        result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseMesgModal = () => {
    setOpenMesg(false);
  };
  const descriptionElementRef = React.useRef(null);
  function openMesgModal(user) {
    setuser(user);
    setOpenMesg(true);
  }

  const handleCloseMesgModal2 = () => {
    setOpenQues(false);
  };
  const descriptionElementRef2 = React.useRef(null);
  function openMesgModal2(user) {
    setuser(user);
    setOpenQues(true);
  }

  function openModal(user) {
    setuser(user);
    handleOpen();
  }

  function openUserModal(user) {
    setuser(user);
    setOpenUser(true);
  }

  const handleCloseUserModal = () => setOpenUser(false);

  const handleRequestSort = (event, property) => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isUserNotFound = list.length === 0;

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main'
  };

  const refreshAdmin = () => {
    setOpen(false);
    setOpenUser(false);
    getFaqs(page);
  };

  function addFaqs(type) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    type === 'edit' && formdata.append('id', user?.id);
    formdata.append(
      'faq_category_id',
      selectedCategory ? selectedCategory?.id : user?.faq_category?.id
    );
    formdata.append('topic', question ? question : user?.topic);
    formdata.append('answer', answer ? answer : user?.answer);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(type === 'edit' ? api.edit_faq : api.add_faq, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          return response.json().then((result) => {
            setmessage(result.message);
            setmode('error');
            setshowSnackbar(true);
          });
        }
      })
      .then((response) => {
        refreshAdmin();
        setmode('success');
        setmessage(response.message);
        setshowSnackbar(true);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  const [selectedId, setselectedId] = useState(null);

  function deleteCategory(id) {
    setselectedId(id);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.delete_faq + '?id=' + id, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          return response.json().then((result) => {
            setmessage(result.message);
            setmode('error');
            setshowSnackbar(true);
          });
        }
      })
      .then((result) => {
        setmode('success');
        setmessage(result.message);
        setshowSnackbar(true);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setselectedId(null);
      });
  }

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getFaqs();
    }
  }, [search]);

  const handleValidateAdd = () => {
    if (!selectedCategory) {
      setalert({ visible: true, message: 'Category is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Category is required' });
      }, 2000);
    } else if (!question) {
      setalert({ visible: true, message: 'Question is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Question is required' });
      }, 2000);
    } else if (!answer) {
      setalert({ visible: true, message: 'Answer is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Answer is required' });
      }, 2000);
    } else {
      addFaqs();
    }
  };

  const handleValidateEdit = () => {
    if (!selectedCategory) {
      setalert({ visible: true, message: 'Category is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Category is required' });
      }, 2000);
    } else if (!question) {
      setalert({ visible: true, message: 'Question is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Question is required' });
      }, 2000);
    } else if (!answer) {
      setalert({ visible: true, message: 'Answer is required' });
      setTimeout(() => {
        setalert({ visible: false, message: 'Answer is required' });
      }, 2000);
    } else {
      addFaqs('edit');
    }
  };

  return (
    <Page title={`FAQs | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* SNACKBAR FOR DELETE */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setshowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setshowSnackbar(false);
            }}
            severity={mode}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        {/* Add FAQS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openUser}
          onClose={handleCloseUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Add FAQs
              </Typography>

              <CardContent>
                <Grid container spacing={2} className="top_column">
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      multiline
                      style={{
                        width: '100%',
                        maxWidth: '100%'
                      }}
                      select
                      value={selectedCategory}
                      label={'Categories'}
                      onChange={handleChange}
                      className="width_full_status"
                    >
                      {categories.map((option) => (
                        <MenuItem item lg={12} sm={12} md={12} key={option.id} value={option}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      multiline
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Question'}
                      type="text"
                      value={question}
                      onChange={(val) => {
                        setQuestion(val.target.value);
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      multiline
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Answer'}
                      type="text"
                      value={answer}
                      onChange={(val) => {
                        setAnswer(val.target.value);
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {/* <Divider /> */}
              {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <LoadingButton
                  disabled={false}
                  loading={loading}
                  sx={{
                    marginRight: 1
                  }}
                  onClick={() => {
                    handleValidateAdd();
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t('EditProfile.buttons.1')}
                </LoadingButton>
                <LoadingButton
                  sx={{
                    marginRight: 1
                  }}
                  onClick={handleCloseUserModal}
                  variant="outlined"
                >
                  {t('EditProfile.buttons.2')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Edit FAQS  */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <Box p={3} flexDirection={'column'} display={'flex'} flex={1}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Edit FAQs
              </Typography>

              <CardContent>
                <Grid container spacing={2} className="top_column">
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      style={{
                        width: '100%'
                      }}
                      select
                      value={selectedCategory}
                      label={'Categories'}
                      onChange={handleChange}
                      className="width_full_status"
                    >
                      {categories.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      multiline
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Question'}
                      type="text"
                      value={question}
                      onChange={(val) => {
                        setQuestion(val.target.value);
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} md={12}>
                    <TextField
                      multiline
                      id="outlined-error-helper-text"
                      fullWidth
                      label={'Answer'}
                      type="text"
                      value={answer}
                      onChange={(val) => {
                        setAnswer(val.target.value);
                      }}
                      variant="outlined"
                      display="inline"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {/* <Divider /> */}
              {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <LoadingButton
                  disabled={false}
                  loading={loading}
                  sx={{
                    marginRight: 1
                  }}
                  onClick={() => {
                    handleValidateEdit();
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t('EditProfile.buttons.1')}
                </LoadingButton>
                <LoadingButton
                  sx={{
                    marginRight: 1
                  }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  {t('EditProfile.buttons.2')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Dialog
          open={openQues}
          onClose={handleCloseMesgModal2}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box sx={{ minWidth: 600, maxHeight: 500 }}>
            <DialogTitle id="scroll-dialog-title">Question</DialogTitle>
            <DialogContent dividers={'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef2}
                tabIndex={-1}
              >
                {user?.topic}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMesgModal2}>Cancel</Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Dialog
          open={openMesg}
          onClose={handleCloseMesgModal}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box sx={{ minWidth: 600, maxHeight: 500 }}>
            <DialogTitle id="scroll-dialog-title">Answer</DialogTitle>
            <DialogContent dividers={'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {user?.answer}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMesgModal}>Cancel</Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            FAQs
          </Typography>
          {permission.includes('add') && (
            <Button
              onClick={() => {
                openUserModal(true);
                setQuestion('');
                setAnswer('');
                setSelectedCategory();
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              ADD
            </Button>
          )}
        </Stack>

        {alertSuccess && alertSuccess.visible && (
          <Alert style={{ marginBottom: 20 }} severity="success">
            {alertSuccess.message}
          </Alert>
        )}

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'100%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                setPage(1);
                getFaqs(1);
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getFaqs(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {list.map((row) => {
                    const { id, answer, topic, faq_category } = row;

                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        style={{}}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell
                          onClick={() => openMesgModal2(row)}
                          align="left"
                          style={{ maxWidth: 200, cursor: 'pointer' }}
                        >
                          <Typography noWrap fontSize={14}>
                            {topic}
                          </Typography>
                        </TableCell>
                        <TableCell
                          onClick={() => openMesgModal(row)}
                          align="left"
                          style={{ maxWidth: 200, cursor: 'pointer' }}
                        >
                          <Typography noWrap fontSize={14}>
                            {answer}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography noWrap fontSize={14}>
                            {faq_category?.name || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          {permission.includes('edit') && (
                            <Button
                              size="small"
                              onClick={() => openModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 85,
                                alignSelf: 'center'
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={<Iconify color={pallete.primary.main} icon="bx:edit" />}
                            >
                              Edit
                            </Button>
                          )}
                          {permission.includes('delete') && (
                            <LoadingButton
                              size="small"
                              onClick={() => deleteCategory(id)}
                              style={{
                                marginTop: 10,
                                justifyContent: 'flex-start',
                                alignSelf: 'center'
                              }}
                              variant="outlined"
                              loading={selectedId == id}
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="material-symbols:delete"
                                />
                              }
                            >
                              Delete
                            </LoadingButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
