import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  Snackbar,
  Alert,
  IconButton
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../sections/@dashboard/user';
import { api } from '../../Api';
import { useTranslation } from 'react-i18next';

export default function Qualifications_Subjects() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const TABLE_HEAD = [
    // { id: 'id', label: '#Sr', alignRight: false },
    { id: 'qualification', label: 'Qualification', alignRight: false },
    { id: 'registered_learners_count', label: 'Registered Learners', alignRight: false }
  ];

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getQualiciationSubjectsById(value);
  };

  function getQualiciationSubjectsById(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const searchParam = search.length > 0 ? search : null;
    const pageParam = page ? page : null;

    let url = api.get_assigned_qualifications;
    const params = new URLSearchParams();
    if (id) params.append('user_id', id);
    if (searchParam) params.append('search', searchParam);
    if (pageParam) params.append('page', pageParam);
    params.append('company_admin_id', user?.company_admin_id);

    if (params.toString()) url += `?${params.toString()}`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        list.length = 0;
        let decryptedData = result.data;
        setlist(decryptedData);
        result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getQualiciationSubjectsById();
  }, []);

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getQualiciationSubjectsById(page);
    }
  }, [search]);

  const isDataNotFound = list.length === 0;

  return (
    <Page title={`Colors | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" mb={1}>
          <IconButton style={{ marginRight: 12, marginBottom: 5 }} onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            Qualifications/Subjects
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getQualiciationSubjectsById();
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getQualiciationSubjectsById(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row) => {
                    const { id, sub_title, registered_leaners_count } = row;

                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* ID */}
                        {/* <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle2" flexWrap={'wrap'}>
                            {id || 'N/A'}
                          </Typography>
                        </TableCell> */}
                        {/* QUALIFICATIONS */}
                        <TableCell scope="row" padding="normal">
                          <RouterLink
                            className="routerLink"
                            to={`/dashboard/qualifications/addQualifications/form/${id}`}
                          >
                            <Typography variant="subtitle2" flexWrap={'wrap'}>
                              {sub_title || 'N/A'}
                            </Typography>
                          </RouterLink>
                        </TableCell>
                        {/* ACTION */}
                        <TableCell align="left">
                          {registered_leaners_count > 0 ? (
                            <RouterLink
                              className="routerLink"
                              to={`/dashboard/users/registered_learners/${id}`}
                            >
                              <Typography>{registered_leaners_count}</Typography>
                            </RouterLink>
                          ) : (
                            registered_leaners_count
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isDataNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                          <SearchNotFound message={`No Qualification Found`} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
