import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';
import CommunicationModal from 'src/components/Communication/CommunicationModal';

export default function Communications() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'to', label: 'To', alignRight: false },
    { id: 'from', label: 'From', alignRight: false },
    { id: 'message', label: 'Message', alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];
  const userData = JSON.parse(localStorage.getItem('user'));

  function checkRender() {
    let check = 'communication';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getCommunications(value);
  };

  function getCommunications(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const searchParam = search.length > 0 ? search : null;
    const pageParam = page ? page : null;

    let url = api.get_communications;
    const params = new URLSearchParams();
    if (searchParam) params.append('search', searchParam);
    if (pageParam) params.append('page', pageParam);
    params.append('company_admin_id', user?.company_admin_id);
    if (params.toString()) url += `?${params.toString()}`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        list.length = 0;
        let decryptedData = result.data;
        setlist(decryptedData);
        result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const handleMarkSeen = (id) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('id', id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.mark_seen, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201) {
          getCommunications(page)
        }
        //  else {
        // }
      })
      .catch((error) => {
        console.log("🚀 ~ handleMarkSeen ~ error:", error)
      });
  };

  useEffect(() => {
    getCommunications();
  }, []);

  const [openCommunication, setopenCommunication] = React.useState(false);

  function openCommunicationModal() {
    setopenCommunication(true);
  }

  const handleCloseColorModal = () => setopenCommunication(false);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const refresh = () => {
    setopenCommunication(false);
    getCommunications(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getCommunications(page);
    }
  }, [search]);

  const isDataNotFound = list.length === 0;

  const [open, setOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (row) => {
    if (userData.id !== row.created_by.id) {
      handleMarkSeen(row?.id)
    }
    setSelectedComment(row?.message);
    setOpen(true);
  };

  return (
    <Page title={`Communications | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* ADD COMMUNICATION */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openCommunication}
          onClose={handleCloseColorModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '60%',
              maxHeight: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              overflow: 'auto',
              p: 1,
              border: '2px solid',
              borderColor: 'primary.main',
              flex: 1,
              style
            }}
            className="admin_modal_pa"
          >
            <CommunicationModal
              refreshAdmin={refresh}
              handleClose={() => {
                setopenCommunication(false);
              }}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Communications
          </Typography>
          {permission.includes('add') && (
            <Button
              onClick={() => {
                openCommunicationModal();
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Send Message
            </Button>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getCommunications();
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getCommunications(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row) => {
                    const { id, topic, created_at, to, is_seen, created_by } = row;
                    return (
                      <TableRow
                        hover
                        onClick={() => { }}
                        style={{
                          backgroundColor: is_seen == '0' && userData.id !== created_by.id ? "rgba(176, 174, 174,0.7)" : 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* DATE */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant={is_seen == '0' && userData.id !== created_by.id ? "subtitle2" : "body2"} flexWrap={'wrap'}>
                            {created_at ? moment(created_at).format('DD/MM/YYYY') : 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* TOPIC */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant={is_seen == '0' && userData.id !== created_by.id ? "subtitle2" : "body2"} flexWrap={'wrap'}>
                            {topic || 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* TO */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant={is_seen == '0' && userData.id !== created_by.id ? "subtitle2" : "body2"} flexWrap={'wrap'}>
                            {to?.full_name || 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* FROM */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant={is_seen == '0' && userData.id !== created_by.id ? "subtitle2" : "body2"} flexWrap={'wrap'}>
                            {created_by?.full_name || 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* ACTION */}
                        <TableCell align="left">
                          <Button
                            size="small"
                            onClick={() => handleClickOpen(row)}
                            style={{
                              justifyContent: 'flex-start',
                              alignSelf: 'center',
                              marginRight: 5
                            }}
                            variant="outlined"
                            component={RouterLink}
                            to="#"
                            startIcon={
                              <Iconify color={pallete.primary.main} icon="carbon:view-filled" />
                            }
                          >
                            Click to see
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isDataNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound message={"No Communication Found"} searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>

        {/* MESSAGE DIALOGUE */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Message</DialogTitle>
          <Divider />
          <DialogContent sx={{ minWidth: 500 }}>
            <DialogContentText>
              <Typography component="div" style={{ whiteSpace: 'pre-line' }}>
                {selectedComment}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
