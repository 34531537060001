import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  CardContent,
  Alert,
  MenuItem,
  IconButton,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Snackbar,
  Paper,
  Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import {
  LearnersForm,
  UserListHead,
  AssessorsForm,
  AdminForm,
  EQAsForm,
  IQAsForm
} from '../../sections/@dashboard/user';

import { api } from '../../Api';
import { useTranslation } from 'react-i18next';
import DropDown from 'src/components/DropDown';
import RoleChangeDropdown from 'src/layouts/dashboard/RoleChangeDropDown';
import EditBulkLearnersForm from 'src/components/Users/EditBulkLearnerForm';
import { Close as CloseIcon, UploadFile as UploadFileIcon, Delete as DeleteIcon, CloudUpload as CloudUploadIcon, } from '@mui/icons-material';
import downloadFile from '../../../src/BulkRegistrationSample.xlsx';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function User() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const TABLE_HEAD = [
    { id: 'name', label: t('Users.table.0'), alignRight: false },
    { id: 'email', label: t('Users.table.1'), alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'status', label: t('Users.table.4'), alignRight: false }
  ];

  const LEARNER_TABLE_HEAD = [
    { id: 'full_name', label: 'Name', alignRight: false },
    { id: 'qualification', label: 'Qualification', alignRight: false },
    { id: 'reference', label: 'Reference', alignRight: false },
    { id: 'assessor', label: 'Assessor', alignRight: false },
    { id: 'iqa', label: 'IQA', alignRight: false },
    { id: 'completion', label: 'Completion', alignRight: false },
    { id: 'cohort', label: 'Cohort/Batch', alignRight: false },
    { id: 'select', label: 'Select', alignRight: false }
  ];

  const ASSESSORS_TABLE_HEAD = [
    { id: 'full_name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'qualifications', label: 'Qualifications/Subjects', alignCenter: true },
    { id: 'assignedLearners', label: 'Assigned Learners', alignCenter: true }
  ];

  const ADMINS_TABLE_HEAD = [
    { id: 'full_name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'contact', label: 'Contact', alignRight: false },
    // { id: 'qualifications', label: 'Qualifications/Subjects', alignRight: false },
    // { id: 'references', label: 'References', alignRight: false }
  ];

  const EQAS_TABLE_HEAD = [
    { id: 'full_name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'qualifications', label: 'Qualifications/Subjects', alignRight: false },
    // { id: 'references', label: 'References', alignRight: false },
    { id: 'learners', label: 'Learners', alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'edit', 'change status', 'change password'];
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const [listRole, setlistRole] = useState({});

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [draweLoading, setDraweLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [alert, setalert] = useState({ visible: false, message: '' });

  const [userName, setUserName] = useState([]);
  const [qualifications, setQualification] = useState([]);
  const [reference, setReference] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [IQAs, setIQAs] = useState([]);
  const [batchs, setBatch] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [selectedReference, setSelectedReference] = useState([]);
  const [selectedAssessors, setSelectedAssessors] = useState([]);
  const [selectedIQAs, setSelectedIQAs] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [openUser, setOpenUser] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [roles, setRoles] = useState([]);
  const [add, setadd] = useState(false);
  const [editable, seteditable] = useState(false);

  const [selected, setSelected] = useState([]);

  const [errorUserDropDown, setErrorUserDropDown] = useState(false);
  const [loadingUserDropDown, setLoadingUserDropDown] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorUrl, setErrorUrl] = useState('');

  const handleCloseBulkModal = () => setOpenUser(false);

  const isUserNotFound = list.length === 0;

  const [formDataLearners, setFormDataLearners] = useState({
    id: null,
    referenceNumber: '',
    firstName: '',
    middleName: '',
    surname: '',
    learnerNumber: '',
    qualification: '',
    assessor: '',
    iqa: '',
    registrationDate: '',
    cohortBatch: '',
    email: '',
    contact: '',
    dob: '',
    address: '',
    disability: false,
    user_qualification_id: "0"
  });

  const [formDataAssessors, setFormDataAssessors] = useState({
    id: null,
    firstName: '',
    middleName: '',
    surname: '',
    qualification: [],
    email: '',
    contact: '',
  });

  const [formDataIQAs, setFormDataIQAs] = useState({
    id: null,
    firstName: '',
    middleName: '',
    surname: '',
    qualification: [],
    email: '',
    contact: ''
  });

  const [formDataAdmin, setFormDataAdmin] = useState({
    id: null,
    firstName: '',
    surname: '',
    // qualification: [],
    // reference: [],
    email: '',
    contact: '',
    // viewOnly: ''
  });

  const [formDataEQAs, setFormDataEQAs] = useState({
    id: null,
    name: '',
    qualification: [],
    reference: [],
    learner: [],
    email: '',
    accountExpiryDate: '',
    code: ''
  });

  useEffect(() => {
    if (listRole.id) {
      getUsers();
    }
    getUserRoles();
    //  getUserStatus();
  }, []);

  useEffect(() => {
    const storedAssessor = localStorage.getItem("roleSelected");
    if (storedAssessor) {
      setlistRole(JSON.parse(storedAssessor))
      setSelectedRole(JSON.parse(storedAssessor));
    } else {
      setlistRole({ id: 3, role_name: 'Learners' })
    }
  }, []); // Empty array ensures this effect runs only once on mount.

  const handleChangeRole = (e) => {
    const selectedValue = e.target.value;
    setSelectedRole(selectedValue);
    // localStorage.setItem("roleSelected", JSON.stringify(selectedValue));
    RefreshForm();
    setalert({ visible: false, message: '' });
  };

  function checkRender() {
    let check = 'users';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getUsers(page);
    }
  }, [search]);

  useEffect(() => {
    if (listRole.role_name) {
      list.length = 0;
      setPage(1);
      getUsers(1);
    }
  }, [listRole]);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getUsers(value);
  };

  const ClearFilter = () => {
    setSelectedName([])
    setSelectedEmails([])
    setSelectedQualifications([])
    setSelectedReference([])
    setSelectedAssessors([])
    setSelectedIQAs([])
    setSelectedBatch([])

    if (
      selectedName.length == 0 &&
      selectedEmails.length == 0 &&
      selectedQualifications.length == 0 &&
      selectedReference.length == 0 &&
      selectedAssessors.length == 0 &&
      selectedIQAs.length == 0 &&
      selectedBatch.length == 0
    ) {
      setOpenDrawer(false);
      refreshAdmin()
    }
  }

  function getUsers(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const nameCheck = userName
      .filter((a) => selectedName.includes(a.full_name))
      .map((a) => a.id);
    const QualificationCheck = qualifications
      .filter((a) => selectedQualifications.includes(a.sub_title))
      .map((a) => a.id);
    const AssessorCheck = assessors
      .filter((a) => selectedAssessors.includes(a.full_name))
      .map((a) => a.id);
    const IQAsCheck = IQAs
      .filter((a) => selectedIQAs.includes(a.full_name))
      .map((a) => a.id);
    const EmailCheck = userName
      .filter((a) => selectedEmails.includes(a.email))
      .map((a) => a.id);
    const BatchCheck = qualifications
      .filter((a) => selectedQualifications.includes(a.cohort_batch_no))
      .map((a) => a.cohort_batch_no);

    // Construct the query string
    const queryParams = new URLSearchParams();
    search.length > 0 && queryParams.append('search', search);
    page && queryParams.append('page', page);
    listRole.role_name != 'All' && queryParams.append('role_id', listRole?.id);
    queryParams.append('company_admin_id', userData?.company_admin_id);
    QualificationCheck.length !== 0 && queryParams.append('qualification_ids', QualificationCheck.join(','));
    AssessorCheck.length !== 0 && queryParams.append('assessor_ids', AssessorCheck.join(','));
    IQAsCheck.length !== 0 && queryParams.append('iqa_ids', IQAsCheck.join(','));
    selectedReference.length !== 0 && queryParams.append('ref_numbers', selectedReference.join(','));
    BatchCheck.length !== 0 && queryParams.append('batch_numbers', BatchCheck.join(','));
    nameCheck.length !== 0 && queryParams.append('name_ids', nameCheck.join(','));
    EmailCheck.length !== 0 && queryParams.append('email_ids', EmailCheck.join(','));

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.getUsers_V1}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setlist(result.data);
          getUserRoles()
          result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
          setloading(false);
        } else {
          setlist([]);
          setpagesCount(0);
          if (listRole.id) {
            setloading(false)
          }
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsearchLoading(false);
      });
  }

  function getUsersDropdown() {
    setLoadingUserDropDown(true)

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    listRole.role_name != 'All' && queryParams.append('role_id', listRole?.id);
    queryParams.append('company_admin_id', userData?.company_admin_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // fetch(`${api.getUsers}?${queryParams.toString()}`, requestOptions)
    fetch(`${api.getUsers_V1}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setUserName(result.data);
          setLoadingUserDropDown(false)
        } else {
          setLoadingUserDropDown(false)
          setErrorUserDropDown(true)
          setUserName([]);
        }
      })
      .catch((error) => {
        console.log('error', error)
        setLoadingUserDropDown(false)
        setErrorUserDropDown(true)
      })
      .finally(() => {

      });
  }

  function getUserRoles() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.get_user_roles + '?company_admin_id=' + user?.company_admin_id, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setRoles(result.data);
        } else {
          setRoles([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
      });
  }

  function openBulkEditModal() {
    setOpenUser(true);
  }

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'scroll'
  };

  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    outline: 0,
  };

  const dropzoneStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    cursor: 'pointer',
    textAlign: 'center',
  };

  const boxStyle = {
    maxWidth: '80%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main',
    borderRadius: 1
  };

  const refreshAdmin = () => {
    setOpenAddModal(false);
    setOpenUser(false);
    list.length = 0;
    getUsers(page);
  };

  // OPEN ADD MODAL FUNCTION
  function handleOpenAddForm() {
    setSelectedRole(null);
    setOpenAddModal(true);
    seteditable(true);
    setadd(true);
  }

  // CLOSE ADD MODAL FUNCTION
  function handleCloseAddForm() {
    setSelectedRole(null);
    setOpenAddModal(false);
    setadd(false);
    seteditable(false);
    setalert({ visible: false, message: '' });
  }

  // HANDLE FORM SUBMISSSIONS
  const handleSubmit = (values) => {
    console.log('values?.reference', values?.reference)
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    if (selectedRole?.role_name === 'Learners') {
      values?.id && formdata.append('id', values?.id);
      formdata.append('role_id', selectedRole?.id);
      values?.referenceNumber && formdata.append('ref_number', values?.referenceNumber);
      values?.firstName && formdata.append('first_name', values?.firstName);
      values?.middleName && formdata.append('middle_name', values?.middleName);
      values?.surname && formdata.append('sur_name', values?.surname);
      values?.learnerNumber && formdata.append('learner_number', values?.learnerNumber);
      values?.qualification &&
        formdata.append('qualifications', JSON.stringify([values?.qualification]));
      values?.assessor && formdata.append('assessors', JSON.stringify([values?.assessor]));
      values?.iqa && formdata.append('iqas', JSON.stringify([values?.iqa]));
      values?.registrationDate && formdata.append('date_of_registration', values?.registrationDate);
      values?.cohortBatch && formdata.append('cohort_batch_no', values?.cohortBatch);
      values?.email && formdata.append('email', values?.email);
      values?.contact && formdata.append('contact', values?.contact);
      values?.dob && formdata.append('date_of_birth', values?.dob);
      values?.address && formdata.append('location', values?.address);
      formdata.append('country', 'USA');
      formdata.append('city', 'N/A');
      formdata.append('lat', 'N/A');
      formdata.append('lon', 'N/A');
      formdata.append('disability', values.disability == true ? 'yes' : 'no');
      formdata.append('user_qualification_id', values.user_qualification_id);
    }
    if (selectedRole?.role_name === 'Assessors') {
      values?.id && formdata.append('id', values?.id);
      formdata.append('role_id', selectedRole?.id);
      values?.firstName && formdata.append('first_name', values?.firstName);
      values?.middleName && formdata.append('middle_name', values?.middleName);
      values?.surname && formdata.append('sur_name', values?.surname);
      values?.qualification &&
        formdata.append('qualifications', JSON.stringify(values?.qualification));
      values?.email && formdata.append('email', values?.email);
      values?.contact && formdata.append('contact', values?.contact);
    }
    if (selectedRole?.role_name === 'IQAs') {
      values?.id && formdata.append('id', values?.id);
      formdata.append('role_id', selectedRole?.id);
      values?.firstName && formdata.append('first_name', values?.firstName);
      values?.middleName && formdata.append('middle_name', values?.middleName);
      values?.surname && formdata.append('sur_name', values?.surname);
      values?.qualification &&
        formdata.append('qualifications', JSON.stringify(values?.qualification));
      values?.email && formdata.append('email', values?.email);
      values?.contact && formdata.append('contact', values?.contact);
    }
    if (selectedRole?.role_name === 'Admin') {
      values?.id && formdata.append('id', values?.id);
      formdata.append('role_id', selectedRole?.id);
      values?.firstName && formdata.append('first_name', values?.firstName);
      values?.middleName && formdata.append('middle_name', values?.middleName);
      values?.surname && formdata.append('sur_name', values?.surname);
      // values?.qualification &&
      //   formdata.append('qualifications', JSON.stringify(values?.qualification));
      // values?.reference && formdata.append('references', JSON.stringify(values?.reference));
      values?.email && formdata.append('email', values?.email);
      values?.contact && formdata.append('contact', values?.contact);
      // values?.viewOnly?.id && formdata.append('view_only_id', values?.viewOnly?.id);
    }
    if (selectedRole?.role_name === 'EQAs') {
      values?.id && formdata.append('id', values?.id);
      formdata.append('role_id', selectedRole?.id);
      values?.name && formdata.append('sur_name', values?.name);
      values?.qualification &&
        formdata.append('qualifications', JSON.stringify(values?.qualification));
      // values?.reference && formdata.append('references', JSON.stringify(values?.reference));
      values?.learner && formdata.append('learners', JSON.stringify(values?.learner));
      values?.email && formdata.append('email', values?.email);
      values?.accountExpiryDate && formdata.append('expiry_date', values?.accountExpiryDate);
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setformLoading(true);
    fetch(api.create_user, requestOptions)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return response.text();
        } else {
          return response.json().then((result) => {
            setalert({ visible: true, message: result.error });
          });
        }
      })
      .then((result) => {
        const response = JSON.parse(result);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setformLoading(false));
  };

  function RefreshForm() {
    setFormDataLearners({
      id: null,
      referenceNumber: '',
      firstName: '',
      surname: '',
      learnerNumber: '',
      qualification: '',
      assessor: '',
      iqa: '',
      registrationDate: '',
      cohortBatch: '',
      email: '',
      contact: '',
      dob: '',
      address: '',
      user_qualification_id: '0'
    });
    setFormDataAssessors({
      id: null,
      firstName: '',
      surname: '',
      qualification: [],
      email: '',
      contact: '',
    });
    setFormDataIQAs({
      id: null,
      firstName: '',
      surname: '',
      qualification: [],
      email: '',
      contact: ''
    });
    setFormDataAdmin({
      id: null,
      firstName: '',
      surname: '',
      // qualification: [],
      // reference: [],
      email: '',
      contact: '',
      // viewOnly: ''
    });
    setFormDataEQAs({
      id: null,
      name: '',
      qualification: [],
      reference: [],
      learner: [],
      email: '',
      accountExpiryDate: null
    });
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.user_qualifications?.id);
      setSelected(newSelecteds);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      // Remove the ID from the array
      setSelected(selected.filter((item) => item !== id));
    } else {
      // Add the ID to the array
      setSelected([...selected, id]);
    }
  };

  const Table_Row = ({ row }) => {
    const { id, full_name, email, seen, user_qualifications, status, contact } = row;
    if (listRole.role_name == 'Learners') {
      return (
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          sx={{ backgroundColor: user_qualifications?.status?.toLowerCase() == "inactive" || user_qualifications?.status?.toLowerCase() == "deactive" ? "rgba(252, 17, 0,0.2)" : "transparent" }}
        >
          <TableCell padding="normal" style={{ width: 150 }}>
            <RouterLink
              className={user_qualifications?.disability == 1 ? "routerLinkDisablity" : "routerLink"}
              to={`/dashboard/users/learnerDetails/${row?.id}/${user_qualifications?.qualifications_with_trash_id}`}
            >
              <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                {full_name === 'undefined' ? 'N/A' : full_name}
              </Typography>
            </RouterLink>
          </TableCell>

          <TableCell align="left" style={{ width: 150 }}>
            <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
              {user_qualifications?.qualifications_with_trash_sub_title || 'N/A'}
            </Typography>
          </TableCell>

          <TableCell align="left" style={{ width: 50 }}>
            <Typography flexWrap={'wrap'}>{user_qualifications?.ref_number || 'N/A'}</Typography>
          </TableCell>

          <TableCell align="left" style={{ maxWidth: 80 }}>
            <Typography flexWrap={'wrap'}>
              {user_qualifications?.assessors_with_trash_full_name || 'N/A'}
            </Typography>
          </TableCell>

          <TableCell align="left" style={{ width: 80 }}>
            <Typography flexWrap={'wrap'} style={{ width: 80 }}>
              {user_qualifications?.iqa_with_trash_full_name || 'N/A'}
            </Typography>
          </TableCell>

          <TableCell align="left" style={{ width: 50 }}>
            <Typography flexWrap={'wrap'}>{user_qualifications?.completion || 'N/A'}</Typography>
          </TableCell>

          <TableCell align="left" style={{ width: 50 }}>
            <Typography flexWrap={'wrap'}>
              {user_qualifications?.cohort_batch_no || 'N/A'}
            </Typography>
          </TableCell>

          <TableCell align="left" style={{ maxWidth: 130 }}>
            <Checkbox
              checked={selected.includes(user_qualifications?.id)}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the row
                handleSelect(user_qualifications?.id);
              }}
            />
          </TableCell>
        </TableRow>
      );
    } else if (listRole.role_name === 'Assessors' || listRole?.role_name == 'IQAs') {
      return (
        <TableRow
          hover
          onClick={() => {
            //   OpenViewUser(row);
          }}
          style={{
            backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
            cursor: seen == '0' ? 'pointer' : 'default'
          }}
          key={id}
          tabIndex={-1}
          role="checkbox"
          sx={{ backgroundColor: user_qualifications?.status?.toLowerCase() == "inactive" ? "rgba(252, 17, 0,0.2)" : "transparent" }}
        >
          {/* NAME */}
          <TableCell padding="normal">
            <RouterLink
              className="routerLink"
              to={
                listRole.role_name == 'Assessors'
                  ? `/dashboard/users/AssessorDetails/${row?.id}`
                  : `/dashboard/users/IqaDetails/${row?.id}`
              }
            >
              <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                {full_name || 'N/A'}
              </Typography>
            </RouterLink>
          </TableCell>
          {/* EMAIL */}
          <TableCell padding="normal">
            <Typography style={{ width: 200 }} variant="subtitle3" flexWrap={'wrap'}>
              {email || 'N/A'}
            </Typography>
          </TableCell>
          {/* QUALIFICATIONS COUNT */}
          <TableCell align="center">
            {user_qualifications?.user_qualification_count ? (
              <RouterLink
                className="routerLink"
                to={`/dashboard/users/qualifications_subjects/${row?.id}`}
              >
                <Typography flexWrap={'wrap'}>
                  {user_qualifications?.user_qualification_count}
                </Typography>
              </RouterLink>
            ) : (
              <Typography noWrap>0</Typography>
            )}
          </TableCell>
          {/* ASSIGNED LEARNERS COUNT */}
          <TableCell align="center">
            {user_qualifications?.assigned_leaner_count ? (
              <RouterLink
                className="routerLink"
                to={`/dashboard/users/assigned_learners/${row?.id}/${listRole?.role_name === 'Assessors' ? 4 : 5
                  }`}
              >
                <Typography flexWrap={'wrap'}>
                  {user_qualifications?.assigned_leaner_count}
                </Typography>
              </RouterLink>
            ) : (
              <Typography flexWrap={'wrap'}>0</Typography>
            )}
          </TableCell>
        </TableRow>
      );
    } else if (listRole.role_name === 'Admin') {
      return (
        <TableRow
          hover
          onClick={() => {
            // OpenViewUser(row);
          }}
          style={{
            backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
            cursor: seen == '0' ? 'pointer' : 'default'
          }}
          key={id}
          tabIndex={-1}
          role="checkbox"
          sx={{ backgroundColor: user_qualifications?.status?.toLowerCase() == "inactive" ? "rgba(252, 17, 0,0.2)" : "transparent" }}
        >
          {/* NAME */}
          <TableCell padding="normal">
            <RouterLink className="routerLink" to={`/dashboard/users/adminDetails/${row?.id}/${user_qualifications?.qualifications_with_trash_id}`}>
              <Typography style={{ width: 200 }} variant="subtitle2" flexWrap={'wrap'}>
                {full_name === 'undefined' ? 'N/A' : full_name}
              </Typography>
            </RouterLink>
          </TableCell>

          {/* EMAIL */}
          <TableCell padding="normal">
            <Typography style={{ width: 200 }} variant="subtitle3" flexWrap={'wrap'}>
              {email || 'N/A'}
            </Typography>
          </TableCell>

          {/* CONTACT */}
          <TableCell padding="normal">
            <Typography style={{ width: 200 }} variant="subtitle3" flexWrap={'wrap'}>
              {contact || 'N/A'}
            </Typography>
          </TableCell>
          {/* QUALIFICATIONS COUNT */}
          {/* <TableCell align="left">
            <Typography flexWrap={'wrap'}>
              {user_qualifications[0]?.user_qualification_count}
            </Typography>
          </TableCell> */}
          {/* REFERENCES COUNT */}
          {/* <TableCell align="left">
            <Typography flexWrap={'wrap'}>{user_qualifications[0]?.references?.length}</Typography>
          </TableCell> */}
        </TableRow>
      );
    } else if (listRole.role_name === 'EQAs') {
      return (
        <TableRow
          hover
          style={{
            backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
            cursor: seen == '0' ? 'pointer' : 'default'
          }}
          key={id}
          tabIndex={-1}
          role="checkbox"
          sx={{ backgroundColor: user_qualifications?.status?.toLowerCase() == "inactive" ? "rgba(252, 17, 0,0.2)" : "transparent" }}
        >

          {/* NAME */}
          <TableCell padding="normal" style={{ width: 150 }}>
            <RouterLink className="routerLink" to={`/dashboard/users/eqaDetails/${row?.id}/${user_qualifications?.qualifications_with_trash_id}`}>
              <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                {full_name === 'undefined' ? 'N/A' : full_name}
              </Typography>
            </RouterLink>
          </TableCell>

          {/* <TableCell padding="normal">
            <Typography style={{ width: 200 }} variant="subtitle3" flexWrap={'wrap'}>
              {full_name || 'N/A'}
            </Typography>
          </TableCell> */}

          {/* EMAIL */}
          <TableCell padding="normal">
            <Typography style={{ width: 200 }} variant="subtitle3" flexWrap={'wrap'}>
              {email || 'N/A'}
            </Typography>
          </TableCell>

          {/* QUALIFICATIONS COUNT */}
          <TableCell align="left" flexWrap={'wrap'}>
            <Typography flexWrap={'wrap'}>
              {user_qualifications?.user_qualification_count}
            </Typography>
          </TableCell>

          {/* REFERENCES COUNT */}
          {/* <TableCell align="left">
            <Typography flexWrap={'wrap'}>{user_qualifications?.references}</Typography>
          </TableCell> */}

          {/* LEARNERS COUNT */}
          <TableCell align="left">
            <Typography flexWrap={'wrap'}>{user_qualifications?.learners}</Typography>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  };

  const getQualifications = () => {
    setLoadingUserDropDown(true)

    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    fetch(`${api.get_qualifications}?company_admin_id=${userData?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setQualification(result.data);
          setLoadingUserDropDown(false)
        } else {
          setLoadingUserDropDown(false)
          setErrorUserDropDown(true)
          setQualification([]);
        }
      })
      .catch((error) => {
        console.log('error', error)
        setErrorUserDropDown(true)
        setLoadingUserDropDown(false)
      });
  };

  const getReference = () => {
    setLoadingUserDropDown(true)

    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    fetch(`${api.get_references}?company_admin_id=${userData?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setReference(result.data);
          setLoadingUserDropDown(false)
        } else {
          setReference([]);
          setLoadingUserDropDown(false)
          setErrorUserDropDown(true)
        }
      })
      .catch((error) => {
        setLoadingUserDropDown(false)
        setErrorUserDropDown(true)
        console.log('error', error)
      });
  };

  const getAssessors = () => {

    setLoadingUserDropDown(true)

    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    fetch(`${api.get_assessors}?company_admin_id=${userData?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setAssessors(result.data);
          setLoadingUserDropDown(false)
        } else {
          setAssessors([]);
          setLoadingUserDropDown(false)
          setErrorUserDropDown(true)
        }
      })
      .catch((error) => {
        console.log('error', error)
        setLoadingUserDropDown(false)
        setErrorUserDropDown(true)
      });
  };

  const getIQAs = () => {

    setLoadingUserDropDown(true)

    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    fetch(`${api.get_iqas}?company_admin_id=${userData?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setIQAs(result.data);
          setLoadingUserDropDown(false)
        } else {
          setIQAs([]);
          setLoadingUserDropDown(false)
          setErrorUserDropDown(true)
        }
      })
      .catch((error) => {
        console.log('error', error);
        setLoadingUserDropDown(false);
        setErrorUserDropDown(true);
      });
  };

  const getBatch = () => {
    setLoadingUserDropDown(true)
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_batch_no}?company_admin_id=${userData?.company_admin_id}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setBatch(result.data);
          setLoadingUserDropDown(false);
        } else {
          setBatch([]);
          setLoadingUserDropDown(false);
          setErrorUserDropDown(true);
        }
      })
      .catch((error) => {
        console.log('error', error);
        setLoadingUserDropDown(false);
        setErrorUserDropDown(true);
      });
  };

  const handleChangeUserName = (event) => {
    const value = event.target.value;
    setSelectedName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeEmails = (event) => {
    const value = event.target.value;
    setSelectedEmails(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeQualifications = (event) => {
    const value = event.target.value;
    setSelectedQualifications(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeReference = (event) => {
    const value = event.target.value;
    setSelectedReference(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeAssessors = (event) => {
    const value = event.target.value;
    setSelectedAssessors(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeIQAs = (event) => {
    const value = event.target.value;
    setSelectedIQAs(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeBatchs = (event) => {
    const value = event.target.value;
    setSelectedBatch(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function handleUploadFile() {
    setLoadingUpload(true)
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('file', selectedFile);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };


    fetch(api.user_import, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const response = JSON.parse(body);
        if (status === 200 || status === 201) {
          if (response.message == 'Import completed with some errors') {
            setSnackbarMessage(response.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setErrorUrl(response.error_file_url);
          }
          setLoadingUpload(false)
          setSelectedFile(null)
          setSnackbarMessage(response.message);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setLoadingUpload(false)
          setSnackbarMessage(response.error);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoadingUpload(false)
      });
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null)
    setErrorUrl('')
  };

  const triggerDownload = () => {
    const link = document.createElement('a');
    link.href = downloadFile;
    link.download = 'Bulk Registration Sample';
    link.target = 'blank'
    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>

        {/*Bulk Edit Modal */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openUser}
          onClose={handleCloseBulkModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              p: 4,
              justifyContent: 'center',
              maxWidth: '70%',
              maxHeight: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              overflow: 'auto',
              border: '2px solid',
              borderColor: 'primary.main',
              flex: 1,
              style
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                Bulk Edit Learner
              </Typography>
              <IconButton
                onClick={() => {
                  setOpenUser(false);
                }}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <EditBulkLearnersForm
              selectedIds={selected}
              handleClose={handleCloseBulkModal}
              refreshAdmin={refreshAdmin}
            />
          </Box>
        </Modal>

        {/* ADD MODAL IN DROPDOWN AND FORMS EASY_ASSESS */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
          open={openAddModal}
          onClose={handleCloseAddForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle }} className="admin_modal_pa">
            <Box paddingTop={2} flexDirection={'column'} display={'flex'} flex={1}>
              <Box display={'flex'} justifyContent={'space-between'} px={4} pt={2}>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {!selectedRole
                    ? 'Add'
                    : editable && add
                      ? 'Add ' + `${selectedRole.role_name} Form`
                      : editable
                        ? 'Edit ' + `${selectedRole.role_name} Form`
                        : 'View ' + `${selectedRole.role_name} Form`}
                </Typography>
                <IconButton
                  onClick={() => {
                    setOpenAddModal(false);
                  }}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <CardContent>
                {roles && (
                  <>
                    {add && (
                      <DropDown
                        dontAppend={true}
                        fullWidth={true}
                        label="Roles"
                        data={roles}
                        handleChange={(e) => handleChangeRole(e)}
                        value={selectedRole}
                      />
                    )}

                    {selectedRole?.role_name === 'Learners' ? (
                      <LearnersForm
                        formData={formDataLearners}
                        setFormData={setFormDataLearners}
                        handleSubmit={handleSubmit}
                        loading={formLoading}
                        error={alert}
                        setAlert={setalert}
                        editable={editable}
                      />
                    ) : selectedRole?.role_name === 'Assessors' ? (
                      <AssessorsForm
                        formData={formDataAssessors}
                        setFormData={setFormDataAssessors}
                        handleSubmit={handleSubmit}
                        loading={formLoading}
                        error={alert}
                        setAlert={setalert}
                        editable={editable}
                      />
                    ) : selectedRole?.role_name === 'IQAs' ? (
                      <IQAsForm
                        formData={formDataIQAs}
                        setFormData={setFormDataIQAs}
                        handleSubmit={handleSubmit}
                        loading={formLoading}
                        error={alert}
                        setAlert={setalert}
                        editable={editable}
                      />
                    ) : selectedRole?.role_name === 'Admin' ? (
                      <AdminForm
                        formData={formDataAdmin}
                        setFormData={setFormDataAdmin}
                        handleSubmit={handleSubmit}
                        loading={formLoading}
                        error={alert}
                        setAlert={setalert}
                        editable={editable}
                      />
                    ) : selectedRole?.role_name === 'EQAs' ? (
                      <EQAsForm
                        formData={formDataEQAs}
                        setFormData={setFormDataEQAs}
                        handleSubmit={handleSubmit}
                        loading={formLoading}
                        error={alert}
                        setAlert={setalert}
                        editable={editable}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </CardContent>
            </Box>
          </Box>
        </Modal>

        <Drawer
          classes={{
            paper: 'width_drawer'
          }}
          anchor={'right'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          <Box pb={2} className="cross_drawer">
            <IconButton
              aria-label="close"
              style={{
                position: 'absolute',
                right: 5,
                marginBottom: 10,
                marginRight: 10
              }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {draweLoading ?
            <Box
              py={5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1
              }}
            >
              <CircularProgress />
            </Box>
            :
            <>
              < Box
                mt={3}
                p={1}
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '90%',
                  alignSelf: 'center'
                }}
              >
                {/* Qualification */}
                {
                  listRole?.role_name == 'Learners' &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                    <Select
                      onOpen={getQualifications}
                      labelId="qualification-label"
                      id="qualification"
                      multiple
                      value={selectedQualifications}
                      onChange={handleChangeQualifications}
                      input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} color="inherit" />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        qualifications.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.sub_title}
                            style={getStyles(name, selectedQualifications, theme)}
                          >
                            {name.sub_title}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }

                {/* Assessor */}
                {
                  listRole?.role_name == 'Learners' &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="Assessor-label">Select Assessor</InputLabel>
                    <Select
                      onOpen={getAssessors}
                      labelId="Assessor-label"
                      id="Assessor"
                      multiple
                      value={selectedAssessors}
                      onChange={handleChangeAssessors}
                      input={<OutlinedInput id="select-multiple-chip" label="Select Assessor" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} sx={{ color: "#000000" }} />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        assessors.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.full_name}
                            style={getStyles(name, selectedAssessors, theme)}
                          >
                            {name.full_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }

                {/* IQA */}
                {
                  listRole?.role_name == 'Learners' &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="IQAs-label">Select IQAs</InputLabel>
                    <Select
                      onOpen={getIQAs}
                      labelId="IQAs-label"
                      id="IQAs"
                      multiple
                      value={selectedIQAs}
                      onChange={handleChangeIQAs}
                      input={<OutlinedInput id="select-multiple-chip" label="Select IQAs" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} sx={{ color: "#000000" }} />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        IQAs.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.full_name}
                            style={getStyles(name, selectedIQAs, theme)}
                          >
                            {name.full_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }

                {/* Name */}
                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                  <InputLabel id="Name-label">Name</InputLabel>
                  <Select
                    onOpen={getUsersDropdown}
                    labelId="Name-label"
                    id="Name"
                    multiple
                    value={selectedName}
                    onChange={handleChangeUserName}
                    input={<OutlinedInput id="select-multiple-chip" label="Name" />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {loadingUserDropDown ? (
                      <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                        <CircularProgress size={24} sx={{ color: "#000000" }} />
                      </MenuItem>
                    ) : errorUserDropDown ? (
                      <MenuItem disabled>No data found</MenuItem>
                    ) : (
                      userName.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.full_name}
                          style={getStyles(name, selectedName, theme)}
                        >
                          {name.full_name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                {/* Email */}
                {
                  listRole?.role_name !== 'Learners' &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="Email-label">Email</InputLabel>
                    <Select
                      onOpen={getUsersDropdown}
                      labelId="Email-label"
                      id="Email"
                      multiple
                      value={selectedEmails}
                      onChange={handleChangeEmails}
                      input={<OutlinedInput id="select-multiple-chip" label="Email" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} sx={{ color: "#000000" }} />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        userName.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.email}
                            style={getStyles(name, selectedEmails, theme)}
                          >
                            {name.email}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }

                {/* Reference */}
                {
                  (listRole?.role_name == 'Learners' || listRole?.role_name == 'Admin' || listRole?.role_name == 'EQAs') &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="Reference-label">Select Reference</InputLabel>
                    <Select
                      onOpen={getReference}
                      labelId="Reference-label"
                      id="Reference"
                      multiple
                      value={selectedReference}
                      onChange={handleChangeReference}
                      input={<OutlinedInput id="select-multiple-chip" label="Select Reference" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} sx={{ color: "#000000" }} />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        reference.map((name) => (
                          <MenuItem
                            key={name.user_qualifications[0].ref_number}
                            value={name.user_qualifications[0].ref_number}
                            style={getStyles(name, selectedReference, theme)}
                          >
                            {name.user_qualifications[0].ref_number}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }

                {/* Cohort/Batch */}
                {
                  listRole?.role_name == 'Learners' &&
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <InputLabel id="Cohort/Batch-label">Select Cohort/Batch</InputLabel>
                    <Select
                      onOpen={getBatch}
                      labelId="Cohort/Batch-label"
                      id="Cohort/Batch"
                      multiple
                      value={selectedBatch}
                      onChange={handleChangeBatchs}
                      input={<OutlinedInput id="select-multiple-chip" label="Select Cohort/Batch" />}
                      MenuProps={MenuProps}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {loadingUserDropDown ? (
                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                          <CircularProgress size={24} sx={{ color: "#000000" }} />
                        </MenuItem>
                      ) : errorUserDropDown ? (
                        <MenuItem disabled>No data found</MenuItem>
                      ) : (
                        batchs.map((name) => (
                          <MenuItem
                            key={name.cohort_batch_no}
                            value={name.cohort_batch_no}
                            style={getStyles(name, selectedBatch, theme)}
                          >
                            {name.cohort_batch_no}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                }
              </Box>

              <Box style={{ width: '90%', alignSelf: 'center', marginTop: 20, marginBottom: 20 }}>
                <Button
                  onClick={() => {
                    ClearFilter()
                  }}
                  variant="outlined"
                  component={RouterLink}
                  to="#"
                  fullWidth
                  style={{ height: 50, marginBottom: 15 }}
                >
                  Clear All
                </Button>
                <Button
                  onClick={(event) => {
                    // list.length = 0;
                    setOpenDrawer(false);
                    setPage(1);
                    getUsers(1);
                  }}
                  variant="contained"
                  component={RouterLink}
                  to="#"
                  fullWidth
                  style={{ height: 50 }}
                >
                  Apply
                </Button>
              </Box>
            </>
          }
        </Drawer>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Box display={'flex'}>

            {listRole.role_name == 'Learners' &&
              <Button onClick={() => handleOpenModal()} startIcon={<Iconify icon="material-symbols:upload" />} variant="contained" component="label" size="small" sx={{ marginRight: 1.5, height: 35 }}>
                bulk registration
              </Button>
            }

            {listRole.role_name == 'Learners' && (
              <Button
                disabled={selected.length == 0}
                onClick={() => {
                  openBulkEditModal();
                }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="tabler:edit" />}
                style={{ marginRight: 10 }}
              >
                Bulk Edit
              </Button>
            )}

            <Button
              onClick={() => {
                setOpenDrawer(true);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="bi:filter" />}
              style={{ marginRight: 10 }}
            >
              Filter
            </Button>

            <Button
              onClick={() => {
                handleOpenAddForm();
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add
            </Button>
          </Box>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'60%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getUsers();
              }}
            />

            <RoleChangeDropdown
              dontAppend={true}
              alreadyRole={listRole}
              fullWidth={false}
              setRole={(roleSelected) => {
                setlistRole(roleSelected);
                localStorage.setItem("roleSelected", JSON.stringify(roleSelected));
              }}
              disabled={loading}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getUsers(1);
              }}
              className="width_full_status"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={
                    listRole?.role_name == 'Learners'
                      ? LEARNER_TABLE_HEAD
                      : listRole?.role_name == 'Assessors'
                        ? ASSESSORS_TABLE_HEAD
                        : listRole?.role_name == 'IQAs'
                          ? ASSESSORS_TABLE_HEAD
                          : listRole?.role_name == 'Admin'
                            ? ADMINS_TABLE_HEAD
                            : listRole?.role_name == 'EQAs'
                              ? EQAS_TABLE_HEAD
                              : TABLE_HEAD
                  }
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  allSelect={selected.length == list.length && list.length > 0}
                />
                <TableBody>
                  {list.map((row, index) => {
                    return <Table_Row row={row} key={index} />;
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                          <SearchNotFound message={`No ${listRole?.role_name} Found`} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>

        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}

        <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
          <Box sx={style3}>
            <Typography id="modal-title" variant="h6" component="h2">
              Bulk Registration
            </Typography>
            <Divider />
            <Paper
              sx={dropzoneStyle}
            >
              {!selectedFile ? (
                <>
                  <CloudUploadIcon fontSize="large" />
                  <Button startIcon={<Iconify icon="material-symbols:upload-file" />} variant="contained" component="label" size="small" sx={{ height: 35, marginTop: 1, marginBottom: 1 }}>
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      accept=".xlsx,.csv,.xls"
                    />
                  </Button>
                  <Typography variant="caption">Formats accepted are .csv and .xlsx</Typography>
                </>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="body2">{selectedFile.name}</Typography>
                  <IconButton sx={{ color: "red" }} onClick={handleFileRemove}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Paper>

            {errorUrl && <RouterLink target='blank' className="routerLink" to={errorUrl}>
              <Typography variant="body2"
                sx={{
                  wordBreak: 'break-word',  // Break long words
                  whiteSpace: 'pre-wrap',   // Preserve whitespace and allow wrapping
                }}
              >{errorUrl}</Typography>
            </RouterLink>}

            <Button
              size="small"
              style={{
                justifyContent: 'flex-start',
                alignSelf: 'flex-end',
                fontSize: 11,
                paddingTop: 4
              }}
              variant="outlined"
              onClick={() => triggerDownload()}
              startIcon={
                <Iconify
                  color={"#000000"}
                  icon="ic:baseline-download"
                />
              }
            >
              Download Format
            </Button>

            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton disabled={!selectedFile} loading={loadingUpload} variant="contained" color="primary" onClick={handleUploadFile} sx={{ ml: 2 }}>
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Modal>

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Page >
  );
}
