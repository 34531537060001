import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, IconButton, Snackbar, Alert, CircularProgress } from '@mui/material';
// mocks_
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import Iconify from '../../components/Iconify';
import { api } from 'src/Api';
import { Decryption } from 'src/functions/function';
import palette from '../../theme/palette';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    backgroundColor: alpha(theme.palette.drawerBackground, 1)
  }
}));

const UserCard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  marginLeft: theme.spacing(2, 2.5),
  marginRight: theme.spacing(2, 2.5),
}));

const UserInfo = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column'
}));

const UserName = styled(Typography)(({ theme }) => ({
  flex: 1,
  fontWeight: theme.typography.fontWeightBold,
  color: '#ccc', fontSize: 13
}));

const UserEmail = styled(Typography)(({ theme }) => ({
  color: '#ccc',
  fontSize: 12
}));

const LogoutButtonStyle = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.error.main,
  margin: theme.spacing(2, 2), // horizontal margin
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.9)'
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const user = JSON.parse(localStorage.getItem('user'));
  console.log("🚀 ~ DashboardSidebar ~ user:", user)

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [sideBar, setsideBar] = useState([]);

  function getSideBar() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.sideBar, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to fetch sidebar data');
        }
      })
      .then((result) => {
        let decryptedData = Decryption(result.data);
        setsideBar(decryptedData);
        localStorage.setItem('permissions', JSON.stringify(decryptedData));
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  const fileInputRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    getSideBar();
  }, []);

  const [loading, setloading] = useState(false);
  const [loadingProfile, setloadingProfile] = useState(false);

  function Logout() {
    setloading(true);
    const rememberEmail = localStorage.getItem('email');
    const rememberPassword = localStorage.getItem('password');
    const rememberMe = localStorage.getItem('remember');

    // Clear all local storage
    localStorage.clear();

    // Re-set remembered credentials
    if (rememberMe === 'true') {
      localStorage.setItem('email', rememberEmail);
      localStorage.setItem('password', rememberPassword);
      localStorage.setItem('remember', rememberMe);
    }
    setTimeout(() => {
      navigate('/login', { replace: true });
      setloading(false);
    }, 300);
  }

  function handleUploadAvatar(event) {
    setloadingProfile(true)
    const file = event.target.files[0];
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('avatar', file);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.update_profile_picture, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const response = JSON.parse(body);
        if (status === 200 || status === 201) {
          localStorage.setItem('user', JSON.stringify(response.data));
          setSnackbarMessage(response.message);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setloadingProfile(false)
        } else {
          setSnackbarMessage(response.error);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setloadingProfile(false)
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
      });
  }

  const renderContent = (
    <Box sx={{ height: 1, display: "flex", backgroundColor: palette.drawerBackground, flexDirection: "column" }}>
      <Box
        sx={{
          px: 3,
          py: 2,
          pt: 1.5,
          display: 'inline-flex',
          width: "100%",
          height: 100,
          marginLeft: 0,
          backgroundColor: palette.drawerBackground
        }}
      >
        <Logo sx={{
          width: 300,
          height: 80,
        }} />
      </Box>

      <Scrollbar
        sx={{
          flex: 1,
          backgroundColor: palette.drawerBackground,
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <NavSection navConfig={sideBar} />
      </Scrollbar>

      <Box
        style={{
          backgroundColor: 'rgb(145 158 166 / 12%)',
        }}
      >
        {(user?.id !== user?.company_admin_id && user?.company_admin_id !== 0) &&
          <Box sx={{ display: "flex", padding: "5px 0px 5px 18px", alignItems: "center", borderBottom: '3px solid #121315' }}>
            <Avatar style={{ width: 32, height: 32, marginRight: 17, borderRadius: 0 }} alt={user?.company_logo} src={user?.company_logo} />
            <UserName variant="subtitle2">{user?.company_name}</UserName>
          </Box>
        }
        {user && (
          <UserCard>
            <IconButton onClick={handleAvatarClick}>
              {loadingProfile ?
                <Box style={{ width: 35, height: 35, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress size={20} sx={{ color: '#ffffff' }} />
                </Box>
                :
                <Avatar style={{ width: 35, height: 35 }} alt={user?.full_name} src={user?.avatar} />
              }
            </IconButton>
            <UserInfo>
              <UserName variant="subtitle1">{user?.full_name}</UserName>
              <UserEmail variant="body2">{user?.email}</UserEmail>
              {user.role.id !== 1 && user.role.id !== 2 && <p style={{
                color: user.role.id == 3 && "green" ||
                  user.role.id == 4 && "blue" ||
                  user.role.id == 5 && "#ED3C3F" ||
                  user.role.id == 7 && "#FFD700",
                fontSize: 15,
                fontWeight: "bold"
              }}>{user?.role?.role_name}</p>}
            </UserInfo>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleUploadAvatar}
            />
          </UserCard>
        )}

        <LogoutButtonStyle
          onClick={Logout}
          variant="outlined"
          loading={loading}
          sx={{
            color: 'black',
            backgroundColor: 'white',
            minWidth: 180,
            left: 18,
            marginTop: 1
          }}
        >
          Logout
        </LogoutButtonStyle>
      </Box>
    </Box>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </RootStyle>
  );
}
