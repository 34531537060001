import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, Grid, Alert, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import { Navigate, useNavigate } from 'react-router-dom';

const LearnersForm = ({ edit, selectedLearner, handleClose, refreshPage }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    referenceNumber: '',
    firstName: '',
    middleName: '',
    surname: '',
    learnerNumber: '',
    qualification: [],
    assessor: [],
    iqa: [],
    registrationDate: '',
    cohortBatch: '',
    email: '',
    contact: '',
    dob: '',
    address: '',
    disability: false,
    user_qualification_id: ''
  });

  const [qualifications, setQualifications] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [iqas, setIqas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ visible: false, message: '' });

  useEffect(() => {
    if (edit) {
      setFormData({
        referenceNumber: selectedLearner?.user_qualifications?.ref_number || '',
        firstName: selectedLearner?.user_qualifications?.first_name || '',
        middleName: selectedLearner?.user_qualifications?.middle_name || '',
        surname: selectedLearner?.user_qualifications?.sur_name || '',
        qualification: selectedLearner?.user_qualifications?.qualifications_with_trash || [],
        assessor: selectedLearner?.user_qualifications?.assessors_with_trash || [],
        iqa: selectedLearner?.user_qualifications?.iqa_with_trash || [],
        registrationDate: selectedLearner?.user_qualifications?.date_of_registration || '',
        cohortBatch: selectedLearner?.user_qualifications?.cohort_batch_no || '',
        email: selectedLearner?.email || '',
        contact: selectedLearner?.user_qualifications?.contact || '',
        dob: selectedLearner?.user_qualifications?.date_of_birth || '',
        address: selectedLearner?.user_qualifications?.location || '',
        learnerNumber: selectedLearner?.user_qualifications?.learner_number || '',
        user_qualification_id: selectedLearner.user_qualifications.id
      });
    }
  }, []);

  // const handleChange = (e) => {
  //   setError({ visible: false, message: '' });
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  const handleChange = (e) => {
    setError({ visible: false, message: '' });
    const { name, value } = e.target;
    console.log("🚀 ~ handleChange ~ value:", value)

    if (name === 'qualification') {
      // Clear the assessor and iqa fields when qualification changes
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        assessor: [], // Clear assessor field
        iqa: [] // Clear iqa field
      }));
      if (value.id) {
        getAssessors(value.id);
        getIqas(value.id);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleCheckboxChange = (event) => {
    setFormData({ ...formData, disability: event.target.checked });
  };

  function getQualifications() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV2}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${true}`;


    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setQualifications(result.data);
          if (edit) {
            setFormData((prevData) => ({
              ...prevData,
              ['qualification']: result?.data?.find(
                (item) => item?.id == selectedLearner?.user_qualifications?.qualifications_with_trash_id
              )
            }));
          }
        } else {
          setQualifications([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  // const getAssessors = async (qualification_id) => {
  //   const token = localStorage.getItem('token');
  //   const user = JSON.parse(localStorage.getItem('user'));

  //   try {
  //     const response = await fetch(
  //       `${api.get_assessorsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualification_id=${qualification_id}&isEdit=${true}`,
  //       {
  //         method: 'GET',
  //         headers: new Headers({
  //           Authorization: `Bearer ${token}`
  //         })
  //       }
  //     );
  //     const result = await response.json();
  //     if (response.status === 200) {
  //       setAssessors(result.data);
  //       if (edit) {
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           ['assessor']: result?.data?.find(
  //             (item) => item?.id == selectedLearner?.user_qualifications[0]?.assessors[0]?.id
  //           )
  //         }));
  //       }
  //     } else {
  //       setAssessors([]);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  function getAssessors(qualification_id) {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_assessorsV2}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualification_id=${qualification_id}&isEdit=${true}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setAssessors(result.data);
          if (edit) {
            setFormData((prevData) => ({
              ...prevData,
              ['assessor']: result?.data?.find(
                (item) => item?.id == selectedLearner?.user_qualifications?.assessors_with_trash_id
              )
            }));
          }
        } else {
          setAssessors([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  const getIqas = async (qualification_id) => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      const response = await fetch(
        `${api.get_iqasV2}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualification_id=${qualification_id}&isEdit=${true}`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${token}`
          })
        }
      );
      const result = await response.json();
      if (response.status === 200) {
        setIqas(result.data);
        if (edit) {
          setFormData((prevData) => ({
            ...prevData,
            ['iqa']: result?.data?.find(
              (item) => item?.id == selectedLearner?.user_qualifications?.iqa_with_trash_id
            )
          }));
        }
      } else {
        setIqas([]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const appendIfNotEmpty = (formData, key, value) => {
    if (value !== null && value !== undefined && value !== '') {
      formData.append(key, value);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    if (edit) appendIfNotEmpty(formdata, 'id', selectedLearner?.id);
    appendIfNotEmpty(formdata, 'role_id', '3');
    appendIfNotEmpty(formdata, 'ref_number', formData.referenceNumber);
    appendIfNotEmpty(formdata, 'first_name', formData.firstName);
    appendIfNotEmpty(formdata, 'middle_name', formData.middleName);
    appendIfNotEmpty(formdata, 'sur_name', formData.surname);
    appendIfNotEmpty(formdata, 'learner_number', formData.learnerNumber);
    appendIfNotEmpty(formdata, 'qualifications', JSON.stringify([formData.qualification]));
    appendIfNotEmpty(formdata, 'assessors', JSON.stringify([formData.assessor]));
    appendIfNotEmpty(formdata, 'iqas', JSON.stringify([formData.iqa]));
    appendIfNotEmpty(formdata, 'date_of_registration', formData.registrationDate);
    appendIfNotEmpty(formdata, 'cohort_batch_no', formData.cohortBatch);
    appendIfNotEmpty(formdata, 'email', formData.email);
    appendIfNotEmpty(formdata, 'contact', formData.contact);
    appendIfNotEmpty(formdata, 'date_of_birth', formData.dob);
    appendIfNotEmpty(formdata, 'location', formData.address);
    appendIfNotEmpty(formdata, 'country', 'USA');
    appendIfNotEmpty(formdata, 'city', 'N/A');
    appendIfNotEmpty(formdata, 'lat', 'N/A');
    appendIfNotEmpty(formdata, 'lon', 'N/A');
    appendIfNotEmpty(formdata, 'disability', formData.disability == true ? 'yes' : 'no');
    appendIfNotEmpty(formdata, 'user_qualification_id', selectedLearner.user_qualifications?.id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.create_user, requestOptions)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return response.text();
        } else {
          return response.json().then((result) => {
            setError({ visible: true, message: result.error });
          });
        }
      })
      .then((result) => {
        const response = JSON.parse(result);
        navigate(-1)
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    getQualifications();
  }, []);

  useEffect(() => {
    if (formData.qualification.id) {
      getAssessors(formData.qualification.id);
      getIqas(formData.qualification.id);
    }
  }, [formData.qualification.id]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={edit}
            fullWidth
            variant="outlined"
            label="Code"
            name="Code"
            value={selectedLearner.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            required
            fullWidth
            label="Reference Number"
            variant="outlined"
            name="referenceNumber"
            value={formData.referenceNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            required
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            label=" Middle Name"
            variant="outlined"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            label="Surname"
            variant="outlined"
            required
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            label="Learner Number (if any)"
            variant="outlined"
            name="learnerNumber"
            value={formData.learnerNumber}
            onChange={handleChange}
            type='number'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField
            disabled={!edit}
            select
            fullWidth
            label="Select Qualification/Subject"
            variant="outlined"
            required
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
          >
            {qualifications.map((item) => {
              return (
                <MenuItem key={item.id} value={item}>
                  <Typography>
                    {item?.sub_title}
                  </Typography>
                  {item.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                    DELETED
                  </Typography>}
                </MenuItem>
              );
            })}
          </TextField> */}

          <TextField
            disabled={!edit}
            select
            fullWidth
            label="Select Qualification/Subject"
            variant="outlined"
            required
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
          >
            {qualifications.map((item) => {
              return (
                <MenuItem key={item.id} value={item}>
                  <Typography>
                    {item?.sub_title}
                  </Typography>
                  {item.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                    DELETED
                  </Typography>}
                </MenuItem>
              )
            })}
          </TextField>
        </Grid>
        {/* ASSESSOR */}
        <Grid item xs={12} sm={6}>
          {/* <TextField
            disabled={!edit}
            select
            fullWidth
            label="Select Assessor"
            variant="outlined"
            required
            name="assessor"
            value={formData.assessor}
            onChange={handleChange}
          >
            {assessors.map((item) => {
              return (
                <MenuItem key={item.id} value={item} style={{ textTransform: 'capitalize' }}>
                  <Typography>
                    {item?.full_name}
                  </Typography>
                  {item.deleted_at &&
                    <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                      DELETED
                    </Typography>
                  }
                </MenuItem>
              );
            })} 
          </TextField>*/}
          <TextField
            disabled={!edit}
            select
            required
            fullWidth
            label="Select Assessor"
            variant="outlined"
            name="assessor"
            value={formData.assessor}
            onChange={handleChange}
          >
            {assessors.map((item) => {
              return (
                <MenuItem key={item.id} value={item} style={{ textTransform: 'capitalize' }}>
                  <Typography>
                    {item?.full_name}
                  </Typography>
                  {item.deleted_at &&
                    <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                      DELETED
                    </Typography>
                  }
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {/* IQA */}
        <Grid item xs={12} sm={6}>
          {/* <TextField
            disabled={!edit}
            select
            fullWidth
            label="Select IQA"
            variant="outlined"
            required
            name="iqa"
            value={formData.iqa}
            onChange={handleChange}
          >
            {iqas.map((item) => {
              return (
                <MenuItem key={item.id} value={item} style={{ textTransform: 'capitalize' }}>
                  <Typography>
                    {item?.full_name}
                  </Typography>
                  {item.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                    DELETED
                  </Typography>}
                </MenuItem>
              );
            })}
          </TextField> */}
          <TextField
            disabled={!edit}
            select
            fullWidth
            required
            label="Select IQA"
            variant="outlined"
            name="iqa"
            value={formData.iqa}
            onChange={handleChange}
          >
            {iqas.map((item) => {
              return (
                <MenuItem key={item.id} value={item} style={{ textTransform: 'capitalize' }}>
                  <Typography>
                    {item?.full_name}
                  </Typography>
                  {item.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                    DELETED
                  </Typography>}
                </MenuItem>
              )
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            required
            fullWidth
            variant="outlined"
            type="date"
            name="registrationDate"
            value={formData.registrationDate}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            variant="outlined"
            label="Cohort batch no."
            name="cohortBatch"
            value={formData.cohortBatch}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            variant="outlined"
            label="Email"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            fullWidth
            variant="outlined"
            label="Contact"
            required
            name="contact"
            type='number'
            value={formData.contact}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!edit}
            required
            fullWidth
            variant="outlined"
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.disability}
                onChange={handleCheckboxChange}
                name="Disability (if any)"
                color="primary"
              />
            }
            label="Disability (if any)"
            sx={{ mt: 1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!edit}
            fullWidth
            variant="outlined"
            label="Address"
            required
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {error.visible && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error.message}
        </Alert>
      )}

      {edit && (
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
        >
          Save
        </LoadingButton>
      )}
    </Box>
  );
};

export default LearnersForm;
