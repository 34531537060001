import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Modal,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';
import CategoriesModal from 'src/sections/@dashboard/categories/categoriesModal';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import SearchNotFound from 'src/components/SearchNotFound';

export default function Categories() {
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];

  function checkRender() {
    let check = 'categories';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getCategories(value);
  };

  const [expandedKeys, setExpandedKeys] = useState({});
  const collectKeys = (list) => {
    let keys = {};
    list.forEach((item) => {
      keys[item.key] = true;
      if (item.children) {
        keys = { ...keys, ...collectKeys(item.children) };
      }
    });
    return keys;
  };

  const toggleAll = () => {
    setExpandedKeys(collectKeys(list));
  };

  useEffect(() => {
    if (search.length > 0) {
      toggleAll();
    } else {
      setExpandedKeys({});
    }
  }, [list]);

  function getCategories(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const searchParam = search.length > 0 ? search : null;
    const pageParam = null;
    let url = api.get_categories;
    const params = new URLSearchParams();
    if (searchParam) params.append('search', searchParam);
    if (pageParam) params.append('page', pageParam);
    if (params.toString()) url += `?${params.toString()}`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        list.length = 0;
        let decryptedData = result.data;
        setlist(decryptedData);
        result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getCategories();
  }, []);

  const [openCategories, setopenCategories] = React.useState(false);
  const [categories, setcategories] = useState(null);

  function openCategoriesModal(categories) {
    setcategories(categories);
    setopenCategories(true);
  }

  const handleClosecategorieModal = () => setopenCategories(false);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const [selectedId, setselectedId] = useState(null);

  function Deletecategories(id) {
    setselectedId(id);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.delete_categories + '?id=' + id, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          return response.json().then((result) => {
            setmessage(result.message);
            setmode('error');
            setshowSnackbar(true);
          });
        }
      })
      .then((result) => {
        setmode('success');
        setmessage(result.message);
        setshowSnackbar(true);
        refresh();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setselectedId(null);
      });
  }

  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState('');
  const [mode, setmode] = useState('error');

  const actionTemplate = (data) => {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        {permission.includes('edit') && (
          <Button
            size="small"
            onClick={() => openCategoriesModal(data)}
            style={{
              justifyContent: 'flex-start',
              alignSelf: 'center',
              marginRight: 5
            }}
            variant="outlined"
            component={RouterLink}
            to="#"
            startIcon={<Iconify color={pallete.primary.main} icon="solar:user-id-bold" />}
          >
            Edit
          </Button>
        )}
        {permission.includes('delete') && (
          <>
            <LoadingButton
              size="small"
              onClick={() => Deletecategories(data?.key)}
              style={{
                justifyContent: 'flex-start',
                alignSelf: 'center'
              }}
              variant="outlined"
              loading={selectedId == data?.key}
              startIcon={<Iconify color={pallete.primary.main} icon="material-symbols:delete" />}
            >
              Delete
            </LoadingButton>
          </>
        )}
      </Box>
    );
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Name"
          headerStyle={{ backgroundColor: 'white', fontWeight: 'bold', padding: 20 }}
        />
        <Column
          header="Actions"
          headerStyle={{
            backgroundColor: 'white',
            fontWeight: 'bold',
            paddingTop: 20,
            paddingBottom: 20
          }}
        />
      </Row>
    </ColumnGroup>
  );

  const refresh = () => {
    setopenCategories(false);
    getCategories(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getCategories(page);
    }
  }, [search]);

  return (
    <Page title={`Categories | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setshowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setshowSnackbar(false);
            }}
            severity={mode}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        {/* EDIT CATEGORIES */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openCategories}
          onClose={handleClosecategorieModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '60%',
              maxHeight: '90%',
              minHeight: '60%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
              border: '2px solid',
              borderColor: 'primary.main',
              flex: 1,
              style
            }}
            className="admin_modal_pa"
          >
            <CategoriesModal
              categories={categories}
              handleClose={handleClosecategorieModal}
              refreshAdmin={refresh}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          {permission.includes('add') && (
            <Button
              onClick={() => {
                openCategoriesModal(null);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add
            </Button>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={'Search ...'}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getCategories();
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getCategories(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                  backgroundColor: 'white'
                }}
              >
                <CircularProgress />
              </Box>
            ) : list.length == 0 ? (
              <>
                <TreeTable
                  emptyMessage={' '}
                  headerColumnGroup={headerGroup}
                  value={list}
                  tableStyle={{ minWidth: '50rem' }}
                ></TreeTable>
                <Box p={5}>
                  <SearchNotFound searchQuery={search} />
                </Box>
              </>
            ) : (
              <TreeTable
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
                emptyMessage={' '}
                headerColumnGroup={headerGroup}
                value={list}
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 10,
                    fontSize: 14
                  }}
                  field="label"
                  expander
                />

                <Column body={actionTemplate} />
              </TreeTable>
            )}
          </Scrollbar>
          {console.log(expandedKeys)}
        </Card>
      </Container>
    </Page>
  );
}
