import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { Alert, Button, Card, Chip, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Pagination, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, TextField, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { api } from 'src/Api';
import { UserListHead } from 'src/sections/@dashboard/user';
import moment from 'moment';
import pallete from '../theme/palette';
import { PausePresentationTwoTone } from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    outline: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function QASampling() {

    const theme = useTheme();
    const navigate = useNavigate()

    const TABLE_HEAD = [
        { id: 'learner_name', label: "Learner Name", alignRight: false },
        { id: 'progress', label: "Progress", alignRight: false },
        { id: 'assessor', label: "Assessor", alignRight: false },
        { id: 'qualification_subject_title', label: "Qualification/Subject Title", alignRight: false },
        { id: 'qualification #', label: "Qualification #", alignRight: false },
        { id: 'sampling_ratio', label: "Sampling ratio", alignRight: false },
        { id: 'sampling_%age', label: "Sampling %age", alignRight: false },
        { id: 'action', label: "Action", alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [addModal, setAddModal] = useState(false);
    const [assessorsList, setAssessorsList] = useState([]);
    console.log("🚀 ~ QASampling ~ assessorsList:", assessorsList)
    const [dateSampling, setDateSampling] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [submitLoading, setSubmitLoading] = useState(false);

    const [selectedQualifications, setSelectedQualifications] = useState([]);
    const [selectedAssessors, setSelectedAssessors] = useState([]);

    const [qualifications, setQualification] = useState([]);
    const [assessors, setAssessors] = useState([]);

    const [errorUserDropDown, setErrorUserDropDown] = useState(false);
    const [loadingUserDropDown, setLoadingUserDropDown] = useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const isIQANotFound = assessorsList.length === 0;

    useEffect(() => {
        if (selectedQualifications.length !== 0 || selectedAssessors.length !== 0) {
            getAssessors(page);
        }
    }, [page, selectedQualifications, selectedAssessors]);

    useEffect(() => {
        if (search.length == 0) {
            assessorsList.length = 0;
            getAssessors(page);
        }
    }, [search]);

    const handleCancelFilter = () => {
        setSelectedAssessors([])
        setSelectedQualifications([])
        handleReload("reload")
    }
    
    const handleReload = (checking) => {
        assessorsList.length = 0;
        getAssessors(page, checking);
    }

    useEffect(() => {
        checkRender();
    }, []);

    const refreshAdmin = () => {
        setAssessorsList([])
        setAddModal(false);
        assessorsList.length = 0;
        getAssessors(page);
    };

    function checkRender() {
        let check = 'qa_samlings';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const getAssessors = (page, checking) => {
        setloading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const QualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);
        const AssessorCheck = assessors
            .filter((a) => selectedAssessors.includes(a.full_name))
            .map((a) => a.id);

        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('company_admin_id', user?.company_admin_id);
        queryParams.append('role_id', 3);
        queryParams.append('iqa_id', user.id);
        checking !== 'reload' && QualificationCheck?.length !== 0 && queryParams.append('qualification_id', QualificationCheck.join(','));
        checking !== 'reload' && AssessorCheck?.length !== 0 && queryParams.append('assessor_id', AssessorCheck.join(','));

        fetch(`${api.getUsers_V1}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAssessorsList(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                    setloading(false)
                } else {
                    setAssessorsList([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    };

    const getAssessorsDropDown = () => {

        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));

        const QualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);

        fetch(`${api.get_assessorsV2}?company_admin_id=${userData?.company_admin_id}&qualification_id=${QualificationCheck.join(',')}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAssessors(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setAssessors([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                console.log('error', error)
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
            });
    }

    const getQualifications = () => {
        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_qualificationsV3}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualification(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                    setQualification([]);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setErrorUserDropDown(true)
                setLoadingUserDropDown(false)
            });
    };

    const handlePageChange = (event, value) => {
        assessorsList.length = 0;
        setPage(value);
        getAssessors(value);
    };

    const handleCloseAddModal = () => {
        setAddModal(false);
    }

    const handleSubmit = () => {
        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();
        if (dateSampling) formdata.append('date', dateSampling); // Added dateSampling value

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.save_iqa_report, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201) {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    refreshAdmin();
                    handleCloseAddModal()
                } else {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    handleCloseAddModal()
                }
            })
            .catch((error) => {
                setSubmitLoading(false)
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleCloseAddModal()
            });

    };

    const handleChangeQualifications = (event) => {
        const value = event.target.value;
        setSelectedQualifications(typeof value === 'string' ? value.split(',') : value);
        setAssessorsList([])
    };

    const handleChangeAssessors = (event) => {
        const value = event.target.value;
        setSelectedAssessors(typeof value === 'string' ? value.split(',') : value);
        setAssessorsList([])
    };


    return (
        <Page title={`qa_samlings | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        QA Sampling
                    </Typography>
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'90%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={'Search QA'}
                            onEnter={() => {
                                setsearchLoading(true);
                                assessorsList.length = 0;
                                getAssessors();
                            }}
                        />
                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getAssessors(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Box
                        px={2}
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: "space-between",
                        }}
                        className="top_column"
                    >
                        <FormControl sx={{ flex: 1 }} fullWidth variant="outlined">
                            <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                            <Select
                                onOpen={getQualifications}
                                labelId="qualification-label"
                                id="qualification"
                                // multiple
                                value={selectedQualifications}
                                onChange={handleChangeQualifications}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                                MenuProps={MenuProps}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {loadingUserDropDown ? (
                                    <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                        <CircularProgress size={24} color="inherit" />
                                    </MenuItem>
                                ) : errorUserDropDown ? (
                                    <MenuItem disabled>No data found</MenuItem>
                                ) : (
                                    qualifications.map((name) => (
                                        <MenuItem
                                            key={name.id}
                                            value={name.sub_title}
                                            style={getStyles(name, selectedQualifications, theme)}
                                        >
                                            {name.sub_title}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1, marginLeft: 1 }} fullWidth variant="outlined">
                            <InputLabel id="Assessor-label">Select Assessor</InputLabel>
                            <Select
                                onOpen={getAssessorsDropDown}
                                labelId="Assessor-label"
                                id="Assessor"
                                // multiple
                                value={selectedAssessors}
                                onChange={handleChangeAssessors}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Assessor" />}
                                MenuProps={MenuProps}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {loadingUserDropDown ? (
                                    <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                        <CircularProgress size={24} sx={{ color: "#000000" }} />
                                    </MenuItem>
                                ) : errorUserDropDown ? (
                                    <MenuItem disabled>No data found</MenuItem>
                                ) : (
                                    assessors.map((name) => (
                                        <MenuItem
                                            key={name.id}
                                            value={name.full_name}
                                            style={getStyles(name, selectedAssessors, theme)}
                                        >
                                            {name.full_name}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>

                        <LoadingButton
                            style={{ width: '14%', marginLeft: '2.5%' }}
                            variant="contained"
                            onClick={() => {
                                handleCancelFilter()
                            }}
                            className="width_full_status"
                        >
                            Cancel
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead headLabel={TABLE_HEAD} rowCount={assessorsList.length} />

                                <TableBody>
                                    {assessorsList.map((row) => {
                                        const { id, full_name, user_qualifications } = row;
                                        return (
                                            <TableRow key={id}
                                                sx={{
                                                    backgroundColor: user_qualifications?.disability == 1 ? 'rgba(255, 54, 1, 0.3)' : 'transparent',
                                                }}
                                            >
                                                {/* NAME */}
                                                <TableCell noWrap align="left">
                                                    {full_name || 'N/A'}
                                                </TableCell>

                                                {/* PROGRESS user_qualifications */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.progress || 'N/A'}
                                                </TableCell>

                                                {/* name assessors_with_trash */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.assessors_with_trash_full_name || 'N/A'}
                                                </TableCell>

                                                {/* name qualifications_with_trash */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.qualifications_with_trash_sub_title || 'N/A'}
                                                </TableCell>

                                                {/* number qualifications_with_trash */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.qualifications_with_trash_sub_number || 'N/A'}
                                                </TableCell>

                                                {/* assessors_with_trash user_qualifications sampling_ratio */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.assessors_with_trash_sampling_ratio ?
                                                        `${user_qualifications?.assessors_with_trash_sampling_ratio}%` :
                                                        "0%"}
                                                </TableCell>

                                                {/* user_qualifications sampling_ratio */}
                                                <TableCell noWrap align="left">
                                                    {user_qualifications?.sampling_age || 'N/A'}
                                                </TableCell>

                                                <TableCell noWrap align="left" sx={{ width: 120 }}>
                                                    <Button variant="contained" color="primary" sx={{ fontSize: 10, width: 120 }}
                                                        onClick={() => navigate(`/dashboard/qa_samlings/QADetails/${row?.id}/${user_qualifications?.qualifications_with_trash_id}`)}
                                                    >
                                                        Quality Assure
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isIQANotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No QA Sampling Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Modal open={addModal} onClose={handleCloseAddModal} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={style3}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Sampling Requirement
                    </Typography>

                    <Paper>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Date of Sampling"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                name="DateofSampling"
                                value={dateSampling}
                                onChange={(e) => setDateSampling(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                    </Paper>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button disabled={submitLoading} variant="contained" color="primary" onClick={handleCloseAddModal}>
                            Cancel
                        </Button>
                        <LoadingButton loading={submitLoading} variant="contained" color="primary" sx={{ ml: 2 }} onClick={handleSubmit}>
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Page>
    )
}

export default QASampling
