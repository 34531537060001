import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';

import pallete from '../theme/palette';
import { api } from '../Api';
import { useTranslation } from 'react-i18next';
import ColorsModal from 'src/sections/@dashboard/colors/colorModal';

export default function Colors() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'color', label: 'Color', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];

  function checkRender() {
    let check = 'colors';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getColors(value);
  };

  function getColors(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const searchParam = search.length > 0 ? search : null;
    const pageParam = page ? page : null;

    let url = api.get_color;
    const params = new URLSearchParams();
    if (searchParam) params.append('search', searchParam);
    if (pageParam) params.append('page', pageParam);
    if (params.toString()) url += `?${params.toString()}`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to get brands');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        list.length = 0;
        let decryptedData = result.data;
        setlist(decryptedData);
        result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
      })
      .catch((error) => {
        console.log('error', error);
        setlist([]);
        setpagesCount(0);
      })
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getColors();
  }, []);

  const [openColor, setopenColor] = React.useState(false);
  const [color, setColor] = useState(null);

  function openColorModal(color) {
    setColor(color);
    setopenColor(true);
  }

  const handleCloseColorModal = () => setopenColor(false);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const refresh = () => {
    setopenColor(false);
    getColors(page);
  };

  useEffect(() => {
    if (search.length == 0) {
      list.length = 0;
      getColors(page);
    }
  }, [search]);

  const [selectedId, setselectedId] = useState(null);

  function DeleteColor(id) {
    setselectedId(id);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.delete_color + '?id=' + id, requestOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        } else {
          return response.json().then((result) => {
            setmessage(result.message);
            setmode('error');
            setshowSnackbar(true);
          });
        }
      })
      .then((result) => {
        setmode('success');
        setmessage(result.message);
        setshowSnackbar(true);
        refresh();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setselectedId(null);
      });
  }

  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState('');
  const [mode, setmode] = useState('error');

  const isDataNotFound = list.length === 0;

  return (
    <Page title={`Colors | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* SNACKBAR FOR DELETE */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setshowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setshowSnackbar(false);
            }}
            severity={mode}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        {/* EDIT COLOR */}
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
          open={openColor}
          onClose={handleCloseColorModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '60%',
              maxHeight: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              overflow: 'auto',
              p: 1,
              border: '2px solid',
              borderColor: 'primary.main',
              flex: 1,
              style
            }}
            className="admin_modal_pa"
          >
            <ColorsModal color={color} handleClose={handleCloseColorModal} refreshAdmin={refresh} />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Colors
          </Typography>
          {permission.includes('add') && (
            <Button
              onClick={() => {
                openColorModal(null);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add
            </Button>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={t('Agents.search')}
              onEnter={() => {
                setsearchLoading(true);
                list.length = 0;
                getColors();
              }}
            />

            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getColors(1);
              }}
              className="width_full_status"
            >
              {' '}
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row) => {
                    const { id, name, code } = row;

                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        style={{
                          backgroundColor: 'transparent',
                          cursor: 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* NAME */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle2" flexWrap={'wrap'}>
                            {name === 'undefined' ? 'N/A' : name}
                          </Typography>
                        </TableCell>
                        {/* COLOR */}
                        <TableCell scope="row" padding="normal">
                          <Typography variant="subtitle2" flexWrap={'wrap'}>
                            {code || 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* ACTION */}
                        <TableCell align="left">
                          {permission.includes('edit') && (
                            <Button
                              size="small"
                              onClick={() => openColorModal(row)}
                              style={{
                                justifyContent: 'flex-start',
                                alignSelf: 'center',
                                marginRight: 5
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="solar:user-id-bold" />
                              }
                            >
                              Edit
                            </Button>
                          )}
                          {permission.includes('delete') && (
                            <>
                              <LoadingButton
                                size="small"
                                onClick={() => DeleteColor(id)}
                                style={{
                                  justifyContent: 'flex-start',
                                  alignSelf: 'center'
                                }}
                                variant="outlined"
                                loading={selectedId == id}
                                startIcon={
                                  <Iconify
                                    color={pallete.primary.main}
                                    icon="material-symbols:delete"
                                  />
                                }
                              >
                                Delete
                              </LoadingButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isDataNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
