import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';

import Page from '../components/Page';
import {
    Alert,
    Button,
    Card,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Modal,
    Pagination,
    Paper,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { api } from 'src/Api';
import { UserListHead } from 'src/sections/@dashboard/user';

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    outline: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function AssessorList() {

    const theme = useTheme();

    const TABLE_HEAD = [
        { id: 'Assessor Name', label: "Assessor Name", alignRight: false },
        { id: 'Qualification Title', label: "Qualification Title", alignRight: false },
        { id: 'Qualification #', label: "Qualification #", alignRight: false },
        { id: 'Sampling Requirement', label: "Sampling Ratio", alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    console.log("🚀 ~ AssessorList ~ searchLoading:", searchLoading)
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [addModal, setAddModal] = useState(false);
    const [assessors, setAssessors] = useState([]);
    const [dateSampling, setDateSampling] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectId, setSelectId] = useState(null);
    const [assessorId, setAssessorId] = useState(null);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const isIQANotFound = assessors.length === 0;

    useEffect(() => {
        getAssessors(page);
    }, [page]);

    useEffect(() => {
        if (search.length == 0) {
            assessors.length = 0;
            getAssessors(page);
        }
    }, [search]);

    useEffect(() => {
        checkRender();
    }, []);

    const refreshAdmin = () => {
        setAddModal(false);
        assessors.length = 0;
        getAssessors(page);
    };

    function checkRender() {
        let check = 'assessors';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const getAssessors = () => {
        setloading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('company_admin_id', user?.company_admin_id);
        // queryParams.append('role_id', 4);
        user?.role?.id == 5 && queryParams.append('iqa_id', user?.id);

        fetch(`${api.get_assessors_list}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAssessors(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                } else {
                    setAssessors([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setloading(false);
                setsearchLoading(false);
            })
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    };

    const handlePageChange = (event, value) => {
        assessors.length = 0;
        setPage(value);
        getAssessors(value);
    };

    const handleCloseAddModal = () => {
        setAddModal(false);
        setDateSampling('')
    }

    const handleOpenAddModal = (id, qualification, samplingRatio) => {
        setAssessorId(id)
        setSelectId(qualification)
        setDateSampling(samplingRatio ? samplingRatio : 0)
        setAddModal(true);
    }

    const handleChangeSamplingRatio = () => {
        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();
        user && formdata.append('user_id', assessorId); // Added dateSampling value
        selectId && formdata.append('qualification_id', selectId); // Added dateSampling value
        user && formdata.append('company_admin_id', user?.company_admin_id); // Added dateSampling value
        dateSampling && formdata.append('value', dateSampling); // Added dateSampling value

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.change_sampling_ratio, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    refreshAdmin();
                    handleCloseAddModal()
                } else {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    handleCloseAddModal()
                }
            })
            .catch((error) => {
                setSubmitLoading(false)
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleCloseAddModal()
            });
    };

    return (
        <Page title={`assessors | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        Assessors
                    </Typography>
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'90%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={'Search Assessors'}
                            onEnter={() => {
                                setsearchLoading(true);
                                assessors.length = 0;
                                getAssessors();
                            }}
                        />
                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getAssessors(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead headLabel={TABLE_HEAD} rowCount={assessors.length} />

                                <TableBody>
                                    {assessors.map((row) => {
                                        const { id, full_name, user_qualifications, qualification_title, qualification_no, sampling_ratio, user_id, qualification_id } = row;
                                        return (
                                            <TableRow key={id}>
                                                <TableCell noWrap align="left">
                                                    {full_name || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {qualification_title || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {qualification_no || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left" alignItems="center" >
                                                    {sampling_ratio ?
                                                        `${sampling_ratio}%` :
                                                        '0%'}
                                                    {permission.includes('edit') &&
                                                        <Button variant='outlined' sx={{ marginLeft: 2, fontSize: 12 }}
                                                            onClick={() => handleOpenAddModal(user_id, qualification_id, sampling_ratio)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isIQANotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No Assessor Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Modal open={addModal} onClose={handleCloseAddModal} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={style3}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Sampling Ratio
                    </Typography>

                    <Paper>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type='text'
                                fullWidth
                                label="Sampling Ratio (%)"
                                variant="outlined"
                                name="samplingRatio"
                                value={`${dateSampling}%`}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Remove any non-numeric characters except for '.' and '%'
                                    value = value.replace(/[^0-9.%]/g, '');

                                    // Remove '%' for processing
                                    value = value.replace('%', '');

                                    // Regular expression to match valid percentages from 0 to 99.9 or exactly 100
                                    const validPercentageRegex = /^(\d{1,2}(\.\d{1})?|100(\.0{0,1})?)$/;

                                    // If the value matches the regex or is empty, set the value
                                    if (value === '' || validPercentageRegex.test(value)) {
                                        setDateSampling(value);
                                    }
                                }}
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                    step: 0.1,  // Allows increments of 0.1 for one decimal place
                                    inputMode: 'decimal',  // Optimizes for decimal input on mobile devices
                                    pattern: "^\d{1,2}(\.\d{0,1})?$|^100$"  // Allows up to one decimal place, and exactly 100
                                }}
                            />
                        </Grid>
                    </Paper>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button disabled={submitLoading} variant="contained" color="primary" onClick={handleCloseAddModal}>
                            Cancel
                        </Button>
                        <LoadingButton loading={submitLoading} variant="contained" color="primary" sx={{ ml: 2 }}
                            onClick={handleChangeSamplingRatio}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Page>
    )
}

export default AssessorList
