import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';

export default function MultiSelectLearners({
  required,
  label,
  value,
  onChange,
  renderValue,
  data,
  lableId,
  id,
  disabled,
  onOpen
}) {
  return (
    <FormControl required={required} fullWidth variant="outlined">
      <InputLabel id={lableId}>{label}</InputLabel>
      <Select
        onOpen={onOpen}
        disabled={disabled}
        labelId={lableId}
        id={id}
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={renderValue}
      >
        {data.map((val, index) => {
          return (
            <MenuItem key={index} value={val}>
              {val.full_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
