import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination,
  CardContent,
  TextField,
  Grid,
  Alert,
  Snackbar,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { api } from '../../Api';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../sections/@dashboard/user';
import { ArrowBack } from '@mui/icons-material';

export default function AddQualifications() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'number', label: 'Number', alignRight: false },
    { id: 'Action', label: 'Action', alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add'];

  const [parentQualifications, setParentQualifications] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [user, setUser] = useState();
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const isUserNotFound = parentQualifications.length === 0;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (user?.id) handleSuperadminAddQualification();
  }, [user]);

  useEffect(() => {
    checkRender();
    getParentQualifications();
  }, []);

  useEffect(() => {
    if (search.length == 0) {
      parentQualifications.length = 0;
      getParentQualifications(page);
    }
  }, [search]);

  function checkRender() {
    let check = 'users';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  const handlePageChange = (event, value) => {
    parentQualifications.length = 0;
    setPage(value);
    getParentQualifications(value);
  };

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    border: '2px solid',
    borderColor: 'primary.main',
    overflow: 'hidden'
  };

  const boxStyle = {
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // alignSelf: 'center',
    maxWidth: '60%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: 'primary.main',
    borderRadius: 1
  };

  function getParentQualifications(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    const params = new URLSearchParams();
    search.length > 0 && params.append('search', search);
    page && params.append('page', page);
    params.append('company_admin_id', user?.company_admin_id);
    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_parent_qualifications}?${params.toString()}`;
    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setParentQualifications(result.data);
          result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
        } else {
          setParentQualifications([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const handleSuperadminAddQualification = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('id', user.id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.superadmin_qualification_transfer, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        setloading(false);
        if (status === 201) {
          setSnackbarMessage(result.message || 'Add successful!');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(result.error || 'Add failed.');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/dashboard/qualifications');
        }, 1000);
      })
      .catch((error) => {
        console.error('error', error);
        setloading(false);
        setSnackbarMessage('An error occurred while adding.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
            Add Qualifications/Subjects
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/qualifications/addQualifications/form"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add your Own
          </Button>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={'Search Qualifications/ Subjects from the list'}
              onEnter={() => {
                setsearchLoading(true);
                parentQualifications.length = 0;
                getParentQualifications();
              }}
            />
            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getParentQualifications(1);
              }}
              className="width_full_status"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={parentQualifications.length} />
                <TableBody>
                  {parentQualifications.map((row) => {
                    const { id, sub_title, sub_number, registered_leaners_count } = row;
                    return (
                      <TableRow hover onClick={() => {}} key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left" style={{ maxWidth: 500 }}>
                          <Typography>{sub_title}</Typography>
                        </TableCell>
                        <TableCell noWrap align="left" style={{ maxWidth: 140 }}>
                          <Typography noWrap>{sub_number}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Button
                            size="small"
                            onClick={() => setUser(row)}
                            style={{
                              justifyContent: 'flex-start',
                              marginTop: 5,
                              width: 85,
                              alignSelf: 'center'
                            }}
                            variant="outlined"
                            component={RouterLink}
                            to="#"
                            startIcon={<Iconify color={'#000000'} icon="ic:round-plus" />}
                          >
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound message={'No Qualification Found'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
