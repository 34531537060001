import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';
import MultiSelectReference from 'src/components/MultiSelectReference';

const AdminForm = ({ formData, setFormData, handleSubmit, editable, error, setAlert, loading }) => {
  const handleChange = (e) => {
    setAlert({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      qualification: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const handleSelectChangeReference = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      reference: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const [qualifications, setqualifications] = useState([]);

  function getQualifications() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${false}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setqualifications(result.data);
        } else {
          setqualifications([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  const [references, setrefrences] = useState([]);

  function getRefrences() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_references}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setrefrences(result.data);
        } else {
          setrefrences([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  const [types, setTypes] = useState([]);

  function getTypes() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_types}?type=view_only_admin`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setTypes(result.data);
          if (formData.viewOnly) {
            setFormData((prevData) => ({
              ...prevData,
              ['viewOnly']: result?.data?.filter((item) => item?.id === formData?.viewOnly?.id)[0]
            }));
          }
        } else {
          setTypes([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  useEffect(() => {
    getQualifications();
    getRefrences();
    getTypes();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label=" Middle Name"
            variant="outlined"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Surname"
            variant="outlined"
            required
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="email"
            label="Email ID"
            variant="outlined"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Contact #"
            variant="outlined"
            name="contact"
            type='number'
            value={formData.contact}
            onChange={handleChange}
          />
        </Grid>

        {error?.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error?.message}
            </Alert>
          </Grid>
        )}
        {editable && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleSubmit(formData);
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AdminForm;