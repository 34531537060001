import { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { TotalBusinesses } from '../sections/@dashboard/app';
import { api } from 'src/Api';

export default function DashboardApp() {
  const [list, setlist] = useState(false);
  const [loading, setloading] = useState(true);

  function getDashboard() {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(api.getDashboard, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to fetch dashboard data');
        }
      })
      .then((result_) => {
        const result = JSON.parse(result_);
        setlist(result.data);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <Page title={`Dashboard | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Typography p={2} variant="h4">
            Dashboard
          </Typography>

          <Grid item xs={12} sm={12} md={12}>
            <TotalBusinesses total={list} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
