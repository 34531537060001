import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  TableHead,
  TableSortLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { api } from '../../Api';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

export default function ExistingQualificationsList() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('user'));

  // The modified TABLE_HEAD array
  const initialTableHead = [
    { id: 'title', label: t('Title'), alignRight: false },
    { id: 'number', label: t('Number'), alignRight: false },
    { id: 'registered_learners', label: userData?.role?.id == 7 ? 'Learners Assigned' : 'Registered Learners', alignRight: false },
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];

  const [qualifications, setqualifications] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc'); // Sorting order
  const [orderBy, setOrderBy] = useState(''); // Column to sort by
  const [tableHead, setTableHead] = useState(initialTableHead);


  const isUserNotFound = qualifications.length === 0;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.role?.id === 3) {
      const updatedTableHead = [...tableHead];
      // Remove the last object
      updatedTableHead.pop();
      // Add a new object with the same key but a different value
      updatedTableHead.push({ id: 'progress', label: 'Progress', alignRight: false });

      setTableHead(updatedTableHead);
    }
    else if (user?.role?.id == 4 || user.role.id == 5 || user.role.id == 7) {
      const updatedTableHead = [...tableHead];
      // Add a new object with the same key but a different value
      updatedTableHead.push({ id: 'resource material', label: 'Resource Material', alignRight: false });
      user?.role?.id == 4 && updatedTableHead.push({ id: 'iqa report', label: 'Iqa Report', alignRight: false });
      setTableHead(updatedTableHead);
    }
  }, []);

  useEffect(() => {
    getQualifications(page);
  }, [page, order, orderBy]);

  useEffect(() => {
    checkRender();
  }, []);

  useEffect(() => {
    if (search.length == 0) {
      qualifications.length = 0;
      getQualifications(page);
    }
  }, [search]);

  const handleRequestSort = (property) => {
    if (property == 'registered_learners') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const sortedQualifications = [...qualifications].sort((a, b) => {
    if (orderBy) {
      if (order === 'asc') {
        return a['registered_leaners_count'] - b['registered_leaners_count'];
      } else {
        return b['registered_leaners_count'] - a['registered_leaners_count'];
      }
    }
    return 0;
  });

  function checkRender() {
    let check = 'qualifications';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  const handlePageChange = (event, value) => {
    qualifications.length = 0;
    setPage(value);
    getQualifications(value);
  };

  function getQualifications(page) {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    const queryParams = new URLSearchParams();
    search.length > 0 && queryParams.append('search', search);
    page && queryParams.append('page', page);
    userData.role.id !== 1 && queryParams.append('company_admin_id', user?.company_admin_id);

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV3}?${queryParams.toString()}`;
    const url2 = `${api.get_superadmin_qualifications}?${queryParams.toString()}`;
    fetch(userData.role.id == 1 ? url2 : url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setqualifications(result.data);
          result?.count ? setpagesCount(Math.ceil(result?.count / 20)) : setpagesCount(1);
        } else {
          setqualifications([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  return (
    <Page title={`Qualifications | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Existing Qualifications/Subjects
          </Typography>
          {permission.includes('add') && (
            <Button
              variant="contained"
              component={RouterLink}
              to={userData.role.id == 1 ? "/dashboard/qualifications/addQualifications/form" : "/dashboard/qualifications/addQualifications"}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Qualifications/Subjects
            </Button>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              width={'90%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              className="width_full_property"
              placeholder={'Search Qualification/ Subject by Title or Number'}
              onEnter={() => {
                setsearchLoading(true);
                qualifications.length = 0;
                getQualifications();
              }}
            />
            <LoadingButton
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getQualifications(1);
              }}
              className="width_full_status"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHead.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ paddingLeft: headCell.paddingLeft && headCell.paddingLeft }}
                      >
                        {headCell.label === 'Title' || headCell.label === 'Number' || headCell.label === 'Progress' || headCell.label === 'Resource Material' || headCell.label === 'Iqa Report' ? (
                          <span>{headCell.label}</span>
                        ) : (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            <span>{headCell.label}</span>
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedQualifications.map((row, index) => {
                    const { id, sub_title, sub_number, registered_leaners_count, user_qualifications } = row;
                    const user = JSON.parse(localStorage.getItem('user'));
                    return (
                      <TableRow hover onClick={() => { }} key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left" style={{ maxWidth: 500 }}>
                          {(user.role.id == 4 || user.role.id == 5 || user.role.id == 7) ?
                            <Typography>{sub_title}</Typography>
                            :
                            <RouterLink
                              className="routerLink"
                              to={user.role.id == 3 ? `/dashboard/qualifications/qualificationDetails/${user.id}/${id}` : `/dashboard/qualifications/addQualifications/form/${id}`}
                            >
                              <Typography>{sub_title}</Typography>
                            </RouterLink>
                          }
                        </TableCell>

                        <TableCell noWrap align="left" style={{ maxWidth: 140 }}>
                          <Typography noWrap>{sub_number}</Typography>
                        </TableCell>

                        {user.role.id == 3 ?
                          <TableCell align="left" style={{ maxWidth: 80 }}>
                            <Typography>{user_qualifications?.progress}</Typography>
                          </TableCell>
                          :
                          <TableCell align="left" style={{ maxWidth: 80 }}>
                            {registered_leaners_count == 0 ? (
                              <Typography>{registered_leaners_count}</Typography>
                            ) : (
                              <>
                                {
                                  user.role.id == 1 ?
                                    <Typography>{registered_leaners_count}</Typography>
                                    :
                                    <RouterLink
                                      className="routerLink"
                                      to={(user.role.id == 4 || user.role.id == 5 || user.role.id == 7) ? `/dashboard/qualifications/registered_learners/${id}` : `/dashboard/users/registered_learners/${id}`}
                                    >
                                      <Typography>{registered_leaners_count}</Typography>
                                    </RouterLink>
                                }
                              </>
                            )}
                          </TableCell>
                        }

                        {
                          (user.role.id == 4 || user.role.id == 5 || user.role.id == 7) &&
                          <TableCell noWrap align="left" style={{ maxWidth: 140 }}>
                            <Typography noWrap>
                              <Button
                                sx={{ fontSize: 12 }}
                                variant='contained'
                                component={RouterLink}
                                to={`/dashboard/qualifications/resource_materials/${user.id}/${id}`}
                              >
                                View
                              </Button>
                            </Typography>
                          </TableCell>
                        }
                        {
                          user?.role?.id == 4 &&
                          <TableCell noWrap align="left" style={{ maxWidth: 140 }}>
                            <Typography noWrap>
                              <Button
                                sx={{ fontSize: 12 }}
                                variant='contained'
                                component={RouterLink}
                                // to={`/dashboard/qualifications/resource_materials/${user.id}/${id}`}
                                to={`/dashboard/qualifications/iq_reports/${user.id}/${id}`}
                                state={{ headerShow: true }}
                              >
                                View
                              </Button>
                            </Typography>
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound message={'No Existing Qualification Found'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>

              {loading && !searchLoading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}

            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
