import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Card,
    Snackbar,
    Alert,
    Typography,
    IconButton,
    Stack,
    CircularProgress,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Scrollbar from '../../components/Scrollbar';
import Page from 'src/components/Page';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';

export default function ResourceForm() {
    const navigate = useNavigate();
    const { id } = useParams(); // Assuming ID is passed in the URL for editing
    const [folderName, setFolderName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isGetLoading, setGetLoading] = useState(false);
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (id) {
            getResourceDataEdit()
        }
    }, [id])


    const getResourceDataEdit = () => {
        setGetLoading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_resource_materail}?id=${id}&company_admin_id=${user?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setFolderName(result.data[0].folder_name || '');
                    setGetLoading(false)
                } else {
                    setGetLoading(false)
                }
            })
            .catch((error) => { console.log('error', error); setGetLoading(false) });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = () => {
        setIsSaveLoading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        var formdata = new FormData();

        folderName && formdata.append('folder_name', folderName);
        // folderName && id && formdata.append('id', id);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(id ? `${api.update_resource_materail}?id=${id}` : api.save_resource_materail, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setIsSaveLoading(false);
                    setTimeout(() => {
                        navigate('/dashboard/resource_materials');
                    }, 1000);
                } else {
                    setIsSaveLoading(false);
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setIsSaveLoading(false);
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleDelete = () => {
        setDeleteLoading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.delete_resource_materail}?id=${id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setDeleteLoading(false);
                    setTimeout(() => {
                        navigate('/dashboard/resource_materials');
                    }, 1000);
                } else {
                    setDeleteLoading(false);
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setDeleteLoading(false);
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    return (
        <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
                        {id ? "Edit Resource Material" : "Add Resource Material"}
                    </Typography>
                    {id && <Button
                        sx={{ backgroundColor: 'red' }}
                        onClick={() => handleDelete(id)}
                        variant="contained"
                        component={RouterLink}
                        to="#"
                        startIcon={<Iconify icon="ic:baseline-delete" />}
                    >
                        {isDeleteLoading ? (
                            <CircularProgress size={20} sx={{ color: '#ffffff' }} />
                        ) : (
                            'Delete'
                        )}
                    </Button>}
                </Stack>
                <Card>
                    {isGetLoading ? (
                        <Box
                            py={5}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Scrollbar>
                            <Box p={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Folder Name"
                                            value={folderName}
                                            onChange={(e) => setFolderName(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box textAlign="right">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                {isSaveLoading ? (
                                                    <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                                                ) : 'Submit'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Scrollbar>
                    )}
                </Card>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </Page >
    );
}
