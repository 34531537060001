import { Box, Checkbox, TableRow, TableCell, TableHead } from '@mui/material';

export default function UserListHead({ headLabel, onSelectAllClick, textStyle, allSelect }) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            style={{ paddingLeft: headCell.paddingLeft && headCell.paddingLeft }}
            padding="normal"
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
          >
            <span style={textStyle}>{headCell.label}</span>
            {headCell.label == 'Select' && (
              <Checkbox checked={allSelect} onChange={onSelectAllClick} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
