export const mainurl = process.env.REACT_APP_MAINURL;
export const chatUrl = process.env.REACT_APP_CHATURL;
// export const URL = "https://ezsapi.dotserviz.com/api/";

// export const api = {
//   login: `${mainurl}v1/users/login`,
//   register: `${mainurl}register`,
//   getUsers: `${mainurl}v1/users/get_users`,
//   getInqueries: `${mainurl}get_inqueries`,
//   getFeedback: `${mainurl}get_product_feedback`,
//   getStatus: `${mainurl}get_inquiry_status`,

//   get_wallet: `${mainurl}v1/wallets/get_wallet_by_admin`,
//   get_wallet_for_admin: `${mainurl}get_wallet_for_admin`,
//   get_pending_requests: `${mainurl}get_pending_requests`,

//   get_agents: `${mainurl}get_realtor`,
//   getCompany: `${mainurl}get_companies`,
//   getTypes: `${mainurl}get_property_types`,
//   getSaleType: `${mainurl}get_sale_type_method`,
//   getConstructionStatus: `${mainurl}get_construction_status`,
//   getAreaUnit: `${mainurl}get_area_unit`,
//   getSortBy: `${mainurl}get_sortby`,
//   UpdateUserStatus: `${mainurl}company_user_status_update`,
//   getBusinesses: `${mainurl}get_property_external`,
//   getOffers: `${mainurl}getbusinessoffers`,
//   reset_password: `${mainurl}v1/users/change_password`,
//   sendNotification: `${mainurl}sendnotification`,
//   approveProperty: `${mainurl}approved_property`,
//   approveRealtor: `${mainurl}approved_realtor`,
//   approveOffers: `${mainurl}approve-businessoffers`,
//   getbusinessoffers: `${mainurl}getbusinessoffers`,
//   createAgent: `${mainurl}register`, //get-dashboard
//   getDashboard: `${mainurl}v1/admin_dashboard`, //get-dashboard,
//   getRequests: `${mainurl}get_inquiry`,
//   getDocuments: `${mainurl}get_documents`,
//   uploadDocument: `${mainurl}save_documents`,
//   getHelp: `${mainurl}get-help`,
//   saveHelp: `${mainurl}save-help`,
//   getHelpCateogries: `${mainurl}get-categories`,
//   getDocumentType: `${mainurl}get-document-type`,
//   markasread: `${mainurl}editrequest`,
//   getPosts: `${mainurl}get_posts`,
//   postApprove: `${mainurl}update_posts_status`,
//   sendEmail: `${mainurl}sendemail`,
//   allseen: `${mainurl}all_seen`,
//   //ROLE PERMISSIONS
//   getRoles: `${mainurl}v1/role_permission/get_role_permission_and_types`,
//   get_permissions: `${mainurl}v1/role_permission/get_permissions`,
//   roles: `${mainurl}v1/role_permission/roles`,
//   add_role_with_per_and_type: `${mainurl}v1/role_permission/add_role_with_per_and_type`,
//   delete_roles: `${mainurl}v1/role_permission/delete_roles`,
//   saveProfile: `${mainurl}allowed_realtors_edit`,
//   updateProfile: `${mainurl}v1/users/update_profile`,
//   replyInqueries: `${mainurl}inquiry_reply_email`,
//   inquiresStatusChange: `${mainurl}inquiry_status_change`,
//   getRoleNames: `${mainurl}v1/role_permission/get_roles`,
//   assignRole: `${mainurl}v1/role_permission/assign_role`,
//   status: `${mainurl}status`,
//   change_wallet_status: `${mainurl}change_wallet_status`,
//   reservation_status_change: `${mainurl}reservation_status_change`,
//   terminateadmin: `${mainurl}team_status_change`,
//   resend_access: `${mainurl}resend_access`,
//   admin_logout: `${mainurl}admin_logout`,
//   user_roles: `${mainurl}user_roles`,
//   //SideBar and Permission
//   sideBar: `${mainurl}v1/role_permission/get_role_permission_by_user`,
//   //team_status_change
//   getDocumentType: `${mainurl}get-document-type`,
//   // get-document-type
//   //CITY AND STATES SEARCHABLE
//   getcityandstates: `${mainurl}getcityandstates`,
//   //Business Category
//   getPropertyCategories: `${mainurl}get_categories`,
//   // offer freq nd types
//   getoffertype: `${mainurl}getoffertype`,
//   getofferfrequency: `${mainurl}getofferfrequency`, //get-categories
//   getUsersdocuments: `${mainurl}accept_user_list_documents`,
//   getCategories: `${mainurl}get-categories`,
//   get_event_logs: `${mainurl}get_event_logs`,
//   getbusinessesbyid: `${mainurl}getbusinessesbyid`,
//   getFeatures: `${mainurl}get_about`,
//   delete_report_pictures: `${mainurl}delete_report_pictures`,
//   user_terminate: `${mainurl}user_terminate`,
//   property_realtor_change: `${mainurl}property_realtor_change`,
//   save_property_history: `${mainurl}save_property_history`,
//   company_realtor_edit: `${mainurl}company_realtor_edit`,
//   get_property_types: `${mainurl}get_property_types`,
//   get_construction_status: `${mainurl}get_construction_status`,
//   upload_property_images: `${mainurl}upload_property_images`,
//   save_properties: `${mainurl}save_properties`,
//   upload_property_documents: `${mainurl}upload_property_documents`,
//   get_company_contacts: `${mainurl}get_company_contacts`,
//   save_company_contacts: `${mainurl}save_company_contacts`,
//   delete_company_contacts: `${mainurl}delete_company_contacts`,
//   delete_documents: `${mainurl}delete_documents`,
//   save_property_comments: `${mainurl}save_property_comments`,
//   get_property_comments: `${mainurl}get_property_comments`,
//   delete_property_comments: `${mainurl}delete_property_comments`,
//   allowed_realtors: `${mainurl}allowed_realtors_edit`,
//   edit_property_documents: `${mainurl}edit_property_documents`,
//   updateforgotpassword: `${mainurl}v1/users/change_forgot_password`,
//   sendforgotpasswordweb: `${mainurl}sendforgotpasswordweb`,
//   send_forgot_password: `${mainurl}v1/users/forgot_password`,
//   check_code_validity: `${mainurl}check_code_validity`,
//   getProperties: `${mainurl}get_listings`,
//   // get_property_status: `${mainurl}get_property_status`, //update_listing_status
//   update_listing_status: `${mainurl}update_listing_status`, //update_listing_status
//   get_reservation_history: `${mainurl}get_reservation_history`, //update_listing_status
//   delete_reservation: `${mainurl}delete_reservation`,
//   // get_transactions: `${mainurl}get_transactions`, //update_listing_status
//   otp_verify: `${mainurl}v1/users/verify_otp`,
//   // BRAND
//   get_product_brand: `${mainurl}v1/products/get_brands`,
//   add_product_brand: `${mainurl}v1/products/add_product_brand`,
//   edit_product_brand: `${mainurl}v1/products/edit_product_brand`,
//   delete_product_brand: `${mainurl}v1/products/delete_product_brand`,
//   // COLOR
//   get_color: `${mainurl}v1/products/get_colors`,
//   add_color: `${mainurl}v1/products/add_product_color`,
//   edit_color: `${mainurl}v1/products/edit_product_color`,
//   delete_color: `${mainurl}v1/products/delete_product_color`,
//   // SIZES
//   get_sizes: `${mainurl}v1/products/get_sizes`,
//   add_sizes: `${mainurl}v1/products/add_product_size`,
//   edit_sizes: `${mainurl}v1/products/edit_product_size`,
//   delete_sizes: `${mainurl}v1/products/delete_product_size`,
//   // CONDITION
//   get_conditions: `${mainurl}v1/products/get_conditions`,
//   add_conditions: `${mainurl}v1/products/add_product_condition`,
//   edit_conditions: `${mainurl}v1/products/edit_product_condition`,
//   delete_conditions: `${mainurl}v1/products/delete_product_condition`,
//   // CATEGORIES
//   get_categories: `${mainurl}v1/products/get_categories_for_admin`,
//   get_parent_categories: `${mainurl}v1/products/get_categories_for_dropdown`,
//   add_categories: `${mainurl}v1/products/add_product_category`,
//   edit_categories: `${mainurl}v1/products/edit_product_category`,
//   delete_categories: `${mainurl}v1/products/delete_product_category`,
//   // PRODUCTS
//   get_products: `${mainurl}v1/products/get_products`,
//   get_product_status: `${mainurl}v1/products/get_product_status`,
//   change_product_status: `${mainurl}v1/products/action_product_status`,
//   get_product_offers: `${mainurl}v1/products/get_product_offers_by_product`,
//   // ORDERS
//   get_orders: `${mainurl}v1/products/get_orders`,
//   //USERS
//   get_user_status: `${mainurl}v1/users/get_user_statuses`,
//   update_user_status: `${mainurl}v1/users/action_user_status`,
//   change_password_by_admin: `${mainurl}v1/users/change_password_by_admin`,
//   //FAQ CATEGORY
//   get_faq_category: `${mainurl}v1/faqs/get_faqs_categories`,
//   add_faq_category: `${mainurl}v1/faqs/add_faqs_categories`,
//   edit_faq_category: `${mainurl}v1/faqs/edit_faqs_categories`,
//   delete_faq_category: `${mainurl}v1/faqs/delete_faqs_categories`,
//   //TRANSACTIONS
//   get_transactions: `${mainurl}v1/wallets/get_wallet_details`,
//   get_transactions_status: `${mainurl}v1/wallets/get_wallet_status`,
//   //FAQS
//   get_faqs: `${mainurl}v1/faqs/get_faqs`,
//   add_faq: `${mainurl}v1/faqs/add_faqs`,
//   edit_faq: `${mainurl}v1/faqs/edit_faqs`,
//   delete_faq: `${mainurl}v1/faqs/delete_faqs`,
//   //FEEDBACK & BUGS
//   get_feedback: `${mainurl}v1/feedback/get_feedbacks`,
//   reply_feedback: `${mainurl}v1/feedback/feedback_reply_email`,
//   get_bugs: `${mainurl}v1/feedback/get_feedbacks`,
//   reply_bug: `${mainurl}v1/feedback/feedback_reply_email`,
//   bug_status_change: `${mainurl}v1/feedback/feedback_status_change`,
//   get_bug_status: `${mainurl}v1/feedback/get_bug_status`,
//   //REQUEST WITHDRAWAL
//   get_withdrawal_request: `${mainurl}v1/wallets/get_requests`,
//   get_withdraw_request_status: `${mainurl}v1/wallets/get_withdraw_request_status`,
//   request_status_change: `${mainurl}v1/wallets/request_status_change`

// };
export const api = {
  login: `${mainurl}v1/users/login`,
  register: `${mainurl}register`,
  getUsers: `${mainurl}v1/users/get_users`,
  getUsers_V1: `${mainurl}v1/users/get_users_V1`,
  get_assessors_list: `${mainurl}v1/users/get_assessors_list`,
  getInqueries: `${mainurl}get_inqueries`,
  getFeedback: `${mainurl}get_product_feedback`,
  getStatus: `${mainurl}get_inquiry_status`,

  get_wallet: `${mainurl}v1/wallets/get_wallet_by_admin`,
  get_wallet_for_admin: `${mainurl}get_wallet_for_admin`,
  get_pending_requests: `${mainurl}get_pending_requests`,

  get_agents: `${mainurl}get_realtor`,
  getCompany: `${mainurl}get_companies`,
  getTypes: `${mainurl}get_property_types`,
  getSaleType: `${mainurl}get_sale_type_method`,
  getConstructionStatus: `${mainurl}get_construction_status`,
  getAreaUnit: `${mainurl}get_area_unit`,
  getSortBy: `${mainurl}get_sortby`,
  UpdateUserStatus: `${mainurl}company_user_status_update`,
  getBusinesses: `${mainurl}get_property_external`,
  getOffers: `${mainurl}getbusinessoffers`,
  reset_password: `${mainurl}v1/users/change_password`,
  sendNotification: `${mainurl}sendnotification`,
  approveProperty: `${mainurl}approved_property`,
  approveRealtor: `${mainurl}approved_realtor`,
  approveOffers: `${mainurl}approve-businessoffers`,
  getbusinessoffers: `${mainurl}getbusinessoffers`,
  createAgent: `${mainurl}register`, //get-dashboard
  getDashboard: `${mainurl}v1/admin_dashboard`, //get-dashboard,
  getRequests: `${mainurl}get_inquiry`,
  getDocuments: `${mainurl}get_documents`,
  uploadDocument: `${mainurl}save_documents`,
  getHelp: `${mainurl}get-help`,
  saveHelp: `${mainurl}save-help`,
  getHelpCateogries: `${mainurl}get-categories`,
  getDocumentType: `${mainurl}get-document-type`,
  markasread: `${mainurl}editrequest`,
  getPosts: `${mainurl}get_posts`,
  postApprove: `${mainurl}update_posts_status`,
  sendEmail: `${mainurl}sendemail`,
  allseen: `${mainurl}all_seen`,
  //ROLE PERMISSIONS
  getRoles: `${mainurl}v1/role_permission/get_role_permission_and_types`,
  get_permissions: `${mainurl}v1/role_permission/get_permissions`,
  roles: `${mainurl}v1/role_permission/roles`,
  add_role_with_per_and_type: `${mainurl}v1/role_permission/add_role_with_per_and_type`,
  delete_roles: `${mainurl}v1/role_permission/delete_roles`,
  saveProfile: `${mainurl}allowed_realtors_edit`,
  updateProfile: `${mainurl}v1/users/update_profile`,
  replyInqueries: `${mainurl}inquiry_reply_email`,
  inquiresStatusChange: `${mainurl}inquiry_status_change`,
  getRoleNames: `${mainurl}v1/role_permission/get_roles`,
  assignRole: `${mainurl}v1/role_permission/assign_role`,
  status: `${mainurl}status`,
  change_wallet_status: `${mainurl}change_wallet_status`,
  reservation_status_change: `${mainurl}reservation_status_change`,
  terminateadmin: `${mainurl}team_status_change`,
  resend_access: `${mainurl}resend_access`,
  admin_logout: `${mainurl}admin_logout`,
  user_roles: `${mainurl}user_roles`,
  //SideBar and Permission
  sideBar: `${mainurl}v1/role_permission/get_role_permission_by_user`,
  //team_status_change
  getDocumentType: `${mainurl}get-document-type`,
  // get-document-type
  //CITY AND STATES SEARCHABLE
  getcityandstates: `${mainurl}getcityandstates`,
  //Business Category
  getPropertyCategories: `${mainurl}get_categories`,
  // offer freq nd types
  getoffertype: `${mainurl}getoffertype`,
  getofferfrequency: `${mainurl}getofferfrequency`, //get-categories
  getUsersdocuments: `${mainurl}accept_user_list_documents`,
  getCategories: `${mainurl}get-categories`,
  get_event_logs: `${mainurl}get_event_logs`,
  getbusinessesbyid: `${mainurl}getbusinessesbyid`,
  getFeatures: `${mainurl}get_about`,
  delete_report_pictures: `${mainurl}delete_report_pictures`,
  user_terminate: `${mainurl}user_terminate`,
  property_realtor_change: `${mainurl}property_realtor_change`,
  save_property_history: `${mainurl}save_property_history`,
  company_realtor_edit: `${mainurl}company_realtor_edit`,
  get_property_types: `${mainurl}get_property_types`,
  get_construction_status: `${mainurl}get_construction_status`,
  upload_property_images: `${mainurl}upload_property_images`,
  save_properties: `${mainurl}save_properties`,
  upload_property_documents: `${mainurl}upload_property_documents`,
  get_company_contacts: `${mainurl}get_company_contacts`,
  save_company_contacts: `${mainurl}save_company_contacts`,
  delete_company_contacts: `${mainurl}delete_company_contacts`,
  delete_documents: `${mainurl}delete_documents`,
  save_property_comments: `${mainurl}save_property_comments`,
  get_property_comments: `${mainurl}get_property_comments`,
  delete_property_comments: `${mainurl}delete_property_comments`,
  allowed_realtors: `${mainurl}allowed_realtors_edit`,
  edit_property_documents: `${mainurl}edit_property_documents`,
  updateforgotpassword: `${mainurl}v1/users/change_forgot_password`,
  sendforgotpasswordweb: `${mainurl}sendforgotpasswordweb`,
  send_forgot_password: `${mainurl}v1/users/forgot_password`,
  check_code_validity: `${mainurl}check_code_validity`,
  getProperties: `${mainurl}get_listings`,
  // get_property_status: `${mainurl}get_property_status`, //update_listing_status
  update_listing_status: `${mainurl}update_listing_status`, //update_listing_status
  get_reservation_history: `${mainurl}get_reservation_history`, //update_listing_status
  delete_reservation: `${mainurl}delete_reservation`,
  // get_transactions: `${mainurl}get_transactions`, //update_listing_status
  otp_verify: `${mainurl}v1/users/verify_forgot_password`,
  // BRAND
  get_product_brand: `${mainurl}v1/products/get_brands`,
  add_product_brand: `${mainurl}v1/products/add_product_brand`,
  edit_product_brand: `${mainurl}v1/products/edit_product_brand`,
  delete_product_brand: `${mainurl}v1/products/delete_product_brand`,
  // COLOR
  get_color: `${mainurl}v1/products/get_colors`,
  add_color: `${mainurl}v1/products/add_product_color`,
  edit_color: `${mainurl}v1/products/edit_product_color`,
  delete_color: `${mainurl}v1/products/delete_product_color`,
  // SIZES
  get_sizes: `${mainurl}v1/products/get_sizes`,
  add_sizes: `${mainurl}v1/products/add_product_size`,
  edit_sizes: `${mainurl}v1/products/edit_product_size`,
  delete_sizes: `${mainurl}v1/products/delete_product_size`,
  // CONDITION
  get_conditions: `${mainurl}v1/products/get_conditions`,
  add_conditions: `${mainurl}v1/products/add_product_condition`,
  edit_conditions: `${mainurl}v1/products/edit_product_condition`,
  delete_conditions: `${mainurl}v1/products/delete_product_condition`,
  // CATEGORIES
  get_categories: `${mainurl}v1/products/get_categories_for_admin`,
  get_parent_categories: `${mainurl}v1/products/get_categories_for_dropdown`,
  add_categories: `${mainurl}v1/products/add_product_category`,
  edit_categories: `${mainurl}v1/products/edit_product_category`,
  delete_categories: `${mainurl}v1/products/delete_product_category`,
  // PRODUCTS
  get_products: `${mainurl}v1/products/get_products`,
  get_product_status: `${mainurl}v1/products/get_product_status`,
  change_product_status: `${mainurl}v1/products/action_product_status`,
  get_product_offers: `${mainurl}v1/products/get_product_offers_by_product`,
  // ORDERS
  get_orders: `${mainurl}v1/products/get_orders`,
  //USERS
  get_user_status: `${mainurl}v1/users/get_user_statuses`,
  update_user_status: `${mainurl}v1/users/action_user_status`,
  change_password_by_admin: `${mainurl}v1/users/change_password_by_admin`,
  get_user_roles: `${mainurl}v1/users/get_user_roles`,
  get_assessors: `${mainurl}v1/users/get_assessors`,
  get_assessorsV1: `${mainurl}v1/users/get_assessorsV1`,
  get_iqas: `${mainurl}v1/users/get_iqas`,
  get_iqasV1: `${mainurl}v1/users/get_iqasV1`,
  get_iqasV2: `${mainurl}v1/users/get_iqasV2`,
  create_user: `${mainurl}v1/users/create_user`,
  bulk_edit: `${mainurl}v1/users/bulk_user_edit`,

  get_assessorsV2: `${mainurl}v1/users/get_assessorsV2`,
  //FAQ CATEGORY
  get_faq_category: `${mainurl}v1/faqs/get_faqs_categories`,
  add_faq_category: `${mainurl}v1/faqs/add_faqs_categories`,
  edit_faq_category: `${mainurl}v1/faqs/edit_faqs_categories`,
  delete_faq_category: `${mainurl}v1/faqs/delete_faqs_categories`,
  //TRANSACTIONS
  get_transactions: `${mainurl}v1/wallets/get_wallet_details`,
  get_transactions_status: `${mainurl}v1/wallets/get_wallet_status`,
  //FAQS
  get_faqs: `${mainurl}v1/faqs/get_faqs`,
  add_faq: `${mainurl}v1/faqs/add_faqs`,
  edit_faq: `${mainurl}v1/faqs/edit_faqs`,
  delete_faq: `${mainurl}v1/faqs/delete_faqs`,
  //FEEDBACK & BUGS
  get_feedback: `${mainurl}v1/feedback/get_feedbacks`,
  reply_feedback: `${mainurl}v1/feedback/feedback_reply_email`,
  get_bugs: `${mainurl}v1/feedback/get_feedbacks`,
  reply_bug: `${mainurl}v1/feedback/feedback_reply_email`,
  bug_status_change: `${mainurl}v1/feedback/feedback_status_change`,
  get_bug_status: `${mainurl}v1/feedback/get_bug_status`,
  //REQUEST WITHDRAWAL
  get_withdrawal_request: `${mainurl}v1/wallets/get_requests`,
  get_withdraw_request_status: `${mainurl}v1/wallets/get_withdraw_request_status`,
  request_status_change: `${mainurl}v1/wallets/request_status_change`,

  // GET EXISTING QUALIFICATIONS
  get_qualifications: `${mainurl}v1/qualifications/get_qualifications`,
  get_qualificationsV1: `${mainurl}v1/qualifications/get_qualificationsV1`,
  get_qualificationsV2: `${mainurl}v1/qualifications/get_qualificationsV2`,
  get_qualificationsV3: `${mainurl}v1/qualifications/get_qualificationsV3`,
  get_qualifications_by_id: `${mainurl}v1/qualifications/get_qualification_by_id`,
  get_parent_qualifications: `${mainurl}v1/qualifications/get_parent_qualifications`,
  save_qualification: `${mainurl}v1/qualifications/save_qualification`,
  update_qualification: `${mainurl}v1/qualifications/update_qualification`,
  superadmin_qualification_transfer: `${mainurl}v1/qualifications/superadmin_qualification_transfer`,
  delete_qualification: `${mainurl}v1/qualifications/delete_qualification`,

  //GET QUALIFICATION
  get_references: `${mainurl}v1/users/get_references`,
  get_assigned_qualifications: `${mainurl}v1/qualifications/get_assigned_qualifications`,
  get_submissions: `${mainurl}v1/qualifications/get_qualification_submissions`,

  // get_qualification_submissions_V2
  get_qualification_submissions_V2: `${mainurl}v1/qualifications/get_qualification_submissions_V2`,
  get_qualification_submissions_V3: `${mainurl}v1/qualifications/get_qualification_submissions_V3`,

  get_evidence: `${mainurl}v1/qualifications/get_submissions`,
  get_assessor_feedback: `${mainurl}v1/qualifications/get_assessor_feedback`,
  get_iqa_comment: `${mainurl}v1/qualifications/get_iqa_comment`,
  get_documents: `${mainurl}v1/qualifications/get_documents`,
  //GET TYPES
  get_types: `${mainurl}v1/get_types`,
  get_learners: `${mainurl}v1/users/get_learners`,
  //ASSESSORS
  get_assigned_learners: `${mainurl}v1/users/get_assigned_learners`,
  //USER BY ID
  get_user_by_id: `${mainurl}v1/users/get_user_detail`,
  get_user_by_id_V1: `${mainurl}v1/users/get_user_detail_V1`,
  

  //get_summary_user_qualification
  get_summary_user_qualification: `${mainurl}v1/users/get_summary_user_qualification`,
  get_qualification_bars: `${mainurl}v1/users/get_qualification_bars`,

  //RESOURCE MATERIAL
  get_batch_no: `${mainurl}v1/users/get_batch_no`,
  save_resource_materail: `${mainurl}v1/resource_materials/save_resource_materail`,
  get_resource_materail: `${mainurl}v1/resource_materials/get_resource_materail`,
  update_resource_materail: `${mainurl}v1/resource_materials/edit_resource_materail`,
  delete_resource_materail: `${mainurl}v1/resource_materials/delete_resource_materail`,
  delete_resource_materail: `${mainurl}v1/resource_materials/delete_resource_materail`,

  send_reset_password_link: `${mainurl}v1/users/send_reset_password_link`,
  user_import: `${mainurl}v1/users/user_import`,
  update_profile_picture: `${mainurl}v1/users/update_profile_picture`,
  save_submission_upload: `${mainurl}v1/qualifications/save_submission`,
  save_document_upload: `${mainurl}v1/qualifications/save_document`,


  //COMMUNICATIONS
  get_communications: `${mainurl}v1/users/get_message`,
  mark_seen: `${mainurl}v1/users/mark_seen`,
  //GET DROPDOWN USERS
  get_dropdown_users: `${mainurl}v1/users/get_users_name`,
  send_message: `${mainurl}v1/users/send_message`,
  //DOWNLOAD PORTFOLIO
  download_portfolio: `${mainurl}v1/users/download_portfolio?`,
  action_on_user: `${mainurl}v1/users/action_user_status`,
  update_submission_status: `${mainurl}v1/qualifications/update_submission_status`,
  save_assessor_feedback: `${mainurl}v1/qualifications/save_assessor_feedback`,

  // IQA
  get_iqa_report: `${mainurl}v1/resource_materials/get_iqa_report`,
  save_iqa_report: `${mainurl}v1/resource_materials/save_iqa_report`,
  get_minutes_of_meetings: `${mainurl}v1/resource_materials/get_minutes_of_meetings`,
  save_minutes_of_meetings: `${mainurl}v1/resource_materials/save_minutes_of_meetings`,
  update_submission_iqa_status: `${mainurl}v1/qualifications/update_submission_iqa_status`,
  update_document_detail: `${mainurl}v1/qualifications/update_document_detail`,
  get_assessor_documents: `${mainurl}v1/users/get_assessor_documents`,
  save_assessor_documents: `${mainurl}v1/users/save_assessor_documents`,
  change_sampling_ratio: `${mainurl}v1/users/change_sampling_ratio`,

  // INVOICES
  get_invoices: `${mainurl}v1/invoices/get_invoices`,
  action_invoice_status: `${mainurl}v1/invoices/action_invoice_status`,
  get_invoice_detail: `${mainurl}v1/invoices/get_invoice_detail`,

  // CUSTOMER
  get_customer: `${mainurl}v1/users/get_customer`,
  create_customer: `${mainurl}v1/users/create_customer`,
  action_customer_status: `${mainurl}v1/users/action_customer_status`,
  action_learner_status: `${mainurl}v1/invoices/action_learner_status`,

  // ANALYTICS
  get_analytics: `${mainurl}v1/users/get_analytics`,
  get_superadmin_qualifications: `${mainurl}v1/qualifications/get_superadmin_qualifications`,
};
