import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Page from '../../components/Page';
import { Alert, Button, Card, Checkbox, Chip, CircularProgress, Container, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Pagination, Paper, Select, Snackbar, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { api } from 'src/Api';
import { UserListHead } from 'src/sections/@dashboard/user';
import moment from 'moment';
import pallete from '../../theme/palette';
import { PausePresentationTwoTone } from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import { NumericFormat } from 'react-number-format';

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    outline: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function CustomerList() {

    const theme = useTheme();

    const TABLE_HEAD = [
        { id: 'sr_no', label: "Sr No.", alignRight: false },
        { id: 'customer_ID ', label: "Customer ID ", alignRight: false },
        { id: 'customer_name', label: "Customer Name", alignRight: false },
        { id: 'registered_learners', label: "Registered Learners", alignRight: false },
        { id: 'price/learner', label: "Price/Learner", alignRight: false },
        { id: 'status', label: "Status", alignRight: false },
        { id: 'action', label: "Action", alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [addModal, setViewModal] = useState(false);
    const [openAddFormModal, setOpenAddFormModal] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectId, setSelectId] = useState(null);

    const [customerName, setCustomerName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [registeredLearner, setRegisteredLearner] = useState('');
    const [emailId, setEmailId] = useState('');
    const [price, setPrice] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [vatApplicable, setVatApplicable] = useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const isCustomerNotFound = customerList.length === 0;

    useEffect(() => {
        getCustomerList(page);
    }, [page]);

    useEffect(() => {
        checkRender();
    }, []);

    const refreshAdmin = () => {
        setViewModal(false);
        customerList.length = 0;
        getCustomerList(page);
    };

    function checkRender() {
        let check = 'customer';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const getCustomerList = () => {
        setloading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        // queryParams.append('company_admin_id', user?.company_admin_id);
        // queryParams.append('role_id', 4);

        fetch(`${api.get_customer}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setCustomerList(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                    setloading(false)
                } else {
                    setCustomerList([]);
                    setpagesCount(0);
                    setloading(false)
                }
            })
            .catch((error) => {
                console.log('error', error)
                setloading(false)
            });
    };

    const handlePageChange = (event, value) => {
        customerList.length = 0;
        setPage(value);
        getCustomerList(value);
    };

    const handleCloseAddModal = () => {
        setViewModal(false);
    }

    const handleViewModal = (row) => {
        setSelectId(row.id)
        setCustomerId(row.customer_id)
        setRegisteredLearner(row.learner_count)
        setCustomerName(row.name)
        setEmailId(row.email)
        setPrice(row.price)
        setPaymentTerms(row.payment_terms)
        setCustomerAddress(row.customer_address)
        setBillingAddress(row.billing_address)
        setVatApplicable(row.vat == 1 ? true : false)
        setViewModal(true);
    }

    const handleFormSubmit = () => {
        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();
        customerName && formdata.append('name', customerName); // Added dateSampling value
        emailId && formdata.append('email', emailId); // Added dateSampling value
        price && formdata.append('price', price); // Added dateSampling value
        paymentTerms && formdata.append('payment_terms', paymentTerms); // Added dateSampling value
        customerAddress && formdata.append('customer_address', customerAddress); // Added dateSampling value
        billingAddress && formdata.append('billing_address', billingAddress); // Added dateSampling value
        vatApplicable && formdata.append('vat', vatApplicable == true ? 1 : 0); // Added dateSampling value
        selectId && formdata.append('id', selectId); // Added dateSampling value

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.create_customer, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    refreshAdmin();
                    handleCloseAddModal()
                    handleAddFormModalClose()
                } else {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.error);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setSubmitLoading(false)
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleAddFormModalClose()
                handleCloseAddModal()
            });
    };

    const handleAddFormModal = (row) => {
        if (row) {
            setSelectId(row.id)
            setCustomerName(row.name)
            setEmailId(row.email)
            setPrice(row.price)
            setPaymentTerms(row.payment_terms)
            setCustomerAddress(row.customer_address)
            setBillingAddress(row.billing_address)
            setVatApplicable(row.vat == 1 ? true : false)
        }
        setOpenAddFormModal(true)
    }

    const handleAddFormModalClose = () => {
        setOpenAddFormModal(false)
        setSelectId(null)
        setCustomerName('')
        setEmailId('')
        setPrice('')
        setPaymentTerms('')
        setCustomerAddress('')
        setBillingAddress('')
        setVatApplicable(false)
    }

    const handleStatusChange = (index, newStatus) => {
        setCustomerList((prevList) => {
            const updatedList = [...prevList];
            const customer = updatedList[index];

            // Update status in the local state
            customer.status = newStatus;

            // Call API to update status on the server
            handleChangeStatus(customer.id, newStatus);

            return updatedList;
        });
    };

    const handleChangeStatus = (id, status) => {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append('id', id); // Send the customer ID
        formdata.append('status', status); // Send the new status

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.action_customer_status, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setCustomerList([])
                    refreshAdmin();
                } else {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    return (
        <Page title={`customer | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
                        Customer
                    </Typography>
                    <Button
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={() => { handleAddFormModal() }}
                    >
                        Add Customer
                    </Button>
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'90%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={'Search Customer'}
                            onEnter={() => {
                                setsearchLoading(true);
                                customerList.length = 0;
                                getCustomerList();
                            }}
                        />
                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getCustomerList(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead headLabel={TABLE_HEAD} rowCount={customerList.length} />

                                <TableBody>
                                    {customerList.map((row, index) => {
                                        const { id, customer_id, name, learner_count, price, status } = row;
                                        return (
                                            <TableRow key={id} >
                                                <TableCell noWrap align="left">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {customer_id || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {name || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap >
                                                    {learner_count}
                                                </TableCell>
                                                <TableCell noWrap >
                                                    {
                                                        price ? <NumericFormat
                                                            value={price ? parseFloat(price).toFixed(2) : "0.00"}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'£'}
                                                        /> :
                                                            "N/A"
                                                    }
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={status}
                                                        exclusive
                                                        onChange={(event, newStatus) => handleStatusChange(index, newStatus)}
                                                        aria-label="Platform"
                                                    >
                                                        <ToggleButton
                                                            value="active"
                                                            sx={{
                                                                fontSize: '12px', // Smaller font size
                                                                padding: '4px 8px', // Less padding for smaller button size
                                                                minWidth: '80px', // Ensuring the button isn't too small
                                                                '&.Mui-selected': {
                                                                    backgroundColor: 'green',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            Active
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="inactive"
                                                            sx={{
                                                                fontSize: '12px', // Smaller font size
                                                                padding: '4px 8px', // Less padding for smaller button size
                                                                minWidth: '80px', // Ensuring the button isn't too small
                                                                '&.Mui-selected': {
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            Inactive
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </TableCell>
                                                <TableCell sx={{ width: 140 }} >
                                                    <Tooltip title="Edit Customer">
                                                        <Button
                                                            size="small"
                                                            onClick={() => handleAddFormModal(row)}
                                                            style={{
                                                                justifyContent: 'center',
                                                                marginTop: 5,
                                                                width: 30, // Less than 30
                                                                height: 30, // Ensuring it's a square button
                                                                minWidth: 30, // Ensuring minimum width is set to 30
                                                                padding: 0, // Removing padding to fit the icon properly
                                                                lineHeight: 1, // Ensuring the icon is centered
                                                                alignSelf: 'center',
                                                                marginRight: 5,
                                                            }}
                                                            variant="outlined"
                                                            component={RouterLink}
                                                            to="#"
                                                        >
                                                            <Iconify style={{ fontSize: 17 }} icon="iconamoon:edit-bold" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="View Details">
                                                        <Button
                                                            size="small"
                                                            onClick={() => handleViewModal(row)}
                                                            style={{
                                                                justifyContent: 'center',
                                                                marginTop: 5,
                                                                width: 30, // Less than 30
                                                                height: 30, // Ensuring it's a square button
                                                                minWidth: 30, // Ensuring minimum width is set to 30
                                                                padding: 0, // Removing padding to fit the icon properly
                                                                lineHeight: 1, // Ensuring the icon is centered
                                                                alignSelf: 'center',
                                                                marginRight: 5,
                                                            }}
                                                            variant="outlined"
                                                            component={RouterLink}
                                                            to="#"
                                                        >
                                                            <Iconify style={{ fontSize: 17 }} icon="carbon:view-filled" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="View Invoice">
                                                        <Button
                                                            size="small"
                                                            style={{
                                                                justifyContent: 'center',
                                                                marginTop: 5,
                                                                width: 30, // Less than 30
                                                                height: 30, // Ensuring it's a square button
                                                                minWidth: 30, // Ensuring minimum width is set to 30
                                                                padding: 0, // Removing padding to fit the icon properly
                                                                lineHeight: 1, // Ensuring the icon is centered
                                                                alignSelf: 'center',
                                                                marginRight: 5,
                                                            }}
                                                            variant="outlined"
                                                            component={RouterLink}
                                                            to={`/dashboard/customers/invoices/${id}`}
                                                        >
                                                            <Iconify style={{ fontSize: 17 }} icon="mdi:invoice-list" />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isCustomerNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No Customers Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Modal
                open={addModal}
                onClose={handleCloseAddModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style3}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        View Customer Details
                    </Typography>

                    <Paper sx={{ p: 2, mt: 2 }}>
                        <Typography variant="body1" component="p">
                            <strong>Customer ID:</strong> {customerId}
                        </Typography>
                        <Typography variant="body1" component="p">
                            <strong>Customer Name:</strong> {customerName}
                        </Typography>
                        <Typography variant="body1" component="p">
                            <strong>Learners Registered:</strong> {registeredLearner}
                        </Typography>
                    </Paper>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCloseAddModal}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={openAddFormModal}
                onClose={handleAddFormModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        ...style3,
                        p: 3,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                        margin: 'auto',
                    }}
                >
                    <Typography id="modal-title" variant="h4" component="h2">
                        {selectId ? "Edit Customer" : "Add Customer"}
                    </Typography>

                    <Paper sx={{ padding: "0px 10px 0px 10px" }}>
                        <TextField
                            fullWidth
                            label="Customer Name"
                            variant="outlined"
                            margin="dense"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Email ID"
                            variant="outlined"
                            margin="dense"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Price (£)"
                            variant="outlined"
                            margin="dense"
                            value={`£ ${price}`}
                            onChange={(e) => {
                                let value = e.target.value;

                                // Remove any '£' symbol and extra spaces
                                value = value.replace('£', '').trim();

                                // Proceed if the value is a valid number, allowing any number to be input
                                if (!isNaN(value) || value === '') {
                                    setPrice(value);
                                }
                            }}
                            inputProps={{
                                min: 0,
                                step: 0.01,
                            }}
                        />
                        <Box display={"flex"} alignItems={"center"}>

                            <TextField
                                label="Payment Terms"
                                variant="outlined"
                                margin="dense"
                                value={paymentTerms}
                                // disableds
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Check if the value is a number and within the range 1 to 30
                                    if (value === '' || (Number(value) >= 1 && Number(value) <= 30)) {
                                        setPaymentTerms(value);
                                    }
                                }}
                                inputProps={{
                                    min: 1,
                                    max: 30,
                                }}

                            />
                            <Typography sx={{ marginLeft: 1 }}>
                                days after invoice issuance
                            </Typography>


                        </Box>

                        <TextField
                            fullWidth
                            label="Customer Address"
                            variant="outlined"
                            margin="dense"
                            value={customerAddress}
                            onChange={(e) => setCustomerAddress(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Billing Address"
                            variant="outlined"
                            margin="dense"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={vatApplicable}
                                    onChange={(e) => setVatApplicable(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="VAT applicable"
                        />
                    </Paper>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button
                            disabled={submitLoading}
                            variant="contained"
                            color="primary"
                            onClick={handleAddFormModalClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={submitLoading}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                            onClick={handleFormSubmit}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Page >
    )
}

export default CustomerList
